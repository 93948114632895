import axiosInstance from '../../utils/axios';
import { Profile, JWTPayload } from './interface';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}

export const fetchProfile = async (
  accessToken: string,
  userUUID: string,
): Promise<Profile> => {
  try {
    const response = await axiosInstance.get<Profile>(
      `/users/sidebar?userUUID=${userUUID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
