import classes from './_tag.module.scss';

import { hexToRgba } from '../../../utils/hexConverter';

interface TagProps {
  color: string;
  className?: string;
  icon?: React.ReactNode;
  label: string;
}

const Tag: React.FC<TagProps> = ({ color, icon, className, label }) => {
  const rgbaColor = hexToRgba(color, 0.15);

  return (
    <div
      className={`${classes.tag} ${className || ''}`}
      style={{ color: color, backgroundColor: `${rgbaColor}` }}
    >
      {label}
      {icon}
    </div>
  );
};
export default Tag;
