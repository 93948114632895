import { useTheme } from '../../../context/ThemeContext/ThemeContext';
import classes from './_checkbox.module.scss';

interface CheckboxProps {
  id: string;
  children: React.ReactNode;
  checked?: boolean;
  onChange?: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  checked,
  onChange,
  children,
}) => {
  const { isDarkMode } = useTheme();

  return (
    <label id={id} className={classes.container}>
      {children}
      <input id={id} type="checkbox" checked={checked} onChange={onChange} />
      <span
        className={`${classes.checkmark} ${
          isDarkMode ? classes.dark : classes.light
        }`}
      ></span>
    </label>
  );
};
export default Checkbox;
