import { toast } from 'react-toastify';
import axiosInstance from '../../utils/axios';
import {
  Response,
  ResponseViewMore,
  JWTPayload,
  ResponseViewMoreStatic,
  ResponseAffiliateGetPayout,
  AffiliationCommissionsGetPayout,
} from '../affiliate/interfaces';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}

export const fetchAffiliateAreaData = async (
  accessToken: string,
  page: number,
  size: number,
): Promise<Response> => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.get<Response>(
      `/affiliations/area/${decoded?.userId}?page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAffiliateViewMoreCards = async (
  accessToken: string,
): Promise<ResponseViewMoreStatic> => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.get<ResponseViewMoreStatic>(
      `/affiliations/earnings/static?userUuid=${decoded?.userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const payoutRequest = async (
  accessToken: string,
  affiliateId: string,
) => {
  const data = {
    affiliateUUID: affiliateId,
  };

  try {
    const response = await axiosInstance.post(
      `/affiliations/payout-requests`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (response.status === 200) {
      toast.success('Votre demande a été effectuée.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }

    return response.data;
  } catch (error) {
    toast.error('Votre demande a échouée.', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};

export const fetchAffiliatePayoutRequest = async (
  accessToken: string,
): Promise<ResponseAffiliateGetPayout> => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.get<ResponseAffiliateGetPayout>(
      `https://live.raisemyfunds.co/api/affiliations/payout-requests/${decoded?.userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAffiliateCommissionsPayoutRequest = async (
  accessToken: string,
  month: number,
  year: number,
  page: number,
  size: number,
): Promise<AffiliationCommissionsGetPayout> => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.get<AffiliationCommissionsGetPayout>(
      `https://live.raisemyfunds.co/api/affiliations/commissions/month?month=${month}&year=${year}&userUUID=${decoded?.userId}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
