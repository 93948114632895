import React, { useState } from 'react';
import { useTheme } from '../../../context/ThemeContext/ThemeContext';
import Button from '../../../components/userInterface/Button/Button';
import Modal from '../../Modal/Modal';
import Icon from '../../userInterface/Icon/Icon';
import classes from './_adminTable.module.scss';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation } from 'react-router-dom';

interface AdminTableProps {
  data: Array<{ [key: string]: any }>;
  columns: string[];
  acceptButton?: boolean;
  refuseButton?: boolean;
  aboutButton?: boolean;
  deleteButton?: boolean;
  executeButton?: boolean;
  downloadButton?: boolean;
  onAboutButtonClick?: (uuid: string) => void;
  onAcceptButtonClick?: (id: number) => void;
  onRefuseButtonClick?: (id: number, reason?: string) => void;
  onDeleteButtonClick?: (id: number) => void;
  onExecuteButtonClick?: (id: number) => void;
  onDownloadButtonClick?: (id: number) => void;
}

type ActionType = 'accept' | 'refuse' | 'delete' | 'execute' | 'download';

interface ModalStateType {
  isOpen: boolean;
  action: ((id: number, reason?: string) => void) | null;
  id: number | null;
  actionType: ActionType | '';
  reason?: string;
}

const AdminTable: React.FC<AdminTableProps> = ({
  data,
  columns,
  acceptButton,
  refuseButton,
  aboutButton,
  deleteButton,
  executeButton,
  downloadButton,
  onAboutButtonClick,
  onAcceptButtonClick,
  onRefuseButtonClick,
  onDeleteButtonClick,
  onExecuteButtonClick,
  onDownloadButtonClick,
}) => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const [modalState, setModalState] = useState<ModalStateType>({
    isOpen: false,
    action: null,
    id: null,
    actionType: '',
  });
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [fundedRefuseReason, setFundedRefuseReason] = useState<string | null>(
    null,
  );

  const openConfirmationModal = (
    id: number,
    action: (id: number, reason?: string) => void,
    actionType: ActionType,
  ) => {
    if (location.pathname === '/admin/kyc') {
      setModalState({
        isOpen: true,
        action,
        id,
        actionType,
        reason: selectedReason ?? undefined,
      });
    } else if (location.pathname === '/admin/funded') {
      setModalState({
        isOpen: true,
        action,
        id,
        actionType,
        reason: fundedRefuseReason ?? undefined,
      });
    } else {
      setModalState({
        isOpen: true,
        action,
        id,
        actionType,
      });
    }
  };

  const closeModal = () => {
    setModalState({
      isOpen: false,
      action: null,
      id: null,
      actionType: '',
      reason: undefined,
    });
    setSelectedReason(null);
    setFundedRefuseReason(null);
  };

  const handleConfirm = () => {
    if (modalState.action && modalState.id !== null) {
      if (modalState.actionType === 'refuse') {
        if (
          location.pathname === '/admin/kyc' &&
          selectedReason !== undefined
        ) {
          if (onRefuseButtonClick) {
            onRefuseButtonClick(
              modalState.id,
              selectedReason ?? 'RAISON PAR DEFAUT ICI KYC',
            );
          }
        } else if (
          location.pathname === '/admin/funded' &&
          fundedRefuseReason !== undefined
        ) {
          if (onRefuseButtonClick) {
            onRefuseButtonClick(
              modalState.id,
              fundedRefuseReason ?? 'RAISON PAR DEFAUT ICI FUNDED',
            );
          }
        } else {
          modalState.action(modalState.id);
        }
      } else {
        modalState.action(modalState.id);
      }
      closeModal();
    }
  };

  const handleReasonChange = (event: any, value: string | null) => {
    setSelectedReason(value);
  };

  const handleChangeRefuseFundedReason = (event: any) => {
    setFundedRefuseReason(event.target.value);
  };

  const refuseReasons = [
    'All four corners of the identity card are not visible',
    'Identity document is expired',
    'Document is unreadable',
    "The address on the document does not match the client's profile",
    "The name on the document does not match the client's profile",
    'Document has already been received',
    'We do not accept this type of document',
    'Document is not current / too old',
  ];

  const borderColor = isDarkMode ? 'black' : 'white';
  const textColor = isDarkMode ? 'white' : 'black';
  const hoverBorderColor = isDarkMode ? 'white' : 'black';
  const iconColor = isDarkMode ? 'white' : 'black';

  return (
    <div className={classes.tableContainer}>
      {modalState.isOpen && (
        <Modal
          title={`Are you sure you want to ${modalState.actionType} this item?`}
          isOpen={modalState.isOpen}
          onClose={closeModal}
          showCloseButton={true}
        >
          {location.pathname === '/admin/funded' &&
            modalState.actionType === 'refuse' && (
              <TextField
                id="refuse-reason-funded"
                label="Reason"
                variant="outlined"
                value={fundedRefuseReason}
                onChange={handleChangeRefuseFundedReason}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: textColor,
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: borderColor,
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: hoverBorderColor,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: textColor,
                  },
                  '& .MuiSvgIcon-root': {
                    color: iconColor,
                  },
                }}
              />
            )}

          {location.pathname === '/admin/kyc' &&
            modalState.actionType === 'refuse' && (
              <Autocomplete
                disablePortal
                id="refuse-reasons"
                options={refuseReasons}
                value={selectedReason}
                onChange={handleReasonChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Reason"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        color: textColor,
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: borderColor,
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: hoverBorderColor,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'primary.main',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: textColor,
                      },
                      '& .MuiSvgIcon-root': {
                        color: iconColor,
                      },
                    }}
                  />
                )}
              />
            )}
          <div className={classes.modalButtons}>
            <Button
              onClick={handleConfirm}
              variant={
                modalState.actionType === 'accept'
                  ? 'green-primary'
                  : modalState.actionType === 'refuse'
                  ? 'primary'
                  : modalState.actionType === 'execute'
                  ? 'green-primary'
                  : 'primary'
              }
            >
              {modalState.actionType}
            </Button>
            <Button onClick={closeModal} variant="outline">
              Cancel
            </Button>
          </div>
        </Modal>
      )}
      <table
        className={`${classes.challengesTable} ${
          isDarkMode ? classes.dark : classes.light
        }`}
      >
        <thead className={classes.tableHead}>
          <tr>
            {columns.map((column, index) => (
              <th
                className={isDarkMode ? classes.dark : classes.light}
                key={index}
              >
                {column}
              </th>
            ))}
            {(aboutButton ||
              acceptButton ||
              refuseButton ||
              deleteButton ||
              executeButton) && (
              <th className={classes.actionColumn}>Action</th>
            )}
          </tr>
        </thead>
        <tbody className={classes.challengesTable__body}>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, columnIndex) => (
                <td key={columnIndex}>{row[column]}</td>
              ))}
              <td className={`${classes.actions} ${classes.actionColumn}`}>
                {executeButton && onExecuteButtonClick && (
                  <Icon
                    onClick={() =>
                      openConfirmationModal(
                        row.ID,
                        onExecuteButtonClick,
                        'execute',
                      )
                    }
                    color="custom"
                    fill="#e3bc21"
                    size={24}
                    path="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                  />
                )}
                {acceptButton && onAcceptButtonClick && (
                  <Icon
                    onClick={() =>
                      openConfirmationModal(
                        row.ID,
                        onAcceptButtonClick,
                        'accept',
                      )
                    }
                    color="custom"
                    fill="#1FCC92"
                    size={24}
                    path="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                  />
                )}
                {refuseButton && onRefuseButtonClick && (
                  <Icon
                    onClick={() =>
                      openConfirmationModal(
                        row.ID,
                        (id, reason) => onRefuseButtonClick(id, reason),
                        'refuse',
                      )
                    }
                    color="custom"
                    fill="#E73D44"
                    size={24}
                    path="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"
                  />
                )}
                {aboutButton ? (
                  <Icon
                    onClick={() =>
                      onAboutButtonClick && onAboutButtonClick(row.UUID)
                    }
                    color="custom"
                    fill="#999999"
                    size={24}
                    path="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM4 5V19H20V5H4ZM7 13H9V17H7V13ZM11 7H13V17H11V7ZM15 10H17V17H15V10Z"
                  />
                ) : null}
                {downloadButton && onDownloadButtonClick && (
                  <Icon
                    onClick={() =>
                      openConfirmationModal(
                        row.ID,
                        onDownloadButtonClick,
                        'download',
                      )
                    }
                    color="white"
                    size={24}
                    path="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                  />
                )}
                {deleteButton && onDeleteButtonClick && (
                  <Icon
                    onClick={() =>
                      openConfirmationModal(
                        row.Code,
                        onDeleteButtonClick,
                        'delete',
                      )
                    }
                    color="white"
                    size={24}
                    path="M7 6V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7ZM9 4V6H15V4H9Z"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminTable;
