import axiosInstance from '../../utils/axios';
import { CertificatesResponse, JWTPayload } from './interfaces';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}

export const fetchCertificate = async (
  accessToken: string,
  anonymous: boolean,
): Promise<CertificatesResponse> => {
  try {
    const response = await axiosInstance.get<CertificatesResponse>(
      `/certificate/all?anonymous=${anonymous}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error during certificate fetch:', error);
    throw error;
  }
};
