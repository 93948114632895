import { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../context/AuthContext/AuthContext';
import { useTheme } from '../../context/ThemeContext/ThemeContext';
import { decodeJWT } from '../../services/auth/login';

import useDocumentTitle from '../../hooks/useDocumentTitle';

import Input from '../../components/userInterface/Input/Input';
import Button from '../../components/userInterface/Button/Button';
import Checkbox from '../../components/userInterface/Checkbox/Checkbox';
import Error from '../../components/Error/Error';
import classes from './_login.module.scss';
import axios from 'axios'; // Ensure you have imported axios itself.

const Login = () => {
  const { isDarkMode } = useTheme();
  const { login } = useAuth();
  const { t } = useTranslation(['login', 'forms', 'common']);
  const goNavigate = useNavigate();

  useDocumentTitle('Sign in');

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isRemembered, setIsRemembered] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [navigate, setNavigate] = useState<boolean>(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');

    if (
      accessToken
    ) {
      const userInfo = decodeJWT(accessToken);
      if (userInfo) {
        if (userInfo.role === 'ROLE_ADMIN') {
          goNavigate('/admin/dashboard');
        } else {
          goNavigate('/');
        }
      }

    }
  }, [goNavigate]);

  const handleCheckboxChange = (event: any) => {
    setIsRemembered(event.target.checked);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError(''); // Reset any existing error messages

    try {
      await login(email, password, isRemembered);
      setNavigate(true);
    } catch (error: any) {
      setLoading(false);
      console.error('Login Error object:', error);
      let message = t('errorDuringLogin');

      if (error.response) {
        // The server responded with a status code that falls out of the range of 2xx
        const { status, data } = error.response;
        message = getErrorMessage(status, data);
      } else if (error.request) {
        // The request was made but no response was received
        message = t('noResponseFromServer');
      } else {
        // An error occurred while setting up the request
        message = error.message;
      }

      setError(message);
    } finally {
      setLoading(false);
    }
  };

  function getErrorMessage(status: number, data: any) {
    switch (status) {
      case 429:
        return t('tooManyLoginAttempt');
      case 401:
        return t('invalidCredentials');
      default:
        return data?.error || t('unexpectedErrorDuringLogin');
    }
  }


  if (navigate) {
    const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');

    if (
      accessToken
    ) {
      const userInfo = decodeJWT(accessToken);
      if (userInfo) {
        if (userInfo.role === 'ROLE_ADMIN') {
          return <Navigate to="/admin/dashboard" />;

        } else {
          return <Navigate to="/" />;
        }
      }

    }
  }

  return (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src={require('../../assets/images/fullLogo.png')}
        alt=""
      />
      <div className={classes.titles}>
        <h1> {t('welcomeBack')} </h1>
        <h2> {t('loginToYourAccount')} </h2>
      </div>
      <div className={classes.formContainer}>
        {error && <Error error={error} />}
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.formGroup}>
            <label htmlFor="email"> {t('emailLabel', { ns: 'forms' })} </label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('emailPlaceholder', { ns: 'forms' })}
            />
          </div>
          <div className={classes.formGroup}>
            <label htmlFor="password">
              {t('passwordLabel', { ns: 'forms' })}
            </label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('passwordPlaceholder', { ns: 'forms' })}
            />
            <div className={classes.actions}>
              <Checkbox
                id="remember-me"
                checked={isRemembered}
                onChange={handleCheckboxChange}
              >
                {t('rememberMe', { ns: 'forms' })}
              </Checkbox>
              <Link
                to={'/forgot-password'}
                className={`${classes.link} ${isDarkMode ? classes.dark : classes.light
                  }`}
              >
                {t('forgotPassword', { ns: 'forms' })}
              </Link>
            </div>
          </div>
          <Button type="submit" disabled={loading} variant="primary">
            {loading ? t('loading', {ns: 'common'}) : t('login')}
          </Button>
        </form>
      </div>
      <div className={classes.signUp}>
        <p> {t('dontHaveAccountYet')} </p>
        <Link
          to={'/buy-challenge'}
          className={`${classes.link} ${isDarkMode ? classes.dark : classes.light
            }`}
        >
          {t('buyChallenge')}
        </Link>
      </div>
    </div>
  );
};
export default Login;
