import useDocumentTitle from '../../../hooks/useDocumentTitle';

import Button from '../../userInterface/Button/Button';

import classes from './_error.module.scss';

interface ErrorProps {
  documentTitle: string;
  title: string;
  subtitle: string;
  button: string;
  onClick?: any;
}

const Error: React.FC<ErrorProps> = ({
  documentTitle,
  title,
  subtitle,
  button,
  onClick,
}) => {
  useDocumentTitle(documentTitle);

  return (
    <div className={classes.container}>
      <h1>{title}</h1>
      <p>{subtitle}</p>
      <Button onClick={onClick} variant="rounded-outline">
        {button}
      </Button>
    </div>
  );
};

export default Error;
