import { useTheme } from '../../context/ThemeContext/ThemeContext';

import classes from './_table.module.scss';

interface TableProps {
  data: Array<{ [key: string]: string | number | JSX.Element }>;
  columns: string[];
}

const Table: React.FC<TableProps> = ({ data, columns }) => {
  const { isDarkMode } = useTheme();

  return (
    <table
      className={`${classes.table}  ${
        isDarkMode ? classes.dark : classes.light
      }`}
    >
      <thead>
        <tr
          className={`${classes.header} ${
            isDarkMode ? classes.dark : classes.light
          }`}
        >
          {columns.map((column, index) => (
            <th
              className={isDarkMode ? classes.dark : classes.light}
              key={index}
            >
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr
            className={`${classes.body} ${
              isDarkMode ? classes.dark : classes.light
            }`}
            key={rowIndex}
          >
            {columns.map((column, columnIndex) => (
              <td key={columnIndex}>{row[column]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default Table;
