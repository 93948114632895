import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  EventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';

import Cookies from 'js-cookie';

import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../../context/ThemeContext/ThemeContext';
import { useAuth } from '../../context/AuthContext/AuthContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import {
  fetchShop,
  fetchShopConnected,
  applyPromoCode,
} from '../../services/shop/shop';
import Card from '../../components/Cards/Card/Card';
import QuantitySelector from '../../components/QuantitySelector/QuantitySelector';
import Icon from '../../components/userInterface/Icon/Icon';
import Button from '../../components/userInterface/Button/Button';
import Input from '../../components/userInterface/Input/Input';
import Select from '../../components/userInterface/Select/Select';
import Checkbox from '../../components/userInterface/Checkbox/Checkbox';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';

import classes from './_buyChallenge.module.scss';
import { cp } from 'fs';
import { MuiTelInput } from 'mui-tel-input';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
import PixelBuyChallenge from '../../utils/pixel/PixelBuyChallenge';

interface DiscountInfo {
  discountValue: number;
  discountPercentage: number;
  finalPrice: number;
}

interface QuantityItem {
  quantity: number;
  price: number;
  originalPrice: number;
}

interface Quantities {
  [key: string]: QuantityItem;
}

const BuyChallenge = () => {
  useDocumentTitle('New Challenge');

  const { t } = useTranslation(['buyChallenge', 'common']);
  const { isDarkMode } = useTheme();
  const { tokens, isTokensLoading } = useAuth();
  const { getCode } = require('country-list');

  const isPromotion = true;
  const prevTokenRef = useRef<string | null>(null);
  const location = window.location;
  const referredBy = new URLSearchParams(location.search).get('affiliate');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [challenges, setChallenges] = useState<{}[]>([]);
  const [multiplierFactors, setMultiplierFactors] = useState<any>();
  const [user, setUser] = useState<any>();
  const [discountInfo, setDiscountInfo] = useState<DiscountInfo | null>(null);
  const [quantities, setQuantities] = useState<Quantities>({});
  const [useVirtualWallet, setUseVirtualWallet] = useState(false);
  const [countryOptions, setCountryOptions] = useState<string[]>([]);
  const [phone, setPhoneValue] = useState('');
  const [phoneIsValid, setPhoneIsValid] = useState<boolean>(false);
  const [walletDeduction, setWalletDeduction] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string | undefined>('');
  const [selectedPayment, setSelectedPayment] = useState<string | undefined>(
    t('billingInfoCard.creditCard'),
  );
  const [phoneError, setPhoneError] = useState<string>('');

  if (referredBy !== null) {
    Cookies.set('affiliate', referredBy, { expires: 30 });
    localStorage.setItem('affiliate', referredBy);
  }

  function isValidPhoneNumber(phoneNumber: string): boolean {
    const cleanedPhoneNumber = phoneNumber.replace(/[\s-.]/g, '');
    const regex = /^\+[1-9]\d{1,14}$/;

    if (cleanedPhoneNumber.length < 9) {
      return false;
    }

    return regex.test(cleanedPhoneNumber);
  }

  useEffect(() => {
    if (user?.phoneNumber) {
      setPhoneValue(user.phoneNumber);
      setPhoneIsValid(true);
    }
  }, [user?.phoneNumber]);

  const handlePhoneChange = (newValue: string) => {
    if (isValidPhoneNumber(newValue)) {
      setPhoneError('');
      setPhoneIsValid(true);
    } else {
      setPhoneError(t('billingInfoCard.PLEASE_ENTER_A_VALID_PHONE_NUMBER'));
      setPhoneIsValid(false);
    }

    setPhoneValue(newValue);
  };

  const preparePaymentData = (event: any) => {
    const items = Object.keys(quantities).map((key) => {
      return {
        balance: key.replace('USD', ''),
        quantity: quantities[key].quantity,
        price: quantities[key].price,
      };
    });

    // Récupérer les valeurs du formulaire
    const formData = new FormData(event.target);
    const firstName = formData.get('firstname');
    const lastName = formData.get('lastname');
    const email = formData.get('email');
    const country = selectedOption;
    const city = formData.get('city');
    const street = formData.get('street');
    const zipCode = formData.get('postalCode');
    return {
      items,
      email: email,
      promoCode: promoCode,
      phoneNumber: user?.phoneNumber || phone,
      firstname: firstName,
      lastname: lastName,
      country: country || user?.country || 'Albania',
      zipCode: zipCode,
      city: city,
      street: street,
      ipAddress: user?.ipAddress,
      referredBy: localStorage.getItem('affiliate') || Cookies.get('affiliate'),
      role: 'ROLE_USER',
      useVirtualWallet: useVirtualWallet,
    };
  };

  const updateStates = (data: any) => {
    setChallenges(data.challenges);
    setMultiplierFactors(data);
    setUser(data);
    const initialQuantities = data.challenges.reduce(
      (acc: any, challenge: any) => {
        acc[`${challenge.challengeBalance}USD`] = {
          quantity: 0,
          price: challenge.currentPrice,
          originalPrice:
            challenge.currentPrice < challenge.initialPrice
              ? challenge.currentPrice
              : challenge.initialPrice,
        };
        return acc;
      },
      {},
    );
    setQuantities(initialQuantities);
  };

  const handlePayment = async (event: any) => {
    event.preventDefault();

    const hasSelectedItems = Object.values(quantities).some(
      (item) => item.quantity > 0,
    );
    if (!hasSelectedItems) {
      toast.info(t('billingInfoCard.PLEASE_SELECT_AT_LEAST_ONE_ARTICLE'), {
        progressStyle: { backgroundColor: 'cornflowerblue' },
      });
      return;
    }

    if (!phoneIsValid) {
      toast.error(t('billingInfoCard.INVALID_PHONE_NUMBER'), {
        progressStyle: { backgroundColor: 'red' },
      });
      return;
    }
    // window.open("https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExOGVoczBha2wwZm1venIwdWY0OWFyeHM3bTJwNHlvZWRveXBkcGhmdSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/L1QnWsvqGB7hrAJ5H3/giphy.gif");

    try {
      const paymentData = preparePaymentData(event);

      // L'URL varie selon le type de paiement sélectionné
      const url =
        selectedPayment === t('billingInfoCard.cryptoWallet')
          ? 'https://live.raisemyfunds.co/api/shop/initPayment'
          : 'https://live.raisemyfunds.co/api/shop/initFiatPayment';

      const response = await axios.post(url, paymentData);

      // Gérer les réponses spécifiques au paiement par crypto-wallet
      if (selectedPayment === t('billingInfoCard.cryptoWallet')) {
        if (response.data.paymentPage) {
          // Rediriger vers la page de paiement si nécessaire
          window.location.href = response.data.paymentPage;
          // navigate(`/buy-challenge/payment-status/${response.data.paymentId}`);
        } else if (response.data.message === 'Payment completed using wallet') {
          // Gérer le cas où le paiement a été effectué entièrement par le wallet
          navigate(`/buy-challenge/payment-status/${response.data.callbackId}`);
        }
      } else {
        if (response.status === 201) {
          // Paiement effectué par le wallet

          navigate(`/buy-challenge/payment-status/${response.data.callbackId}`);
        } else {
          // Redirection pour les paiements fiat
          window.location.href = response.data.url;
        }
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        toast.error(
          t('billingInfoCard.CANNOT_EXCEED_TOTAL_FUNDING_400K_FROM_CHALLENGES'),
          {
            progressStyle: { backgroundColor: 'red' },
          },
        );
      } else if (error.response.status === 409) {
        toast.error(t('billingInfoCard.THIS_PHONE_NUMBER_IS_ALREADY_USE'), {
          progressStyle: { backgroundColor: 'red' },
        });
      } else {
        console.error("Erreur lors de l'initiation du paiement: ", error);
      }
    }
  };

  const [promoCode, setPromoCode] = useState('');
  const [promoError, setPromoError] = useState('');

  const handlePromoCodeChange = (e: any) => {
    setPromoCode(e.target.value);
  };

  const handleApplyPromoCode = async () => {
    if (!promoCode) {
      setPromoError(t('billingInfoCard.enterPromoCodeError'));
      return;
    }

    try {
      const originalPrice = Object.values(quantities).reduce(
        (sum, { quantity, originalPrice }) => {
          const numQuantity = Number(quantity);
          const numPrice = Number(originalPrice);
          return (
            sum +
            (isNaN(numQuantity) || isNaN(numPrice) ? 0 : numQuantity * numPrice)
          );
        },
        0,
      );

      if (!tokens || !tokens.accessToken) {
        const response = await applyPromoCode(
          null,
          promoCode,
          originalPrice,
          referredBy,
        );
        setDiscountInfo(response);
      } else {
        const response = await applyPromoCode(
          tokens.accessToken,
          promoCode,
          originalPrice,
          null,
        );
        setDiscountInfo(response);
      }
      setPromoError('');
    } catch (error) {
      setPromoError(t('billingInfoCard.failedToApplyPromoCode'));
      setDiscountInfo(null);
    }
  };

  useEffect(() => {
    fetch('https://live.raisemyfunds.co/api/localisation/allowed-countries')
      .then((response: any) => response.json())
      .then((data) => {
        setCountryOptions(data);
      });
  }, []);

  useEffect(() => {
    if (!isTokensLoading) {
      (async () => {
        setIsLoading(true);
        try {
          let data;
          if (tokens) {
            prevTokenRef.current = tokens.accessToken;
            data = await fetchShopConnected(tokens.accessToken);
          } else {
            data = await fetchShop();
          }
          updateStates(data);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [tokens, isTokensLoading]);

  const handleQuantityChange = (productKey: any, newQuantity: any) => {
    setQuantities((prevQuantities: any) => ({
      ...prevQuantities,
      [productKey]: {
        ...prevQuantities[productKey],
        quantity: newQuantity,
      },
    }));
    setDiscountInfo(null);
  };

  const calculateSubtotal = useCallback(() => {
    return Object.values(quantities).reduce((sum, { quantity, price }) => {
      const numQuantity = Number(quantity);
      const numPrice = Number(price);
      return (
        sum +
        (isNaN(numQuantity) || isNaN(numPrice) ? 0 : numQuantity * numPrice)
      );
    }, 0);
  }, [quantities]); // Les dépendances de useCallback

  useEffect(() => {
    const calculateWalletDeduction = () => {
      const subtotal = calculateSubtotal();
      let finalPrice = discountInfo ? discountInfo.finalPrice : subtotal;

      return useVirtualWallet
        ? Math.min(finalPrice, user?.currentWallet || 0)
        : 0;
    };

    setWalletDeduction(calculateWalletDeduction());
  }, [useVirtualWallet, user?.currentWallet, calculateSubtotal]); // calculateSubtotal est maintenant une dépendance stable

  const calculateTotalCost = () => {
    const subtotal = calculateSubtotal();
    let finalPrice = discountInfo ? discountInfo.finalPrice : subtotal;

    if (useVirtualWallet) {
      finalPrice -= walletDeduction;
    }

    return finalPrice;
  };

  const paymentOptions =
    user?.country === 'Algeria' || selectedOption === 'Algeria'
      ? [t('billingInfoCard.cryptoWallet')]
      : [t('billingInfoCard.creditCard'), t('billingInfoCard.cryptoWallet')];

  const handleSelectPayment = (value: string) => {
    setSelectedPayment(value);
  };

  useEffect(() => {}, [selectedPayment]);

  const handleSelect = (value: string) => {
    setSelectedOption(value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseVirtualWallet(event.target.checked);
  };

  const handleButtonClick = () => {
    setUseVirtualWallet(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === ' ' || e.keyCode === 32) {
      e.preventDefault();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PixelBuyChallenge />
      {!tokens ? (
        <nav className={classes.nav}>
          <div className={classes.mobileNav}>
            <Link to="https://raisemyfunds.co/">
              <img src={require('../../assets/images/squareLogo.png')} alt="" />
            </Link>
            <div className={classes.mobileButtons}>
              <Button variant="outline" onClick={() => navigate('/login')}>
                Login
              </Button>
              <Button
                variant="primary"
                onClick={() => navigate('/buy-challenge')}
              >
                Get Funded
              </Button>
            </div>
          </div>
          <div className={classes.wrapper}>
            <Link to="https://raisemyfunds.co/">
              <img src={require('../../assets/images/fullLogo.png')} alt="" />
            </Link>
            <ul>
              <li>
                <Link to={'https://raisemyfunds.co/the-challenge/'}>
                  The Challenge
                </Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/faq/'}>FAQ</Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/about-us/'}>About Us</Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/partner/'}>
                  Be a Partner
                </Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/contact-us/'}>
                  Contact Us
                </Link>
              </li>
            </ul>
            <div>
              <Button variant="outline" onClick={() => navigate('/login')}>
                Login
              </Button>
              <Button
                variant="primary"
                onClick={() => navigate('/buy-challenge')}
              >
                Get Funded
              </Button>
            </div>
          </div>
        </nav>
      ) : null}

      <div className={classes.mainContainer}>
        <div className={classes.challengeContainer}>
          {challenges.map((challenge: any, index: number) => (
            <Card key={index} className={classes.card}>
              <h2>{`${challenge?.challengeBalance.toLocaleString(
                'fr-FR',
              )} USD`}</h2>
              <table
                className={`${classes.table} ${
                  isDarkMode ? classes.dark : classes.light
                }`}
              >
                <thead className={classes.table__header}>
                  <tr>
                    <th></th>
                    <th>{t('challengesCards.step')} 1</th>
                    <th>{t('challengesCards.step')} 2</th>
                  </tr>
                </thead>
                <tbody className={classes.table__body}>
                  <tr>
                    <td>{t('challengesCards.profitTarget')}</td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.profitTargetPhase1
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.profitTargetPhase2
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('challengesCards.maxDailyLoss')}</td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.maxDailyLoss
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.maxDailyLoss
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('challengesCards.maxLifetimeLoss')}</td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.maxLifetimeLoss
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.maxLifetimeLoss
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={classes.footer}>
                <QuantitySelector
                  initialQuantity={
                    quantities[`${challenge.challengeBalance}USD`]?.quantity ||
                    0
                  }
                  onQuantityChange={(newQuantity) =>
                    handleQuantityChange(
                      `${challenge.challengeBalance}USD`,
                      newQuantity,
                    )
                  }
                />
                <div className={classes.prices}>
                  {challenge?.currentPrice < challenge?.initialPrice ? (
                    <div
                      className={`${classes.price} ${
                        isDarkMode ? classes.dark : classes.light
                      }`}
                    >
                      {challenge?.currentPrice}€
                    </div>
                  ) : null}
                  <div
                    className={`${
                      challenge?.currentPrice < challenge?.initialPrice
                        ? classes.strikePrice
                        : classes.price
                    } ${isDarkMode ? classes.dark : classes.light}`}
                  >
                    {`${challenge?.initialPrice} €`}
                  </div>
                </div>
              </div>
            </Card>
          ))}
          <Card className={classes.infoCard}>
            <div className={classes.iconContainer}>
              <div className={classes.icon}>
                <Icon
                  color="white"
                  size={52}
                  path="M12.0049 22.0029C6.48204 22.0029 2.00488 17.5258 2.00488 12.0029C2.00488 6.48008 6.48204 2.00293 12.0049 2.00293C17.5277 2.00293 22.0049 6.48008 22.0049 12.0029C22.0049 17.5258 17.5277 22.0029 12.0049 22.0029ZM8.50488 14.0029V16.0029H11.0049V18.0029H13.0049V16.0029H14.0049C15.3856 16.0029 16.5049 14.8836 16.5049 13.5029C16.5049 12.1222 15.3856 11.0029 14.0049 11.0029H10.0049C9.72874 11.0029 9.50488 10.7791 9.50488 10.5029C9.50488 10.2268 9.72874 10.0029 10.0049 10.0029H15.5049V8.00293H13.0049V6.00293H11.0049V8.00293H10.0049C8.62417 8.00293 7.50488 9.12222 7.50488 10.5029C7.50488 11.8836 8.62417 13.0029 10.0049 13.0029H14.0049C14.281 13.0029 14.5049 13.2268 14.5049 13.5029C14.5049 13.7791 14.281 14.0029 14.0049 14.0029H8.50488Z"
                />
              </div>
            </div>
            <div className={classes.content}>
              <h2>{t('useWalletCard.useWalletTitle')}</h2>
              <h3 className={classes.subtitle}>
                {tokens ? (
                  <>
                    {t('useWalletCard.useWalletSubtitle', {
                      money: user?.currentWallet,
                    })}
                  </>
                ) : (
                  ''
                )}
              </h3>
              <p>{t('useWalletCard.useWalletText')}</p>
            </div>

            <Button
              variant="primary"
              disabled={!tokens}
              onClick={handleButtonClick}
            >
              {t('useWalletCard.useWalletButton')}
            </Button>
          </Card>
        </div>
        <Card className={classes.sidebar}>
          <h2>{t('billingInfoCard.billingInfo')}</h2>
          <form onSubmit={handlePayment}>
            <div className={classes.formGroup}>
              <div className={classes.form}>
                <label htmlFor="firstname">
                  {t('billingInfoCard.firstname')}
                </label>
                <Input
                  id="firstname"
                  name="firstname"
                  defaultValue={user?.firstName}
                  type="text"
                  placeholder={t('billingInfoCard.firstname')}
                  required
                />
              </div>
              <div className={classes.form}>
                <label htmlFor="lastname">
                  {t('billingInfoCard.lastname')}
                </label>
                <Input
                  id="lastname"
                  name="lastname"
                  defaultValue={user?.lastName}
                  type="text"
                  placeholder={t('billingInfoCard.lastname')}
                  required
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <div className={classes.form}>
                <label htmlFor="country">{t('billingInfoCard.country')}</label>
                <Select
                  id="country"
                  options={countryOptions}
                  defaultValue={user?.country}
                  onSelect={handleSelect}
                />
              </div>
              <div className={classes.form}>
                <label htmlFor="city">{t('billingInfoCard.city')}</label>
                <Input
                  id="city"
                  name="city"
                  defaultValue={user?.city}
                  type="text"
                  placeholder={t('billingInfoCard.city')}
                  required
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <div className={classes.form}>
                <label htmlFor="street">{t('billingInfoCard.street')}</label>
                <Input
                  id="street"
                  name="street"
                  defaultValue={user?.street}
                  type="text"
                  placeholder={t('billingInfoCard.street')}
                  required
                />
              </div>
              <div className={classes.form}>
                <label htmlFor="postalCode">
                  {t('billingInfoCard.postalCode')}
                </label>
                <Input
                  id="postalCode"
                  name="postalCode"
                  defaultValue={user?.postalCode}
                  type="text"
                  placeholder={t('billingInfoCard.postalCode')}
                  required
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <div className={classes.form}>
                <label htmlFor="email">{t('billingInfoCard.emailLabel')}</label>
                <Input
                  id="email"
                  name="email"
                  defaultValue={user?.email}
                  type="email"
                  placeholder={t('billingInfoCard.emailPlaceholder')}
                  onKeyDown={handleKeyDown}
                  required
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <div className={classes.form}>
                <label>{t('billingInfoCard.PHONE_NUMBER_LABEL')}</label>
                <MuiTelInput
                  className={`${classes.textField} ${
                    isDarkMode ? classes.dark : classes.light
                  }`}
                  defaultCountry={user?.country ? getCode(user?.country) : null}
                  excludedCountries={[
                    'AF',
                    'DZ',
                    'BA',
                    'KH',
                    'CU',
                    'KP',
                    'EG',
                    'GY',
                    'VA',
                    'IR',
                    'IQ',
                    'LA',
                    'LB',
                    'LY',
                    'MM',
                    'PK',
                    'QA',
                    'RU',
                    'SY',
                    'UG',
                    'US',
                    'VU',
                    'VN',
                    'YE',
                    'VI',
                  ]}
                  value={phone}
                  onChange={handlePhoneChange}
                  MenuProps={{
                    sx: {
                      '.MuiPaper-root': {
                        backgroundColor: isDarkMode ? '#2a2b2e' : '#f4f4f4',
                        color: isDarkMode ? '#ffffff' : '#333333',
                      },
                      '.MuiTypography-root': {
                        color: isDarkMode ? '#ffffff' : '#333333',
                      },
                    },
                  }}
                />
              </div>
            </div>
            <span className={classes.errorMsg}>{phoneError}</span>
            <hr className={classes.line} />
            <div className={classes.formGroup}>
              <div className={classes.form}>
                <label htmlFor="paymentMethod">
                  {t('billingInfoCard.paymentMethod')}
                </label>
                <Select
                  id="paymentMethod"
                  options={paymentOptions}
                  onSelect={handleSelectPayment}
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <div className={classes.form}>
                <label htmlFor="couponCode">
                  {t('billingInfoCard.couponCode')}
                </label>
                <Input
                  id="couponCode"
                  type="text"
                  placeholder={t('billingInfoCard.couponCode')}
                  value={promoCode}
                  onChange={handlePromoCodeChange}
                />
              </div>
              <Button
                variant="primary"
                type="button"
                className={`${classes.applyButton} ${
                  isDarkMode ? classes.dark : classes.light
                }`}
                onClick={handleApplyPromoCode}
              >
                {t('apply', { ns: 'common' })}
              </Button>
            </div>
            <div className={classes.aboutContainer}>
              {promoError && <p className={classes.errorMsg}>{promoError}</p>}

              <div className={classes.about}>
                <p>{t('billingInfoCard.platform')}</p>
                <p>Metatrader 5</p>
              </div>
              {discountInfo && !promoError && (
                <p className={classes.bonus}>
                  -{discountInfo.discountValue}€ (
                  {discountInfo.discountPercentage}% Off)
                </p>
              )}
              {useVirtualWallet && (
                <p className={classes.bonus}>
                  -{walletDeduction}€ (from virtual wallet)
                </p>
              )}

              <div className={classes.aboutContainer}>
                <div className={classes.about}>
                  <p>{t('billingInfoCard.price')}</p>
                  <p>{calculateTotalCost()}€</p>
                </div>
              </div>
            </div>
            {tokens ? (
              <div className={classes.walletCheckbox}>
                <Checkbox
                  id="use-wallet"
                  checked={useVirtualWallet}
                  onChange={handleCheckboxChange}
                >
                  {t('useWalletCard.useWalletButton')}
                </Checkbox>
              </div>
            ) : (
              ''
            )}
            <Button
              variant="primary"
              className={classes.paymentButton}
              type="submit"
            >
              {t('billingInfoCard.paymentButton')}
            </Button>
          </form>
        </Card>
      </div>

      <Card className={classes.conditions}>
        <h2>{t('challengeConditionsTable.challengeConditions')}</h2>

        <table
          className={`${classes.conditionTable} ${
            isDarkMode ? classes.dark : classes.light
          }`}
        >
          <thead className={classes.conditionTable__header}>
            <tr>
              <th></th>
              <th>{t('challengesCards.step')} 1</th>
              <th>{t('challengesCards.step')} 2</th>
              <th>{t('challengeConditionsTable.liveAccount')}</th>
            </tr>
          </thead>
          <tbody className={classes.conditionTable__body}>
            <tr>
              <td>{t('challengeConditionsTable.challengeDuration')}</td>
              <td>{t('challengeConditionsTable.unlimited')}</td>
              <td>{t('challengeConditionsTable.unlimited')}</td>
              <td>{t('challengeConditionsTable.unlimited')}</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.profitTarget')}</td>
              <td>10%</td>
              <td>5% </td>
              <td>-</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.minimumTradingDays')}</td>
              <td>4 </td>
              <td>4 </td>
              <td>-</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.maxDailyLoss')}</td>
              <td>5% </td>
              <td>5% </td>
              <td>-</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.maxLoss')}</td>
              <td>10% </td>
              <td>10% </td>
              <td>-</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.refundableFee')}</td>
              <td>{t('challengeConditionsTable.challengePrice')}</td>
              <td>{t('challengeConditionsTable.free')}</td>
              <td>{t('challengeConditionsTable.refund')}</td>
            </tr>
          </tbody>
        </table>
        <Typography fontSize={18} mt={2}>
          {t('challengeConditionsTable.ON_THE_FIRST_SCALE_UP')}
        </Typography>
      </Card>
      {!tokens ? (
        <footer className={classes.mainFooter}>
          <div>
            <p>{t('footer.paragraphOne')}</p>
            <br />
            <p>{t('footer.paragraphTwo')}</p>
            <br />
            <p>{t('footer.paragraphThree')}</p>
            <br />
            <p>{t('footer.paragraphFour')}</p>
          </div>
          <hr />
          <p className={classes.copyright}>{t('footer.copyrightText')}</p>
        </footer>
      ) : null}
    </>
  );
};
export default BuyChallenge;
