import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAuth } from '../../context/AuthContext/AuthContext';
import { useTheme } from '../../context/ThemeContext/ThemeContext';

import useDocumentTitle from '../../hooks/useDocumentTitle';

import Tag from '../../components/userInterface/Tag/Tag';
import Tooltip from '../../components/userInterface/Tooltip/Tooltip';

import {
  fetchTraderAreaData,
  getPayout,
  scaleUpFundedAccount,
  submitWithdrawalRequest,
} from '../../services/client/client';
import { logout } from '../../services/auth/login';
import { fetchProfile } from '../../services/profileSidebar/profileSidebar';

import IconCard from '../../components/Cards/IconCard/IconCard';
import AccountCard from '../../components/Cards/AccountCard/AccountCard';
import Icon from '../../components/userInterface/Icon/Icon';
import Button from '../../components/userInterface/Button/Button';
import Card from '../../components/Cards/Card/Card';
import ProfitChart from '../../components/ProfitChart/ProfitChart';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/Loader/Loader';
import Stepper from '../../components/Stepper/Stepper';
import Select from '../../components/userInterface/Select/Select';
import UploadButton from '../../components/userInterface/UploadButton/UploadButton';
import Input from '../../components/userInterface/Input/Input';
import Error from '../../components/Error/Error';
import ZoomableChart from '../../components/ZoomableChart/ZoomableChart';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import classes from './_traderArea.module.scss';
import React from 'react';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MUIModal from '@mui/material/Modal';

import networkList from '../PartnerArea/networkList.json';
import { Stack } from '@mui/material';

export default function TraderArea() {
  const { user, tokens, isTokensLoading } = useAuth();
  const { isDarkMode } = useTheme();
  const { t } = useTranslation([
    'traderArea',
    'clientArea',
    'components',
    'common',
  ]);

  useDocumentTitle('Trader Area');

  const passwordRef = useRef<HTMLInputElement | null>(null);
  const idRef = useRef<HTMLParagraphElement>(null);
  const serverRef = useRef<HTMLParagraphElement>(null);
  const prevTokenRef = useRef<string | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean[]>([]);
  const [isScaleUpModalOpen, setIsScaleUpModalOpen] = useState<boolean[]>([]);
  const [isPayoutModalOpen, setIsPayoutModalOpen] = useState<boolean[]>([]);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] =
    useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordCopied, setPasswordCopied] = useState(false);
  const [idCopied, setIdCopied] = useState(false);
  const [serverCopied, setServerCopied] = useState(false);
  const [accounts, setAccounts] = useState<any>([]);
  const [wallet, setWallet] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [walletValue, setWalletValue] = useState<string>('');
  const [isWalletValid, setIsWalletValid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [amount, setAmount] = useState('');
  const [kycStatus, setKycStatus] = useState<boolean>(false);

  const [selectedNetwork, setSelectedNetwork] = useState<string | null>(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const steps = [
    { title: t('withdrawFunds.steps.paiementMethod', { ns: 'clientArea' }) },
    { title: t('withdrawFunds.steps.informations', { ns: 'clientArea' }) },
    { title: t('withdrawFunds.steps.amount', { ns: 'clientArea' }) },
  ];
  const paymentMethod = [
    t('withdrawFunds.stepOne.selectPaiementMethodPlaceholder', {
      ns: 'clientArea',
    }),
    t('withdrawFunds.stepOne.bankAccount', { ns: 'clientArea' }),
    t('withdrawFunds.stepOne.cryptoWallet', { ns: 'clientArea' }),
  ];

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCopyPassword = () => {
    if (passwordRef.current) {
      navigator.clipboard.writeText(passwordRef.current.value);
      setPasswordCopied(true);
      setTimeout(() => {
        setPasswordCopied(false);
      }, 2000);
    }
  };

  const handleCopyId = () => {
    if (idRef.current) {
      navigator.clipboard.writeText(idRef.current.innerText);
      setIdCopied(true);
      setTimeout(() => {
        setIdCopied(false);
      }, 2000);
    }
  };

  const handleCopyServer = () => {
    if (serverRef.current) {
      navigator.clipboard.writeText(serverRef.current.innerText);
      setServerCopied(true);
      setTimeout(() => {
        setServerCopied(false);
      }, 2000);
    }
  };

  const handleSelect = (option: string) => {
    if (
      option !==
      t('withdrawFunds.stepOne.selectPaiementMethodPlaceholder', {
        ns: 'clientArea',
      })
    ) {
      setSelectedOption(option);
      setError('');
    }
  };

  const openModal = (accountLogin: string) => {
    setIsModalOpen({ ...isModalOpen, [accountLogin]: true });
  };
  const openScaleUpModal = (accountLogin: string) => {
    setIsScaleUpModalOpen({ ...isModalOpen, [accountLogin]: true });
  };
  const openPayoutModal = (accountLogin: string) => {
    setIsPayoutModalOpen({ ...isModalOpen, [accountLogin]: true });
  };
  const openWithdrawModal = () => {
    setIsWithdrawModalOpen(true);
  };

  const closeModal = (accountLogin: string) => {
    setIsModalOpen({ ...isModalOpen, [accountLogin]: false });
  };
  const closeScaleUpModal = (accountLogin: string) => {
    setIsScaleUpModalOpen({ ...isModalOpen, [accountLogin]: false });
  };
  const closePayoutModal = (accountLogin: string) => {
    setIsPayoutModalOpen({ ...isModalOpen, [accountLogin]: false });
  };
  const closeWithdrawModal = () => {
    setIsWithdrawModalOpen(false);
  };

  const handleWalletChange = (e: any) => {
    const value = e.target.value;
    setWalletValue(value);
    setIsWalletValid(value.trim() !== '');
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };

  const sendToast = () => {
    toast.info(
      t('headerCards.PLEASE_COMPLETE_YOUR_KYC_TO_WITHDRAW', {
        ns: 'clientArea',
      }),
      {
        progressStyle: { backgroundColor: 'cornflowerblue' },
      },
    );
  };

  const handleNetworkChange = (
    event: React.ChangeEvent<{}>,
    value: { id: number; name: string; src: string } | null,
  ) => {
    setSelectedNetwork(value ? value.name : null);
  };

  const handleStepChange = (newStep: number) => {
    setError('');

    if (newStep > currentStep && currentStep === 0 && !selectedOption) {
      setError(t('withdrawFunds.stepOne.error', { ns: 'clientArea' }));
      return;
    }

    if (
      newStep > currentStep &&
      currentStep === 1 &&
      selectedOption ===
        t('withdrawFunds.stepOne.cryptoWallet', { ns: 'clientArea' }) &&
      !isWalletValid
    ) {
      setError(t('withdrawFunds.stepTwo.errorCrypto', { ns: 'clientArea' }));
      return;
    }
    setCurrentStep(newStep);
  };

  const handleWithdrawSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const numericAmount = parseFloat(amount.replace(',', '.'));

    if (numericAmount <= 0 || numericAmount > wallet?.currentBalance) {
      setError(t('withdrawFunds.stepThree.error', { ns: 'clientArea' }));
      return;
    }

    if (
      selectedOption ===
        t('withdrawFunds.stepOne.bankAccount', {
          ns: 'clientArea',
        }) &&
      numericAmount > 8400
    ) {
      setError(
        t('withdrawFunds.stepThree.ERROR_YOU_EXCEED_THE_MAXIMUM_AMOUNT', {
          ns: 'clientArea',
        }),
      );
      return;
    }

    if (tokens?.accessToken) {
      setIsLoading(true);
      const numericAmount = parseFloat(amount.replace(',', '.'));

      try {
        await submitWithdrawalRequest(
          tokens?.accessToken,
          numericAmount,
          selectedOption ===
            t('withdrawFunds.stepOne.bankAccount', { ns: 'clientArea' })
            ? 'BANK'
            : 'CRYPTO',
          walletValue,
          selectedNetwork,
        );
      } catch (error) {
        setIsLoading(false);
        closeWithdrawModal();
      } finally {
        setIsLoading(false);
        closeWithdrawModal();
      }
    }
  };

  useEffect(() => {
    if (!isTokensLoading) {
      (async () => {
        if (tokens && tokens.accessToken !== prevTokenRef.current) {
          setIsLoading(true);
          prevTokenRef.current = tokens.accessToken;
          try {
            const data = await fetchTraderAreaData(tokens.accessToken);
            setAccounts(data.accounts);
            setWallet(data.walletInfo);
          } finally {
            setIsLoading(false);
          }
        } else if (!tokens) {
          logout();
        }
      })();
    }
  }, [tokens, isTokensLoading]);

  useEffect(() => {
    if (!isTokensLoading && tokens?.accessToken && user) {
      const fetchData = async () => {
        try {
          const profileData = await fetchProfile(
            tokens.accessToken,
            user.userId,
          );
          setKycStatus(profileData.isKYCValidated);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } else if (!tokens) {
      logout();
    }
  }, [tokens, isTokensLoading]);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: isDarkMode ? '#262729' : '#ffffff',
    color: isDarkMode ? '#ffffff' : '#262729',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflowY: 'auto',
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <MUIModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stepper
            steps={steps}
            currentStep={currentStep}
            onStepChange={handleStepChange}
            className={classes.stepper}
          >
            <form>
              {currentStep === 0 && (
                <div>
                  {error && <Error error={error} />}
                  <h2>
                    {t('withdrawFunds.stepOne.selectPaiementMethod', {
                      ns: 'clientArea',
                    })}
                  </h2>
                  <Select
                    id="paymentMethod"
                    options={paymentMethod}
                    onSelect={handleSelect}
                  />
                </div>
              )}
              {currentStep === 1 && (
                <div>
                  {error && <Error error={error} />}

                  <h2 className="h2-container">
                    {selectedOption ===
                    t('withdrawFunds.stepOne.cryptoWallet', {
                      ns: 'clientArea',
                    })
                      ? t('withdrawFunds.stepTwo.enterCryptoWallet', {
                          ns: 'clientArea',
                        })
                      : null}
                    {selectedOption ===
                    t('withdrawFunds.stepOne.cryptoWallet', {
                      ns: 'clientArea',
                    }) ? (
                      <Tooltip
                        content={
                          selectedOption ===
                          t('withdrawFunds.stepOne.cryptoWallet', {
                            ns: 'clientArea',
                          })
                            ? t('withdrawFunds.stepTwo.helpCrypto', {
                                ns: 'clientArea',
                              })
                            : null
                        }
                        className="tooltip-text"
                      >
                        <Tag
                          label={``}
                          color="#ffffff"
                          icon={
                            <Icon
                              fill="#ffffff"
                              color=""
                              size={16}
                              path={
                                'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 11V17H13V11H11ZM11 7V9H13V7H11Z'
                              }
                            />
                          }
                        />
                      </Tooltip>
                    ) : null}
                  </h2>
                  {selectedOption ===
                  t('withdrawFunds.stepOne.bankAccount', {
                    ns: 'clientArea',
                  }) ? (
                    <>
                      <Typography variant="h5">
                        {t('withdrawFunds.stepTwo.BANK_INFORMATION_MESSAGE', {
                          ns: 'clientArea',
                        })}
                      </Typography>
                    </>
                  ) : (
                    <div>
                      <Input
                        className={classes.stepperComponents}
                        placeholder={t(
                          'withdrawFunds.stepTwo.cryptoWalletPlaceholder',
                          { ns: 'clientArea' },
                        )}
                        value={walletValue}
                        onChange={handleWalletChange}
                        required
                      />

                      <Autocomplete
                        id="select-network"
                        options={networkList}
                        getOptionLabel={(option) => option.name}
                        onChange={handleNetworkChange}
                        sx={{
                          marginTop: '20px',
                          zIndex: 6000,
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              '& > img': { mr: 2, flexShrink: 0 },
                            }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={option.src}
                              src={option.src}
                              alt=""
                            />
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            className={classes.networkLabel}
                            {...params}
                            label={t(
                              'withdrawFunds.stepTwo.selectNetworkPlaceholder',
                              { ns: 'clientArea' },
                            )}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            sx={{
                              '& .MuiInputBase-input': {
                                color: isDarkMode ? 'white' : '#333333',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: isDarkMode
                                    ? '#4f4f51'
                                    : '#d4d4d4',
                                  borderWidth: '2px',
                                },
                                '&:hover fieldset': {
                                  borderColor: isDarkMode
                                    ? '#4f4f51'
                                    : '#d4d4d4',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#e73d44',
                                },
                              },
                              '& .MuiSvgIcon-root': {
                                color: isDarkMode ? 'white' : '#333333',
                              },
                              '& .MuiInputLabel-root': {
                                color: isDarkMode ? 'white' : '#333333',
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
              {currentStep === 2 && (
                <div>
                  {error && <Error error={error} />}
                  <h2>
                    {t('withdrawFunds.stepThree.enterTheAmount', {
                      ns: 'clientArea',
                    })}
                  </h2>
                  <p className="max-balance-text">
                    {t('withdrawFunds.stepThree.BALANCE', {
                      ns: 'clientArea',
                    })}{' '}
                    {wallet?.currentBalance}€
                  </p>
                  {selectedOption ===
                    t('withdrawFunds.stepOne.bankAccount', {
                      ns: 'clientArea',
                    }) && (
                    <p className="max-balance-text">
                      {t('withdrawFunds.stepThree.max', {
                        ns: 'clientArea',
                      })}{' '}
                      8400€
                    </p>
                  )}

                  <Input
                    className={classes.stepperComponents}
                    id="amount"
                    type="text"
                    placeholder={t(
                      'withdrawFunds.stepThree.enterAmountPlaceholder',
                      { ns: 'clientArea' },
                    )}
                    onKeyDown={handleKeyDown}
                    value={amount === '0' ? '' : amount}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      let formattedValue = inputValue
                        .replace(',', '.')
                        .replace(/[^0-9.]+/g, '');

                      const parts = formattedValue.split('.');
                      if (parts.length > 2) {
                        formattedValue =
                          parts[0] + '.' + parts.slice(1).join('');
                      }

                      setAmount(formattedValue);
                    }}
                  />

                  <Button
                    className={classes.stepperComponents}
                    variant="primary"
                    onClick={(e) => {
                      handleWithdrawSubmit(e);
                    }}
                  >
                    {t('headerCards.withdrawButton', { ns: 'clientArea' })}
                  </Button>
                </div>
              )}
            </form>
          </Stepper>
        </Box>
      </MUIModal>

      <div className={classes.cardsContainer}>
        <IconCard
          title={t('headerCards.challengeActive')}
          amount={0 || wallet?.activeChallenges}
          icon="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18ZM12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14Z"
          className={classes.card}
        />
        <IconCard
          title={t('headerCards.fundedAccount')}
          amount={0 || wallet?.fundedAccounts}
          icon="M2 20H22V22H2V20ZM4 12H6V19H4V12ZM9 12H11V19H9V12ZM13 12H15V19H13V12ZM18 12H20V19H18V12ZM2 7L12 2L22 7V11H2V7ZM12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
          className={classes.card}
        />
        <IconCard
          title={t('headerCards.currentWallet')}
          amount={`${0 || wallet?.currentBalance} €`}
          icon="M2.00488 9H21.0049C21.5572 9 22.0049 9.44772 22.0049 10V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V9ZM3.00488 3H18.0049V7H2.00488V4C2.00488 3.44772 2.4526 3 3.00488 3ZM15.0049 14V16H18.0049V14H15.0049Z"
          percent={0 || wallet?.walletVariation}
          button={
            <div className={classes.withdrawButtons}>
              <Button
                variant={kycStatus ? 'primary' : 'disabled'}
                onClick={kycStatus ? handleOpen : sendToast}
              >
                {t('headerCards.withdrawButton')}
              </Button>
            </div>
          }
          className={classes.lastCard}
        />
      </div>
      <div className={classes.fundedContainer}>
        <h2 className={classes.sectionTitle}>
          <Icon
            color="black"
            size={32}
            path="M2 20H22V22H2V20ZM4 12H6V19H4V12ZM9 12H11V19H9V12ZM13 12H15V19H13V12ZM18 12H20V19H18V12ZM2 7L12 2L22 7V11H2V7ZM12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
          />
          {t('main.fundedAccountSectionTitle')}
        </h2>
        {accounts.length === 0 ? (
          <h2 className={classes.noAccountMessage}>
            {t('main.noFundedAccounts', { ns: 'traderArea' })}
          </h2>
        ) : (
          <div className={classes.accountCardsContainer}>
            {accounts.map((account: any) => (
              <AccountCard
                key={account.accountLogin}
                accountNumber={account?.accountLogin}
                balance={account?.balance}
                result={'Funded'}
                profit={account?.profit}
                upgradeButton={
                  account?.canScaleUp ? (
                    <Button
                      variant="primary"
                      onClick={() => openScaleUpModal(account?.accountLogin)}
                    >
                      {t('accountCard.scaleUp', { ns: 'components' })}
                    </Button>
                  ) : null
                }
                payoutButton={
                  account?.canPayout ? (
                    <Button
                      variant="primary"
                      onClick={() => openPayoutModal(account?.accountLogin)}
                    >
                      {t('accountCard.getPayout', { ns: 'components' })}
                    </Button>
                  ) : null
                }
                onClick={() => openModal(account?.accountLogin)}
              >
                <div className={classes.accountContent}>
                  <Card className={classes.chart}>
                    <ZoomableChart
                      labels={Array.from(
                        { length: account?.listOfProfits.length },
                        (_, i) => i.toString(),
                      )}
                      datasets={[
                        {
                          label: 'Profits',
                          data: account?.listOfProfits,
                          fill: 'start',
                          backgroundColor: 'rgba(231, 71, 80, 0.1)',
                          borderColor: 'rgb(231, 71, 80)',
                        },
                      ]}
                      xAxisTitle="Number of trades"
                      yAxisTitle="Profits"
                      annotations={[
                        {
                          id: 'maxDailyLoss',
                          content: `Max Daily Loss: ${account?.maxDailyLossChart}`,
                          borderColor: 'rgb(231, 71, 80)',
                          yMin: account?.maxDailyLossChart,
                          yMax: account?.maxDailyLossChart,
                        },
                        {
                          id: 'maxLoss',
                          content: `Max Loss: ${account?.maxLossPassed.targetValue}`,
                          borderColor: 'rgb(231, 71, 80)',
                          yMin: account?.maxLossPassed.targetValue,
                          yMax: account?.maxLossPassed.targetValue,
                        },
                      ]}
                    />
                  </Card>
                  <table
                    className={`${classes.challengesTable} ${
                      isDarkMode ? classes.dark : classes.light
                    }`}
                  >
                    <tbody className={classes.challengesTable__body}>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.status', {
                            ns: 'components',
                          })}
                        </td>
                        <td className={classes.status}>Funded</td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.startDate', {
                            ns: 'components',
                          })}
                        </td>
                        <td>
                          {account?.startDate === null
                            ? t('waiting', { ns: 'common' })
                            : account?.startDate}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.startingBalance', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.deposit}</td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.netProfitLoss', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.profit}$</td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.balance', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.balance}$</td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.totalPayout', {
                            ns: 'components',
                          })}
                        </td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.lastPayout', {
                            ns: 'components',
                          })}
                        </td>
                        <td>
                          {account?.lastPayoutDate === null
                            ? t('waiting', { ns: 'common' })
                            : account?.lastPayoutDate}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={classes.tradesInfos}>
                  <h2>
                    {t(
                      'accountCard.moreTradingInformationsCards.moreTradingInformations',
                      { ns: 'components' },
                    )}
                  </h2>
                  <Stack sx={{ padding: '0 2vw' }}>
                    <Stack
                      sx={{
                        position: 'relative',
                        marginBottom: '80px',
                        marginTop: '80px',
                        border: '2px solid #CCCCCC',
                        width: '100%',
                        height: '16px',
                        borderRadius: '50px',
                      }}
                    >
                      <Stack
                        sx={{
                          width: `${account?.consistencyRule.currentPercentage}%`,
                          maxWidth: '100%',
                          backgroundColor: '#E74750',
                          height: '14px',
                          borderRadius: '50px 0px 0px 50px',
                        }}
                      >
                        <Stack
                          sx={{
                            position: 'absolute',
                            height: '38px',
                            width: '2px',
                            backgroundColor: '#E74750',
                            top: '0',
                            bottom: '0',
                            margin: 'auto 0',
                            left: `min(${account?.consistencyRule.currentPercentage}%, 100%)`,
                            '::before': {
                              textWrap: 'nowrap',
                              whiteSpace: 'nowrap',
                              content: `"${t('PROGRESS_BAR.ACTUAL', {
                                ns: 'clientArea',
                              })} ${account?.consistencyRule
                                .currentPercentage}%"`,
                              position: 'absolute',
                              top: '-50px',
                              left: '50%',
                              transform:
                                account?.consistencyRule.currentPercentage < 5
                                  ? 'translateX(-0%)'
                                  : account?.consistencyRule.currentPercentage >
                                    90
                                  ? 'translateX(-100%)'
                                  : 'translateX(-50%)',
                              fontWeight: 'bold',
                              color: '#E74750',
                            },
                            '::after': {
                              textWrap: 'nowrap',
                              whiteSpace: 'nowrap',
                              content: `"${t('PROGRESS_BAR.MAX_DAILY_PROFIT', {
                                ns: 'clientArea',
                              })} ${account?.consistencyRule.maxDailyProfit}"`,
                              position: 'absolute',
                              bottom: '-50px',
                              left: '50%',
                              transform:
                                account?.consistencyRule.currentPercentage < 5
                                  ? 'translateX(-0%)'
                                  : account?.consistencyRule.currentPercentage >
                                    90
                                  ? 'translateX(-100%)'
                                  : 'translateX(-50%)',
                              fontWeight: 'bold',
                            },
                          }}
                        ></Stack>
                      </Stack>

                      <Stack
                        sx={{
                          position: 'absolute',
                          height: '38px',
                          width: '2px',
                          backgroundColor: '#CCCCCC',
                          top: '0',
                          bottom: '0',
                          margin: 'auto 0',
                          left: '45%',
                          '::before': {
                            textWrap: 'nowrap',
                            whiteSpace: 'nowrap',
                            content: `"${t('PROGRESS_BAR.45_PERCENT_RULE', {
                              ns: 'clientArea',
                            })}"`,
                            position: 'absolute',
                            top: '-30px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontWeight: 'bold',
                          },
                          '::after': {
                            textWrap: 'nowrap',
                            whiteSpace: 'nowrap',
                            content: `"${account?.consistencyRule.minimumPayoutValue}$"`,
                            position: 'absolute',
                            bottom: '-30px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontWeight: 'bold',
                          },
                        }}
                      ></Stack>
                    </Stack>
                  </Stack>
                  <table
                    className={`${classes.challengesTable} ${
                      isDarkMode ? classes.dark : classes.light
                    }`}
                    style={{ width: '100%' }}
                  >
                    <thead className={classes.challengesTable__header}>
                      <tr>
                        <th></th>
                        <th>{t('PAYOUT_TABLE.DATE')}</th>
                        <th>{t('PAYOUT_TABLE.STATE')}</th>
                      </tr>
                    </thead>
                    <tbody className={classes.challengesTable__body}>
                      <tr>
                        <td>{t('PAYOUT_TABLE.NEXT_AVAILABLE_PAYOUT')}</td>
                        <td>{account?.nextPayoutDate || 'N/A'}</td>
                        <td>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            gap={1}
                          >
                            {account?.payoutAvailable ? (
                              <CheckCircleIcon sx={{ color: '#1fcc92' }} />
                            ) : (
                              <CancelIcon sx={{ color: '#e73d44' }} />
                            )}
                            {account?.payoutAvailable
                              ? t('PAYOUT_TABLE.AVAILABLE')
                              : t('PAYOUT_TABLE.NOT_AVAILABLE')}
                          </Stack>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={classes.cards}>
                    <Card
                      className={`${classes.card} ${
                        isDarkMode ? classes.dark : classes.light
                      }`}
                    >
                      <h3>
                        {t(
                          'accountCard.moreTradingInformationsCards.averageProfitLossPerTrade',
                          { ns: 'components' },
                        )}
                      </h3>
                      <p>{account?.averageProfitLossPerTrade.value} $</p>
                    </Card>
                    <Card
                      className={`${classes.card} ${
                        isDarkMode ? classes.dark : classes.light
                      }`}
                    >
                      <h3>
                        {t(
                          'accountCard.moreTradingInformationsCards.numberOfTrades',
                          { ns: 'components' },
                        )}
                      </h3>
                      <p>{account?.numberOfTrades.value}</p>
                    </Card>
                    <Card
                      className={`${classes.card} ${
                        isDarkMode ? classes.dark : classes.light
                      }`}
                    >
                      <h3>
                        {t(
                          'accountCard.moreTradingInformationsCards.totalLotsTraded',
                          { ns: 'components' },
                        )}
                      </h3>
                      <p>{account?.totalLotsTraded.value}</p>
                    </Card>
                    <Card
                      className={`${classes.card} ${
                        isDarkMode ? classes.dark : classes.light
                      }`}
                    >
                      <h3>
                        {t('accountCard.moreTradingInformationsCards.winRate', {
                          ns: 'components',
                        })}
                      </h3>
                      <p>{account?.winRate.value}%</p>
                    </Card>
                  </div>
                </div>
              </AccountCard>
            ))}

            {accounts.map((account: any) => (
              <React.Fragment key={account.accountLogin}>
                <Modal
                  key={account.accountLogin}
                  title={t('credentialsModal.title', { ns: 'clientArea' })}
                  isOpen={isModalOpen[account.accountLogin]}
                  onClose={() => closeModal(account.accountLogin)}
                >
                  <div className={classes.credentials}>
                    <p>{t('credentialsModal.login', { ns: 'clientArea' })}</p>
                    <p ref={idRef}>{account?.accountLogin}</p>
                    <p
                      className={classes.copyButton}
                      onClick={() => handleCopyId()}
                    >
                      <Icon
                        size={24}
                        color="white"
                        path="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"
                      />
                      {idCopied
                        ? t('copied', { ns: 'common' })
                        : t('copy', { ns: 'common' })}
                    </p>
                  </div>
                  <hr className={classes.credentialsLine} />
                  <div className={classes.credentials}>
                    <p>
                      {t('credentialsModal.password', { ns: 'clientArea' })}
                    </p>
                    <div
                      className={`${classes.credentialsPassword} ${
                        isDarkMode ? classes.dark : classes.light
                      }`}
                    >
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={account?.accountPassword}
                        readOnly
                        ref={passwordRef}
                      />
                      <Icon
                        onClick={() => handleShowPassword()}
                        size={16}
                        color="white"
                        path={
                          showPassword
                            ? 'M4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62282 2.81932 7.5129 4.52047 5.93457ZM14.7577 16.1718L13.2937 14.7078C12.902 14.8952 12.4634 15.0002 12.0003 15.0002C10.3434 15.0002 9.00026 13.657 9.00026 12.0002C9.00026 11.537 9.10522 11.0984 9.29263 10.7067L7.82866 9.24277C7.30514 10.0332 7.00026 10.9811 7.00026 12.0002C7.00026 14.7616 9.23884 17.0002 12.0003 17.0002C13.0193 17.0002 13.9672 16.6953 14.7577 16.1718ZM7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87992 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925L16.947 12.7327C16.9821 12.4936 17.0003 12.249 17.0003 12.0002C17.0003 9.23873 14.7617 7.00016 12.0003 7.00016C11.7514 7.00016 11.5068 7.01833 11.2677 7.05343L7.97446 3.76015Z'
                            : 'M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z'
                        }
                      />
                    </div>
                    <p
                      className={classes.copyButton}
                      onClick={() => handleCopyPassword()}
                    >
                      <Icon
                        size={24}
                        color="white"
                        path="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"
                      />
                      {passwordCopied
                        ? t('copied', { ns: 'common' })
                        : t('copy', { ns: 'common' })}
                    </p>
                  </div>
                  <hr className={classes.credentialsLine} />
                  <div className={classes.credentials}>
                    <p>{t('credentialsModal.server', { ns: 'clientArea' })}</p>
                    <p ref={serverRef}>{account?.server}</p>
                    <p
                      className={classes.copyButton}
                      onClick={() => handleCopyServer()}
                    >
                      <Icon
                        size={24}
                        color="white"
                        path="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"
                      />
                      {serverCopied
                        ? t('copied', { ns: 'common' })
                        : t('copy', { ns: 'common' })}
                    </p>
                  </div>
                </Modal>

                <Modal
                  key={account.accountLogin + 'scaleUp'}
                  title={t('scaleUpModal.title', { ns: 'components' })}
                  isOpen={isScaleUpModalOpen[account.accountLogin]}
                  onClose={() => closeScaleUpModal(account.accountLogin)}
                >
                  <div className={classes.scaleUpModal}>
                    <ul>
                      <li>
                        {t('scaleUpModal.upgradeMT5Account', {
                          ns: 'components',
                        })}
                      </li>
                      <li>
                        {t('scaleUpModal.gainProfitSplit', {
                          ns: 'components',
                        })}
                      </li>
                    </ul>
                    <p>
                      {t('scaleUpModal.resetCurrentProfit', {
                        ns: 'components',
                      })}
                    </p>

                    <div className={classes.buttons}>
                      <Button
                        variant="primary"
                        onClick={() => scaleUpFundedAccount(account.fundedUUID)}
                      >
                        {t('scaleUpModal.confirmButton', { ns: 'components' })}
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => closeScaleUpModal(account.accountLogin)}
                      >
                        {t('cancel', { ns: 'common' })}
                      </Button>
                    </div>
                  </div>
                </Modal>

                <Modal
                  key={account.accountLogin + 'getPayout'}
                  title={t('getPayoutModal.title')}
                  isOpen={isPayoutModalOpen[account.accountLogin]}
                  onClose={() => closePayoutModal(account.accountLogin)}
                >
                  <div className={classes.payoutModal}>
                    <p>{t('getPayoutModal.scaleUpTips')}</p>
                    <p>{t('getPayoutModal.enterAnAmount')}</p>
                    {error && <Error error={error} />}
                    <p className="max-amount-text">
                      {t('getPayoutModal.max')} {account?.profit}
                    </p>
                    <Input
                      // TODO : MODIFIY THIS
                      id="amountWallet"
                      type="number"
                      min={0}
                      placeholder={t('getPayoutModal.enterAnAmount')}
                      onKeyDown={handleKeyDown}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />

                    <div className={classes.buttons}>
                      <Button
                        onClick={() => {
                          amount > account?.profit
                            ? setError(t('getPayoutModal.errorInvalidAmount'))
                            : (() => {
                                getPayout(
                                  account.accountLogin,
                                  parseInt(amount),
                                  tokens?.accessToken,
                                );
                                closePayoutModal(account.accountLogin);
                              })();
                        }}
                        variant="primary"
                      >
                        {t('getPayoutModal.proceedButton')}
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => closePayoutModal(account.accountLogin)}
                      >
                        {t('cancel', { ns: 'common' })}
                      </Button>
                    </div>
                  </div>
                </Modal>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
