import { useTranslation } from 'react-i18next';

import { useAuth } from '../../context/AuthContext/AuthContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import Card from '../../components/Cards/Card/Card';
import Table from '../../components/Table/Table';

import classes from './_download.module.scss';
import { Link, useNavigate } from 'react-router-dom';

const Download = () => {
  const { t } = useTranslation('download');
  const { tokens } = useAuth();
  const navigate = useNavigate();

  useDocumentTitle('Download');

  if(!tokens) {
    navigate('/');
  }

  const data = [
    {
      [t('description')]: t('windowsMT5Platform'),
      [t('file')]: (
        <Link
          to={'https://download.mql5.com/cdn/web/raise.global.sa/mt5/raiseglobalsa5setup.exe'}
        >
          {t('windowsMT5Application')}
        </Link>
      ),
      [t('moreInfo')]: <Link to={'https://www.metatrader5.com/fr/terminal/help/startworking'}>{t('mtTutorial')}</Link>,
    },
    {
      [t('description')]: t('iosMT5Platform'),
      [t('file')]: (
        <Link
          to={'https://apps.apple.com/us/app/metatrader-5/id413251709'}
        >
          {t('iosMT5Application')}
        </Link>
      ),
      [t('moreInfo')]: <Link to={'https://www.metatrader5.com/fr/terminal/help/startworking'}>{t('mtTutorial')}</Link>,
    },
    {
      [t('description')]: t('androidMT5Platform'),
      [t('file')]: (
        <Link target='_blank'
          to={'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&hl=en_US&pli=1'}
        >
          {t('androidMT5Application')}
        </Link>
      ),
      [t('moreInfo')]: <Link to={'https://www.metatrader5.com/fr/terminal/help/startworking'}>{t('mtTutorial')}</Link>,
    },
  ];
  const columns = [t('description'), t('file'), t('moreInfo')];

  return (
    <Card className={classes.card}>
      <h2> {t('download')} </h2>
      <Table data={data} columns={columns} />
    </Card>
  );
};
export default Download;
