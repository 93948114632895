import React, { FormEvent, useState, useEffect } from 'react';
import Modal from '../../../components/Modal/Modal';
import Input from '../../../components/userInterface/Input/Input';
import Button from '../../../components/userInterface/Button/Button';
import Select from '../../../components/userInterface/Select/Select';

import { logout } from '../../../services/auth/login';
import { useAuth } from '../../../context/AuthContext/AuthContext';

import { createUser } from '../../../services/admin/users/users';

import classes from './_users.module.scss';
import { isCountryAuthorized } from '../../../utils/isCountryAuthorized';

interface CreateUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { user, tokens, isTokensLoading } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [referredBy, setReferredBy] = useState<string>('');
  const [role, setRole] = useState<string>('ROLE_USER');
  const [country, setCountry] = useState<string>('');
  const [countryOptions, setCountryOptions] = useState<string[]>([]);

  useEffect(() => {
    fetch('https://live.raisemyfunds.co/api/localisation/allowed-countries')
      .then((response: any) => response.json())
      .then((data) => {
        setCountryOptions(data);
      });
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const userData = {
      email: email,
      phoneNumber: phoneNumber,
      firstname: firstname,
      lastname: lastname,
      country: country,
      ipAddress: '192.168.1.1',
      referredBy: referredBy,
      role: role,
    };

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (tokens && tokens.accessToken) {
      setIsLoading(true);

      try {
        createUser(tokens.accessToken, userData, user?.userId);
        setError('');
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    } else if (!tokens) {
      logout();
    }
  };

  return (
    <Modal title="Create User" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <div>
          <div className={classes.formContainer}>
            <div className={classes.formGroup}>
              <label htmlFor="email">Email (required)</label>
              <Input
                id="email"
                type="email"
                required
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value.replace(/\s+/g, ''))}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="phoneNumber">Phone number (required)</label>
              <Input
                id="phoneNumber"
                type="tel"
                required
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.formContainer}>
            <div className={classes.formGroup}>
              <label htmlFor="firstname">Firstname (required)</label>
              <Input
                id="firstname"
                required
                placeholder="Firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="lastname">Lastname (required)</label>
              <Input
                id="lastname"
                required
                placeholder="Lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.formGroup}>
            <label htmlFor="referredBy">Referred By (optional)</label>
            <Input
              id="referredBy"
              placeholder="Referred By (UUID)"
              value={referredBy}
              onChange={(e) => setReferredBy(e.target.value)}
            />
          </div>
          <div className={classes.formContainer}>
            <div className={classes.formGroup}>
              <label htmlFor="role">Role (required)</label>
              <Select
                id="role"
                options={['ROLE_USER']}
                defaultValue={role}
                onSelect={(e) => setRole(e)}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="country">Country (required)</label>
              <Select
                id="country"
                options={countryOptions}
                defaultValue={country}
                onSelect={(e) => setCountry(e)}
              />
            </div>
          </div>
          <Button variant="primary" type="submit">
            Create User
          </Button>
        </div>
      </form>
    </Modal>
  );
};
export default CreateUserModal;
