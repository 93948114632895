import React, { createContext, useContext, useState } from 'react';

import { LoginResponse, JWTPayload } from '../../services/auth/interfaces';
import { decodeJWT, loginUser } from '../../services/auth/login';

interface AuthContextProps {
  tokens: LoginResponse | null;
  user: JWTPayload | null;
  login: (email: string, password: string, remember: boolean) => Promise<void>;
  isTokensLoading: boolean;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [tokens, setTokens] = useState<LoginResponse | null>(null);
  const [user, setUser] = useState<JWTPayload | null>(null);
  const [isTokensLoading, setIsTokensLoading] = useState(true);

  React.useEffect(() => {
    const storedLocalAccessToken =
      localStorage.getItem('accessToken') ||
      sessionStorage.getItem('accessToken');
    const storedLocalRefreshToken =
      localStorage.getItem('refreshToken') ||
      sessionStorage.getItem('refreshToken');

    if (storedLocalAccessToken && storedLocalRefreshToken) {
      setTokens({
        accessToken: storedLocalAccessToken,
        refreshToken: storedLocalRefreshToken,
      });
      const decoded = decodeJWT(storedLocalAccessToken);
      if (decoded) setUser(decoded);
    }

    setIsTokensLoading(false);
  }, []);

  const login = async (email: string, password: string, remember: boolean) => {
    try {
      const { tokens, user } = await loginUser(email, password, remember);

      setTokens(tokens);
      setUser(user);
    } catch (error) {
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        tokens,
        isTokensLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }
  return context;
};
