import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

type CountdownProps = {
  targetDate: any;
};

const Countdown: React.FC<CountdownProps> = ({ targetDate }) => {
  const { t } = useTranslation(['calendar']);

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const isTimeUp =
    timeLeft.days === 0 &&
    timeLeft.hours === 0 &&
    timeLeft.minutes === 0 &&
    timeLeft.seconds === 0;

  return (
    <div>
      {isTimeUp ? (
        <p>{t('TABLE.ACHIEVED')}</p>
      ) : (
        <Typography sx={{ fontWeight: 'bold' }}>
          {timeLeft.days === 0 ? null : timeLeft.days + ':'}
          {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
        </Typography>
      )}
    </div>
  );
};

export default Countdown;
