import { useState } from 'react';

import { useTheme } from '../../../context/ThemeContext/ThemeContext';

import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/userInterface/Button/Button';
import Icon from '../../../components/userInterface/Icon/Icon';
import Tooltip from '../../../components/userInterface/Tooltip/Tooltip';

import classes from './_userProfileTable.module.scss';

interface UserProfileTableProps {
  data: Array<{ [key: string]: any }>;
  columns: string[];
  affiliateButton?: boolean;
  deleteButton?: boolean;
  onDeleteButtonClick?: (id: number) => void;
  onAffiliationButtonClick?: (id: number) => void;
}

type ActionType = 'accept' | 'refuse' | 'delete' | 'reattribute';
type ModalStateType = {
  isOpen: boolean;
  action: ((id: number) => void) | null;
  id: number | null;
  actionType: ActionType | '';
};

const UserProfileTable: React.FC<UserProfileTableProps> = ({
  data,
  columns,
  deleteButton,
  onDeleteButtonClick,
  affiliateButton,
  onAffiliationButtonClick,
}) => {
  const { isDarkMode } = useTheme();

  const [modalState, setModalState] = useState<ModalStateType>({
    isOpen: false,
    action: null,
    id: null,
    actionType: '',
  });

  const openConfirmationModal = (
    id: number,
    action: (id: number) => void,
    actionType: ActionType,
  ) => {
    setModalState({ isOpen: true, action, id, actionType });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, action: null, id: null, actionType: '' });
  };

  const handleConfirm = () => {
    if (modalState.action && modalState.id !== null) {
      modalState.action(modalState.id);
      closeModal();
    }
  };

  return (
    <div className={classes.tableContainer}>
      {modalState.isOpen && (
        <Modal
          title={`Are you sure you want to ${modalState.actionType} this item?`}
          isOpen={modalState.isOpen}
          onClose={closeModal}
          showCloseButton={true}
        >
          <div className={classes.modalButtons}>
            <Button
              onClick={handleConfirm}
              variant={
                modalState.actionType === 'accept'
                  ? 'green-primary'
                  : modalState.actionType === 'refuse'
                  ? 'primary'
                  : 'primary'
              }
            >
              {modalState.actionType}
            </Button>
            <Button onClick={closeModal} variant="outline">
              Cancel
            </Button>
          </div>
        </Modal>
      )}
      <table
        className={`${classes.challengesTable} ${
          isDarkMode ? classes.dark : classes.light
        }`}
      >
        <thead className={classes.tableHead}>
          <tr>
            {columns.map((column, index) => (
              <th
                className={isDarkMode ? classes.dark : classes.light}
                key={index}
              >
                {column}
              </th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody className={classes.challengesTable__body}>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, columnIndex) => (
                <td key={columnIndex}>{row[column]}</td>
              ))}
              <td className={classes.actions}>
                {deleteButton && onDeleteButtonClick ? (
                  <Icon
                    onClick={() =>
                      openConfirmationModal(
                        row.ID,
                        onDeleteButtonClick,
                        'delete',
                      )
                    }
                    color="white"
                    size={18}
                    path="M7 6V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7ZM9 4V6H15V4H9Z"
                  />
                ) : null}
                {affiliateButton && onAffiliationButtonClick ? (
                  <Tooltip
                    position="left"
                    content="Assign commissions to the affiliate"
                  >
                    <Icon
                      onClick={() =>
                        openConfirmationModal(
                          row.ID,
                          onAffiliationButtonClick,
                          'reattribute',
                        )
                      }
                      color="custom"
                      fill="#d4d4d4"
                      size={20}
                      path="M19.3788 15.1057C20.9258 11.4421 19.5373 7.11431 16.0042 5.0745C13.4511 3.60046 10.4232 3.69365 8.03452 5.0556L7.04216 3.31879C10.028 1.61639 13.8128 1.4999 17.0042 3.34245C21.4949 5.93513 23.2139 11.4848 21.1217 16.112L22.4635 16.8867L18.2984 19.1008L18.1334 14.3867L19.3788 15.1057ZM4.62961 8.89968C3.08263 12.5633 4.47116 16.8911 8.00421 18.9309C10.5573 20.4049 13.5851 20.3118 15.9737 18.9499L16.9661 20.6867C13.9803 22.389 10.1956 22.5055 7.00421 20.663C2.51357 18.0703 0.794565 12.5206 2.88672 7.89342L1.54492 7.11873L5.70999 4.90463L5.87505 9.61873L4.62961 8.89968ZM8.50421 14.0027H14.0042C14.2804 14.0027 14.5042 13.7788 14.5042 13.5027C14.5042 13.2266 14.2804 13.0027 14.0042 13.0027H10.0042C8.6235 13.0027 7.50421 11.8834 7.50421 10.5027C7.50421 9.122 8.6235 8.00271 10.0042 8.00271H11.0042V7.00271H13.0042V8.00271H15.5042V10.0027H10.0042C9.72807 10.0027 9.50421 10.2266 9.50421 10.5027C9.50421 10.7788 9.72807 11.0027 10.0042 11.0027H14.0042C15.3849 11.0027 16.5042 12.122 16.5042 13.5027C16.5042 14.8834 15.3849 16.0027 14.0042 16.0027H13.0042V17.0027H11.0042V16.0027H8.50421V14.0027Z"
                    />
                  </Tooltip>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default UserProfileTable;
