import { useCallback, useEffect, useState } from 'react';

import {
  createPromotionCode,
  deletePromotionCode,
  editPromotionCode,
  fetchPromotionCodes,
} from '../../../services/admin/promotion/promotion';
import { logout } from '../../../services/auth/login';
import { PromotionCodesResponseContent } from '../../../services/admin/promotion/interface';

import { useAuth } from '../../../context/AuthContext/AuthContext';
import { useTheme as useRMFTheme } from '../../../context/ThemeContext/ThemeContext';

import useDocumentTitle from '../../../hooks/useDocumentTitle';

import Loader from '../../../components/Loader/Loader';
import Pagination from '../../../components/Pagination/Pagination';
import Icon from '../../../components/userInterface/Icon/Icon';
import Input from '../../../components/userInterface/Input/Input';
import Error from '../../../components/Error/Error';

import classes from './_promotion.module.scss';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Modal,
  useMediaQuery,
  useTheme,
  Typography,
  Button,
} from '@mui/material';
import Filter, { FilterType } from '../../../components/Filter/Filter';

const Promotion = () => {
  const { tokens, isTokensLoading } = useAuth();
  useDocumentTitle('Promotion');

  const theme = useTheme();
  const { isDarkMode } = useRMFTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const size = 10;

  const PromotionCodeFiltersTypes: FilterType[] = [
    { key: 'promoCode', type: 'string' },
    { key: 'userAdminId', type: 'string' },
    { key: 'minDiscount', type: 'string' },
    { key: 'numberOfUses', type: 'string' },
    { key: 'maxDiscount', type: 'string' },
    { key: 'active', type: 'boolean' },
  ];

  const tablePromotionCodeData = [];
  const tablePromotionCodeColumns = [
    'Code',
    'Description',
    'Discount (%)',
    'User ID',
    'Start Date',
    'End Date',
    'Max Usages Allowed',
    'Times Used',
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [promoCodeToDelete, setPromoCodeToDelete] = useState(null);
  const [promoCodeToEdit, setPromoCodeToEdit] = useState('');

  const [code, setCode] = useState<string>('');
  const [discount, setDiscount] = useState<number>(0);
  const [description, setDescription] = useState<string>('');
  const [numberOfUses, setNumberOfUses] = useState<number>();
  const [referrerId, setReferrerId] = useState<string>();
  const [selectedStartDate, setSelectedStartDate] = useState<
    string | undefined
  >();
  const [selectedEndDate, setSelectedEndDate] = useState<string | undefined>();

  const [promotionCodes, setPromotionCodes] = useState<
    PromotionCodesResponseContent[]
  >([]);
  const [promotionCodesPage, setPromotionCodesPage] = useState<number>(0);
  const [totalPromotionCodesPages, setTotalPromotionCodesPages] =
    useState<number>(0);
  const [promotionTotalOfElements, setPromotionTotalOfElements] =
    useState<number>(0);

  const [PromotionCodeFilters, setPromotionCodeFilters] = useState<{
    [key: string]: string;
  }>({});

  for (const code of promotionCodes) {
    tablePromotionCodeData.push({
      Code: code.promoCode,
      ID: code.promoId,
      Description: code.description,
      'Discount (%)': code.discount,
      'User ID': code.user ? code.user.adminId : '',
      'Start Date': code.startDate ? code.startDate.substring(0, 10) : '',
      'End Date': code.endDate ? code.endDate.substring(0, 10) : '',
      'Max Usages Allowed': code.numberOfUses,
      'Times Used': code.usedCount,
    });
  }

  function createData(
    code: string,
    id: string,
    description: string,
    discountPercentage: number,
    userId: string,
    startDate: string,
    endDate: string,
    maxUsagesAllowed: number | null,
    timesUsed: number,
  ) {
    return {
      code,
      id,
      description,
      discountPercentage,
      userId,
      startDate,
      endDate,
      maxUsagesAllowed,
      timesUsed,
    };
  }

  const rows = promotionCodes.map((code) =>
    createData(
      code.promoCode,
      code.promoId,
      code.description,
      code.discount,
      code.user ? code.user.adminId : '',
      code.startDate ? code.startDate.substring(0, 10) : '',
      code.endDate ? code.endDate.substring(0, 10) : '',
      code.numberOfUses,
      code.usedCount,
    ),
  );

  const loadPromotionCodes = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await fetchPromotionCodes(
          tokens.accessToken,
          promotionCodesPage,
          size,
          PromotionCodeFilters,
        );
        setPromotionCodes(response.content);
        setTotalPromotionCodesPages(response.totalPages);
        setPromotionTotalOfElements(response.totalElements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, promotionCodesPage, size, PromotionCodeFilters]);

  const handleDeletePromotionCode = async (id: any) => {
    if (tokens && tokens.accessToken) {
      await deletePromotionCode(tokens.accessToken, id);

      loadPromotionCodes();
    } else {
      logout();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isYearValid = (dateString: string): boolean => {
    const yearRegex = /^\d{4}-/;
    return yearRegex.test(dateString);
  };

  const isEndDateAfterStartDate = (
    startDate: string | undefined,
    endDate: string | undefined,
  ): boolean => {
    if (!startDate || !endDate) {
      return false;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    return end >= start;
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const date = event.target.value;

    if (isYearValid(date)) {
      setSelectedStartDate(date);
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;

    if (isYearValid(date)) {
      setSelectedEndDate(date);
    }
  };

  const handleApplyPromotionCodeSearch = useCallback(
    (filterType: string, filterValue: string) => {
      setPromotionCodeFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: filterValue,
      }));
    },
    [],
  );
  const handleRemovePromotionCodeFilter = useCallback((filterType: string) => {
    setPromotionCodeFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: '',
    }));
  }, []);

  const submitCreatePromotionCode = async (e: React.FormEvent) => {
    e.preventDefault();

    const promotionCodeData = {
      description: description,
      discount: discount,
      promoCode: code,
      id: referrerId || null,
      numberOfUses: numberOfUses || null,
      startDate: selectedStartDate || null,
      endDate: selectedEndDate || null,
    };

    if (!code && !discount && !description) {
      return setError('Merci de remplir les champs manquant.');
    }

    if (promotionCodes.some((promo) => promo.promoCode === code)) {
      return setError('Une promotion avec ce nom existe déjà.');
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (selectedEndDate) {
      const endDate = new Date(selectedEndDate);
      endDate.setHours(0, 0, 0, 0);

      if (endDate <= currentDate) {
        return setError(
          'La date de fin ne peut pas être inférieure ou égale à la date actuelle',
        );
      }
    }

    if (selectedStartDate && selectedEndDate) {
      const isValidDateRange = isEndDateAfterStartDate(
        selectedStartDate,
        selectedEndDate,
      );

      if (!isValidDateRange) {
        return setError(
          "L'année de début ne peut pas être avant la date de fin",
        );
      }
    }

    if (tokens && tokens.accessToken) {
      setIsLoading(true);

      try {
        await createPromotionCode(tokens.accessToken, promotionCodeData);
        setError('');
        closeModal();
        loadPromotionCodes();
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
        closeModal();
      }
    } else if (!tokens) {
      logout();
    }
  };

  const submitEditPromotionCode = async (e: React.FormEvent) => {
    e.preventDefault();

    const promotionCodeData = {
      description: description,
      discount: discount,
      promoCode: code,
      id: referrerId || null,
      numberOfUses: numberOfUses || null,
      startDate: selectedStartDate || null,
      endDate: selectedEndDate || null,
    };

    if (!code && !discount && !description) {
      return setError('Merci de remplir les champs manquant.');
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (selectedEndDate) {
      const endDate = new Date(selectedEndDate);
      endDate.setHours(0, 0, 0, 0);

      if (endDate <= currentDate) {
        return setError(
          'La date de fin ne peut pas être inférieure ou égale à la date actuelle',
        );
      }
    }

    if (selectedStartDate && selectedEndDate) {
      const isValidDateRange = isEndDateAfterStartDate(
        selectedStartDate,
        selectedEndDate,
      );

      if (!isValidDateRange) {
        return setError(
          "L'année de début ne peut pas être avant la date de fin",
        );
      }
    }

    if (tokens && tokens.accessToken) {
      setIsLoading(true);

      try {
        await editPromotionCode(
          tokens.accessToken,
          promoCodeToEdit,
          promotionCodeData,
        );
        setError('');
        handleCloseEditModal();
        loadPromotionCodes();
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
        handleCloseEditModal();
      }
    } else if (!tokens) {
      logout();
    }
  };

  const handleOpenConfirmationDialog = (id: any) => {
    setPromoCodeToDelete(id);
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setPromoCodeToDelete(null);
  };

  const handleOpenEditModal = (promoId: any) => {
    // Trouvez le code de promotion dans l'état `promotionCodes`
    const promoToEdit = promotionCodes.find(
      (promo) => promo.promoId === promoId,
    );
    if (promoToEdit) {
      // Pré-remplissez les champs du formulaire avec les données du code de promotion
      setCode(promoToEdit.promoCode);
      setDescription(promoToEdit.description);
      setDiscount(promoToEdit.discount);
      setNumberOfUses(
        promoToEdit.numberOfUses !== null
          ? promoToEdit.numberOfUses
          : undefined,
      );
      setReferrerId(promoToEdit.user ? promoToEdit.user.adminId : '');
      setSelectedStartDate(
        promoToEdit.startDate
          ? promoToEdit.startDate.substring(0, 10)
          : undefined,
      );
      setSelectedEndDate(
        promoToEdit.endDate ? promoToEdit.endDate.substring(0, 10) : undefined,
      );
      // Stockez l'ID pour l'utilisation dans la soumission du formulaire
      setPromoCodeToEdit(promoId);
      setIsModalOpen(true);
    }
  };

  const handleCloseEditModal = () => {
    setCode('');
    setDescription('');
    setDiscount(0);
    setNumberOfUses(undefined);
    setReferrerId('');
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    setPromoCodeToEdit('');
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isTokensLoading) {
      loadPromotionCodes();
    }
  }, [loadPromotionCodes, isTokensLoading]);

  if (isLoading) {
    return <Loader />;
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: isDarkMode ? '#262729' : '#ffffff',
    color: isDarkMode ? '#ffffff' : '#262729',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal open={isModalOpen} onClose={handleCloseEditModal}>
        <Box sx={style}>
          <Typography variant="h4" sx={{ marginBottom: '24px' }}>
            {promoCodeToEdit === ''
              ? 'Create a promotion code'
              : 'Edit a promo code'}
          </Typography>
          {error && <Error error={error} />}
          <form
            onSubmit={
              promoCodeToEdit === ''
                ? submitCreatePromotionCode
                : submitEditPromotionCode
            }
          >
            <div>
              <div className={classes.formContainer}>
                <div className={classes.formGroup}>
                  <label htmlFor="code">Promotion Code (Obligatoire)</label>
                  <Input
                    id="code"
                    placeholder="SuperCode, 58951-FTG"
                    required
                    value={code}
                    onChange={(e) =>
                      setCode(e.target.value.replace(/\s+/g, ''))
                    }
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="discount">
                    Discount Percentage (Obligatoire)
                  </label>
                  <Input
                    id="discount"
                    type="number"
                    min={1}
                    placeholder="10"
                    required
                    value={discount}
                    onChange={(e) => setDiscount(parseInt(e.target.value))}
                  />
                </div>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="description">Description (Obligatoire)</label>
                <Input
                  id="description"
                  placeholder="Code pour la Noël"
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div className={classes.formContainer}>
                <div className={classes.formGroup}>
                  <label htmlFor="usages">Number of Usages</label>
                  <Input
                    id="usages"
                    type="number"
                    min={1}
                    placeholder="5"
                    value={numberOfUses}
                    onChange={(e) => setNumberOfUses(parseInt(e.target.value))}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="referrer">Referrer's ID</label>
                  <Input
                    id="referrer"
                    placeholder="10"
                    value={referrerId}
                    onChange={(e) => setReferrerId(e.target.value)}
                  />
                </div>
              </div>
              <div className={classes.formContainer}>
                <div className={classes.formGroup}>
                  <label htmlFor="startDate">Start Date</label>
                  <Input
                    id="startDate"
                    type="date"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="endDate">End Date</label>
                  <Input
                    id="endDate"
                    type="date"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                  />
                </div>
              </div>
            </div>
            <Button variant="contained" type="submit">
              {promoCodeToEdit === '' ? 'Create a code' : 'Edit this code'}
            </Button>
          </form>
        </Box>
      </Modal>

      <Dialog
        fullScreen={fullScreen}
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="delete-dialog"
      >
        <DialogTitle
          id="delete-dialog"
          sx={{
            backgroundColor: isDarkMode ? '#262729' : '#ffffff',
            color: isDarkMode ? '#FFFFFF' : '#262729',
          }}
        >
          {'Are you sure you want to delete this promotion code?'}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: isDarkMode ? '#262729' : '#ffffff',
          }}
        >
          <DialogContentText sx={{ color: isDarkMode ? '#FFFFFF' : '#262729' }}>
            You are about to delete the promotion code "
            <strong>{promoCodeToDelete}</strong>". Do you really want to delete
            it?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: isDarkMode ? '#262729' : '#ffffff',
          }}
        >
          <Button variant="outlined" onClick={handleCloseConfirmationDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleCloseConfirmationDialog();
              handleDeletePromotionCode(promoCodeToDelete);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <h1 className={classes.title}>
        Promotion <Chip label={promotionTotalOfElements} color="primary" />
      </h1>

      <Filter
        filters={PromotionCodeFilters}
        onApplySearch={handleApplyPromotionCodeSearch}
        onRemoveFilter={handleRemovePromotionCodeFilter}
        filterTypes={PromotionCodeFiltersTypes}
      />
      {tablePromotionCodeData.length === 0 ? (
        'Aucun code de promotion actif'
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              backgroundColor: isDarkMode ? '#262729' : 'white',
              '& .MuiTableCell-root': {
                color: isDarkMode ? 'white' : 'black',
                borderBottom: '1px solid #484848',
                whiteSpace: 'nowrap',
              },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Code</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Discount (%)</TableCell>
                <TableCell align="center">User ID</TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">End Date</TableCell>
                <TableCell align="center">Max Usages Allowed</TableCell>
                <TableCell align="center">Times Used</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    position: 'sticky',
                    right: 0,
                    background: isDarkMode ? '#262729' : 'white',
                    zIndex: 1,
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.code}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{row.code}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.discountPercentage}</TableCell>
                  <TableCell align="center">{row.userId}</TableCell>
                  <TableCell align="center">{row.startDate}</TableCell>
                  <TableCell align="center">{row.endDate}</TableCell>
                  <TableCell align="center">{row.maxUsagesAllowed}</TableCell>
                  <TableCell align="center">{row.timesUsed}</TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      position: 'sticky',
                      right: 0,
                      background: isDarkMode ? '#262729' : 'white',
                      zIndex: 1,
                    }}
                  >
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleOpenConfirmationDialog(row.code)}
                      >
                        <DeleteIcon
                          sx={{ color: isDarkMode ? '#FFFFFF' : '#333333' }}
                        />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleOpenEditModal(row.id)}>
                        <EditIcon
                          sx={{ color: isDarkMode ? '#FFFFFF' : '#333333' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className={classes.footer}>
        <Pagination
          total={totalPromotionCodesPages}
          perPage={size}
          currentPage={promotionCodesPage}
          onPageChange={(page) => setPromotionCodesPage(page)}
        />
        <div className={classes.footerActions}>
          <p className={classes.action} onClick={() => openModal()}>
            <Icon
              color=""
              fill="#B7B7B7"
              size={18}
              path="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 11H7V13H11V17H13V13H17V11H13V7H11V11Z"
            />
            Create a promotion code
          </p>
        </div>
      </div>
    </>
  );
};
export default Promotion;
