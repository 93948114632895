import { forwardRef } from 'react';

import { useTheme } from '../../../context/ThemeContext/ThemeContext';

import classes from './_textarea.module.scss';

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, disabled, ...props }, ref) => {
    const { isDarkMode } = useTheme();

    return (
      <textarea
        className={`${classes.input} ${className || ''} ${
          isDarkMode ? classes.dark : classes.light
        } `}
        disabled={disabled}
        ref={ref}
        {...props}
      />
    );
  },
);

TextArea.displayName = 'Input';

export default TextArea;
