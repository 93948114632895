import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useTheme } from '../../context/ThemeContext/ThemeContext';
import { useAuth } from '../../context/AuthContext/AuthContext';

import { RaffleDTO } from '../../services/raffle/interfaces';
import { fetchRaffle } from '../../services/raffle/raffle';
import { logout } from '../../services/auth/login';

import Loader from '../../components/Loader/Loader';

import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import PodiumFirstImage from '../../assets/images/raffle/podium-first.png';
import PodiumSecondImage from '../../assets/images/raffle/podium-second.png';
import PodiumThirdImage from '../../assets/images/raffle/podium-third.png';
import FirstPriceImage from '../../assets/images/raffle/raffle-first-price.png';
import SecondPriceImage from '../../assets/images/raffle/raffle-second-place.png';
import ThirdPriceImage from '../../assets/images/raffle/raffle-third-price.png';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxWidth: '90%',
  color: 'white',
  borderRadius: '8px',
  p: 4,
  overflow: 'scroll',
  maxHeight: '80vh',
};

const Raffle = () => {
  const { tokens, isTokensLoading } = useAuth();
  const { isDarkMode } = useTheme();
  const { t } = useTranslation('raffle');

  const [raffle, setRaffle] = useState<RaffleDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [openAboutModal, setOpenAboutModal] = React.useState(false);
  const [openTicketsModal, setOpenTicketsModal] = React.useState(false);

  const sectionRef = useRef<HTMLDivElement | null>(null);

  const scrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const getRaffle = async () => {
      if (tokens?.accessToken && !isTokensLoading)
        try {
          const raffleData = await fetchRaffle(tokens.accessToken);
          setRaffle(raffleData);
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      else if (!tokens) {
        logout();
      }
    };

    getRaffle();
  }, []);

  const handleOpenAboutModal = () => setOpenAboutModal(true);
  const handleCloseAboutModal = () => setOpenAboutModal(false);

  const handleOpenTicketsModal = () => setOpenTicketsModal(true);
  const handleCloseTicketsModal = () => setOpenTicketsModal(false);

  const translations = {
    days: t('days'),
    hours: t('hours'),
    minutes: t('minutes'),
    seconds: t('seconds'),
  };

  const calculateTimeLeft = () => {
    if (raffle) {
      const difference = +new Date(raffle.endDate) - +new Date();
      let timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }

      return timeLeft;
    }
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [raffle]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Modal
        open={openAboutModal}
        onClose={handleCloseAboutModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          bgcolor={isDarkMode ? '#262729' : '#FFFFFF'}
          color={isDarkMode ? '#FFFFFF' : '#333333'}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={'#F64644'}
            fontWeight={'bold'}
          >
            <Stack alignItems={'center'} gap={1} direction={'row'}>
              <InfoIcon />
              {t('infoModalTitle')}
            </Stack>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, mb: 5 }}
            color={isDarkMode ? '#FFFFFF' : '#333333'}
          >
            {t('infoModalText1')}
            <br /> <br />
            {t('infoModalRewards')}
            <List sx={{ marginLeft: '-15px' }}>
              {[
                t('reward10K'),
                t('reward25K'),
                t('reward50K'),
                t('reward100K'),
                t('reward200K'),
              ].map((text, index) => (
                <ListItem
                  key={index}
                  sx={{ paddingTop: '0px', paddingBottom: '0px' }}
                >
                  <ListItemIcon sx={{ minWidth: '20px' }}>
                    <FiberManualRecordIcon
                      sx={{
                        fontSize: '8px',
                        color: isDarkMode ? '#FFFFFF' : '#333333',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
            {t('infoModalText2')}
          </Typography>

          <Button variant="contained" href="/buy-challenge">
            {t('buyChallengeButton')}
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openTicketsModal}
        onClose={handleCloseTicketsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} bgcolor={isDarkMode ? '#262729' : '#FFFFFF'}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mb={2}
            color={'#F64644'}
            fontWeight={'bold'}
          >
            {t('ticketsModalTitle')}
          </Typography>
          {raffle ? (
            raffle.ticketEarnedNumbers.length > 0 ? (
              <Stack direction="row" gap={2} flexWrap="wrap">
                {raffle.ticketEarnedNumbers.map((ticketNumber, index) => (
                  <Box
                    key={index}
                    component="div"
                    id="modal-modal-description"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: 2,
                      color: 'black',
                      border: '2px solid #F64644',
                      backgroundColor: 'rgba(246, 70, 68, 0.2)',
                      borderRadius: '6px',
                      width: '100px',
                      height: '50px',
                    }}
                  >
                    <Typography variant="h6" component="p">
                      {ticketNumber}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            ) : (
              <>
                <Typography color={isDarkMode ? '#FFFFFF' : '#333333'}>
                  {t('ticketsModalText1')} <br /> <br />{' '}
                  {t('ticketsModalText2')}
                  <List sx={{ marginLeft: '-15px' }}>
                    {[
                      t('reward10K'),
                      t('reward25K'),
                      t('reward50K'),
                      t('reward100K'),
                      t('reward200K'),
                    ].map((text, index) => (
                      <ListItem
                        key={index}
                        sx={{ paddingTop: '0px', paddingBottom: '0px' }}
                      >
                        <ListItemIcon sx={{ minWidth: '20px' }}>
                          <FiberManualRecordIcon
                            sx={{
                              fontSize: '8px',
                              color: isDarkMode ? '#FFFFFF' : '#333333',
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                      </ListItem>
                    ))}
                  </List>
                </Typography>

                <Button variant="contained" href="/buy-challenge">
                  {t('buyChallengeButton')}
                </Button>
              </>
            )
          ) : null}
        </Box>
      </Modal>

      <Card
        elevation={0}
        sx={{
          backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
          padding: {
            xs: '70px 15px',
            sm: '70px 40px',
          },
          position: 'relative',
          overflow: 'hidden',
          minHeight: {
            xs: '700px',
            lg: '400px',
          },
        }}
      >
        <Stack
          position={'absolute'}
          zIndex={0}
          height={1200}
          width={1200}
          top={'-50%'}
          left={'30%'}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 800 450"
            opacity="0.57"
          >
            <defs>
              <filter
                id="bbblurry-filter"
                x="-100%"
                y="-100%"
                width="400%"
                height="400%"
                filterUnits="objectBoundingBox"
                primitiveUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feGaussianBlur
                  stdDeviation="89"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  in="SourceGraphic"
                  edgeMode="none"
                  result="blur"
                ></feGaussianBlur>
              </filter>
            </defs>
            <g filter="url(#bbblurry-filter)">
              <ellipse
                rx="173.5"
                ry="82"
                cx="414.74824927069926"
                cy="214.19493079185483"
                fill="#a26a33ff"
              ></ellipse>
              <ellipse
                rx="173.5"
                ry="82"
                cx="308.5635393316095"
                cy="280.3502452590249"
                fill="#6a0b4fff"
              ></ellipse>
            </g>
          </svg>
        </Stack>

        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack position={'relative'} zIndex={10}>
            <Typography
              variant="h4"
              component="h1"
              fontWeight={'bold'}
              gutterBottom
              color={isDarkMode ? '#FFFFFF' : '#333333'}
            >
              {t('raffle')}
            </Typography>
            <Chip
              label={t('onGoing')}
              sx={{
                backgroundColor: '#1FCC92',
                fontWeight: '600',
                width: 'fit-content',
              }}
            ></Chip>
            <Typography
              sx={{ marginTop: '25px', marginBottom: '50px' }}
              variant="body1"
              component="p"
              color={isDarkMode ? '#FFFFFF' : '#333333'}
            >
              {t('text1')} <br />
              {t('text2')}{' '}
              <Typography variant="body1" component="span" color={'#F64644'}>
                {t('text3')}
              </Typography>
              !
            </Typography>
            <Stack
              sx={{
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}
              direction="row"
              gap={2}
            >
              <Button
                startIcon={<InfoIcon />}
                variant="contained"
                onClick={() => handleOpenAboutModal()}
              >
                {t('informationsButton')}
              </Button>
              <Button
                startIcon={<EmojiEventsIcon />}
                variant="outlined"
                onClick={scrollToSection}
              >
                {t('pricesButton')}
              </Button>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            position="absolute"
            bottom={'0'}
            right={'50px'}
            sx={{
              left: {
                xs: '50%',
                lg: 'auto',
              },
              transform: {
                xs: 'translateX(-50%)',
                lg: 'none',
              },
            }}
          >
            <Stack alignItems="center" gap={1}>
              <Avatar sx={{ width: 56, height: 56, bgcolor: '#666666' }} />
              <Typography
                variant="h6"
                sx={{ mb: '-15px' }}
                color={isDarkMode ? '#FFFFFF' : '#333333'}
              >
                {raffle?.secondPlace ? raffle.secondPlace : '???'}
              </Typography>
              <Box
                component="img"
                src={PodiumSecondImage}
                sx={{
                  maxWidth: {
                    xs: '150px',
                    lg: '100%',
                  },
                  maxHeight: {
                    xs: '150px',
                    lg: '100%',
                  },
                  height: 'auto',
                  width: 'auto',
                  objectFit: 'contain',
                  transform: 'translateY(22px)',
                }}
              />
            </Stack>
            <Stack alignItems="center" gap={1}>
              <Avatar sx={{ width: 64, height: 64, bgcolor: '#666666' }} />
              <Typography
                variant="h6"
                sx={{ mb: '5px' }}
                color={isDarkMode ? '#FFFFFF' : '#333333'}
              >
                {raffle?.firstPlace ? raffle.firstPlace : '???'}
              </Typography>
              <Box
                component="img"
                src={PodiumFirstImage}
                sx={{
                  maxWidth: {
                    xs: '150px',
                    lg: '100%',
                  },
                  maxHeight: {
                    xs: '150px',
                    lg: '100%',
                  },
                  height: 'auto',
                  width: 'auto',
                  objectFit: 'contain',
                }}
              />
            </Stack>
            <Stack alignItems="center" gap={1}>
              <Avatar sx={{ width: 42, height: 42, bgcolor: '#666666' }} />
              <Typography
                variant="h6"
                sx={{ mb: '-25px' }}
                color={isDarkMode ? '#FFFFFF' : '#333333'}
              >
                {raffle?.thirdPlace ? raffle.thirdPlace : '???'}
              </Typography>
              <Box
                component="img"
                src={PodiumThirdImage}
                sx={{
                  maxWidth: {
                    xs: '150px',
                    lg: '100%',
                  },
                  maxHeight: {
                    xs: '150px',
                    lg: '100%',
                  },
                  height: 'auto',
                  width: 'auto',
                  objectFit: 'contain',
                  transform: 'translateY(34px)',
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Card>

      <Paper
        elevation={0}
        sx={{
          margin: '50px 0px 20px 0px',
          padding: '1px',
          background:
            'linear-gradient(60deg, rgba(231,61,67,1) 0%, rgba(126,6,10,1) 100%)',
        }}
      >
        <Card
          elevation={0}
          sx={{
            backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
            padding: '25px 0px',
          }}
        >
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Typography
              variant="h5"
              component="h2"
              mb={5}
              color={isDarkMode ? '#FFFFFF' : '#333333'}
            >
              {t('timeRemaining')}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                color: isDarkMode ? '#FFFFFF' : '#333333',
                maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                '@media (max-width: 600px)': {
                  justifyContent: 'center',
                },
                '@media (min-width: 600px)': {
                  justifyContent: 'space-around',
                },
              }}
            >
              {Object.entries(timeLeft).map(([unit, value]) => (
                <Grid
                  item
                  xs={6}
                  sm={2}
                  textAlign={'center'}
                  key={unit}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h4"
                    component="h3"
                    color={isDarkMode ? '#FFFFFF' : '#333333'}
                  >
                    {value}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h4"
                    color={isDarkMode ? '#FFFFFF' : '#333333'}
                  >
                    {translations[unit as keyof typeof translations]}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Card>
      </Paper>

      <Stack
        gap={'20px'}
        direction={'row'}
        sx={{
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
        }}
      >
        <Card
          elevation={0}
          sx={{
            padding: '20px',
            width: '100%',
            background:
              'linear-gradient(60deg, rgba(231,61,67,1) 0%, rgba(126,6,10,1) 100%)',
          }}
        >
          <Stack
            color={'white'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography variant="h4" component="h2">
              {raffle?.ticketEarned}
            </Typography>
            <Typography variant="h6" component="h3" gutterBottom>
              {t('ticketEarned')}
            </Typography>
            <Button
              sx={{
                color: 'white',
                borderColor: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  borderColor: 'white',
                },
              }}
              variant="outlined"
              startIcon={<InfoIcon />}
              onClick={() => handleOpenTicketsModal()}
            >
              {t('myTicketsButton')}
            </Button>
          </Stack>
        </Card>

        <Card
          elevation={0}
          sx={{
            padding: '20px',
            width: '100%',
            backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
          }}
        >
          <Stack
            color={'white'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <Typography
              variant="h4"
              component="h2"
              color={isDarkMode ? '#FFFFFF' : '#333333'}
            >
              {raffle?.ticketDistributed}
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              gutterBottom
              color={isDarkMode ? '#FFFFFF' : '#333333'}
            >
              {t('ticketsDistributed')}
            </Typography>
          </Stack>
        </Card>
      </Stack>

      <Card
        ref={sectionRef}
        elevation={0}
        sx={{
          marginTop: '50px',
          marginBottom: '20px',
          background:
            'linear-gradient(90deg, rgba(22,22,32,1) 0%, rgba(39,39,55,1) 100%)',
        }}
      >
        <Stack textAlign={'center'} color={'white'} pt={5}>
          <Typography variant="h4" component="h2" fontWeight={'bold'}>
            {t('firstPriceTitle')}
          </Typography>
          <Typography variant="h5" component="h3">
            {t('firstPriceSubtitle')}
          </Typography>
        </Stack>
        <Stack
          mt={15}
          justifyContent="center"
          alignItems="center"
          width={'100%'}
        >
          <Box
            component="img"
            src={FirstPriceImage}
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
              height: '80%',
              width: 'auto',
              objectFit: 'contain',
            }}
          />
        </Stack>
      </Card>

      <Stack
        direction="row"
        gap={'20px'}
        sx={{
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
        }}
      >
        <Paper
          sx={{
            width: '100%',
            padding: '2px',
            background:
              'linear-gradient(90deg, rgba(66,66,99,1) 0%, rgba(124,124,175,1) 100%)',
          }}
        >
          <Card
            elevation={0}
            sx={{
              backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
              height: '100%',
              width: '100%',
            }}
          >
            <Stack textAlign={'center'} color={'white'} pt={5}>
              <Typography
                variant="h4"
                component="h2"
                fontWeight={'bold'}
                color={isDarkMode ? '#FFFFFF' : '#333333'}
              >
                {t('secondPriceTitle')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                color={isDarkMode ? '#FFFFFF' : '#333333'}
              >
                {t('secondPriceSubtitle')}
              </Typography>
            </Stack>
            <Stack
              mt={2}
              justifyContent="center"
              alignItems="center"
              width={'100%'}
            >
              <Box
                component="img"
                src={SecondPriceImage}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  height: '300px',
                  width: 'auto',
                  objectFit: 'contain',
                }}
              />
            </Stack>
          </Card>
        </Paper>

        <Card
          elevation={0}
          sx={{
            width: {
              xs: '100%',
              lg: '60%',
            },
            backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
          }}
        >
          <Stack textAlign={'center'} color={'white'} pt={5}>
            <Typography
              variant="h4"
              component="h2"
              fontWeight={'bold'}
              color={isDarkMode ? '#FFFFFF' : '#333333'}
            >
              {t('thirdPriceTitle')}
            </Typography>
            <Typography
              variant="h5"
              component="h3"
              color={isDarkMode ? '#FFFFFF' : '#333333'}
            >
              {t('thirdPriceSubtitle')}
            </Typography>
          </Stack>
          <Stack
            mt={2}
            justifyContent="center"
            alignItems="center"
            width={'100%'}
          >
            <Box
              component="img"
              src={ThirdPriceImage}
              sx={{
                maxWidth: '100%',
                maxHeight: '100%',
                height: '300px',
                width: 'auto',
                objectFit: 'contain',
              }}
            />
          </Stack>
        </Card>
      </Stack>
    </>
  );
};

export default Raffle;
