import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../context/ThemeContext/ThemeContext';

import Icon from '../../../components/userInterface/Icon/Icon';

import classes from './_accountCard.module.scss';
import Switch from '@mui/material/Switch';
import { FormControlLabel, Stack, Typography } from '@mui/material';

interface AccountCardProps {
  accountNumber: string;
  balance: string;
  result: string;
  phase?: string;
  profit?: string;
  upgradeButton?: React.ReactNode;
  payoutButton?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const AccountCard: React.FC<AccountCardProps> = ({
  accountNumber,
  balance,
  result,
  phase,
  profit,
  upgradeButton,
  payoutButton,
  className,
  children,
  onClick,
}) => {
  const { isDarkMode } = useTheme();
  const { t } = useTranslation(['components', 'commons']);

  const [isShowMore, setIsShowMore] = useState(false);
  const [checked, setChecked] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleShowMore = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <div
      className={`
      ${classes.card} ${className || ''} 
      ${isShowMore ? classes.active : ''} 
      ${isDarkMode ? classes.dark : classes.light}`}
    >
      <div className={classes.header}>
        <div className={classes.top}>
          <h2>
            {t('accountCard.account')}: {checked ? accountNumber : '*******'}
          </h2>
          <div className={classes.buttons}>
            <div className={classes.upgrade}>
              {checked ? upgradeButton : null}
            </div>
            <div className={classes.payout}>
              {checked ? payoutButton : null}
            </div>
          </div>
        </div>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              name="visible"
            />
          }
          label={t('accountCard.visible')}
        />
      </div>
      <div className={classes.main}>
        <p>
          <span className={classes.grayText}>{t('accountCard.balance')}:</span>{' '}
          {checked ? `$${balance}` : '*****'}
        </p>
        <p>
          {t('accountCard.result')}:{' '}
          <span className={classes.yellowText}>
            {' '}
            {checked ? result : '*****'}{' '}
          </span>
        </p>
        <p>
          {phase ? t('accountCard.phase') : t('accountCard.profit')}{' '}
          <span className={classes.yellowText}>
            {' '}
            {checked ? `${phase ? phase : profit}` : '*****'}{' '}
          </span>
        </p>
      </div>

      {checked ? (
        <div className={classes.footer}>
          <p onClick={onClick}>
            <Icon
              color="black"
              size={21}
              path="M16 2L21 7V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918C3 2.44405 3.44495 2 3.9934 2H16ZM12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5ZM7.52746 17H16.4725C16.2238 14.75 14.3163 13 12 13C9.68372 13 7.77619 14.75 7.52746 17Z"
            />
            {t('accountCard.identifiants')}
          </p>
          <div
            onClick={() => handleShowMore()}
            className={classes.showMoreButton}
          >
            <Icon
              color="black"
              size={21}
              path={
                isShowMore
                  ? 'M11.9997 10.8284L7.04996 15.7782L5.63574 14.364L11.9997 8L18.3637 14.364L16.9495 15.7782L11.9997 10.8284Z'
                  : 'M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z'
              }
            />
            {isShowMore ? t('accountCard.showLess') : t('accountCard.showMore')}
          </div>
        </div>
      ) : null}
      {checked ? (
        <div className={classes.moreContent}>
          <hr />
          {children}
        </div>
      ) : null}
    </div>
  );
};
export default AccountCard;
