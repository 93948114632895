import { useTheme } from '../../context/ThemeContext/ThemeContext';
import classes from './_loadercircle.module.scss';

interface LoaderProps {
  global?: boolean;
  className?: string; // Ajout de la propriété className
}

const LoaderCircle: React.FC<LoaderProps> = ({ global, className }) => {
    const { isDarkMode } = useTheme();
  
    return (
      <div
        className={`${classes.container} ${
          isDarkMode ? classes.dark : classes.light
        } ${global ? classes.global : ''} ${className}`}
      >
        <span className={classes.loader}></span>
      </div>
    );
  };
  
export default LoaderCircle;
