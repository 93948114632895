import { useTheme } from '../../../context/ThemeContext/ThemeContext';
import { useAuth } from '../../../context/AuthContext/AuthContext';
import { useAccessControl } from '../../../context/AccessControlContext/AccessControlContext';

import Sidebar from '../Sidebar/Sidebar';
import Error from '../Error/Error';

import classes from './_layout.module.scss';
import { useNavigate } from 'react-router-dom';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const { accessGranted } = useAccessControl();

  const navigate = useNavigate();

  if (!accessGranted) {
    return (
      <Error
        documentTitle="Access Denied"
        title={'Access Denied'}
        subtitle={
          "If you believe you're seeing this message in error, please contact our support team at support@raisemyfunds.co. We're sorry, but access to our site is not available in your region at this time due to regulatory reasons."
        }
        button={'Return to homepage'}
        onClick={() => navigate('/')}
      />
    );
  }

  return (
    <div
      className={`${classes.container}  ${
        isDarkMode ? classes.dark : classes.light
      }`}
    >
      <nav className={classes.nav}>{user ? <Sidebar /> : null}</nav>
      <main className={classes.main}>{children}</main>
    </div>
  );
};
export default Layout;
