import React, { useEffect, useMemo, useRef, useState } from 'react';

import 'hammerjs';

import {
  Chart as ChartJS,
  CategoryScale,
  ChartOptions,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';

import { IconButton, Stack } from '@mui/material';
import MuiTooltip from '@mui/material/Tooltip';
import { RemoveCircleOutline, ZoomIn, ZoomOut } from '@mui/icons-material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  annotationPlugin,
  Filler,
  zoomPlugin,
);

interface AnnotationProps {
  id: string;
  content: string;
  borderColor: string;
  yMin: number;
  yMax: number;
}

interface ZoomableChartProps {
  labels: any[];
  datasets: {
    label: string;
    data: number[];
    fill: string;
    borderColor: string;
    backgroundColor: string;
  }[];
  xAxisTitle: string;
  yAxisTitle: string;
  annotations?: AnnotationProps[];
}

const ZoomableChart: React.FC<ZoomableChartProps> = ({
  labels,
  datasets,
  xAxisTitle,
  yAxisTitle,
  annotations = [],
}) => {
  const chartRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const [isResponsiveMode, setIsResponsiveMode] = useState(
    window.innerWidth < 1024,
  );

  const handleZoomIn = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.zoom(1.1);
    }
  };

  const handleZoomOut = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.zoom(0.9);
    }
  };

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  useEffect(() => {
    if (isResponsiveMode) {
      chartRef.current.zoom(1.8);
    }
    const handleResize = () => {
      setIsResponsiveMode(window.innerWidth < 1024);
      if (chartRef.current && chartRef.current.chartInstance) {
        chartRef.current.chartInstance.update('none');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const options = useMemo(
    (): ChartOptions<'line'> => ({
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          type: 'category',
          ticks: {
            autoSkip: true,
          },
          title: {
            display: !isResponsiveMode,
            text: xAxisTitle,
            color: '#cccccc',
            font: {
              size: 24,
              weight: 'bold',
            },
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: !isResponsiveMode,
            text: yAxisTitle,
            color: '#cccccc',
            font: {
              size: 24,
              weight: 'bold',
            },
          },
        },
      },
      plugins: {
        annotation: {
          annotations: annotations.reduce(
            (acc, annotation) => {
              acc[annotation.id] = {
                type: 'line',
                yMin: annotation.yMin,
                yMax: annotation.yMax,
                borderColor: annotation.borderColor,
                borderWidth: 2,
                label: {
                  content: annotation.content,
                  backgroundColor: annotation.borderColor,
                  display: true,
                  position: 'start',
                },
              };
              return acc;
            },
            {} as Record<string, any>,
          ),
        },
        legend: {
          display: datasets.length > 1,
        },
        tooltip: {
          enabled: true,
          mode: 'index',
          intersect: false,
          animation: false,
        },
        zoom: {
          limits: {
            x: { min: 'original', max: 'original' },
          },
          pan: {
            enabled: true,
            mode: 'x',
            threshold: 10,
          },
          zoom: {
            wheel: {
              enabled: true,
              speed: 0.1,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
      animation: {
        duration: 0,
      },
    }),
    [xAxisTitle, yAxisTitle, datasets.length, isResponsiveMode],
  );

  const data = {
    labels,
    datasets,
  };

  return (
    <Stack spacing={1}>
      <Stack ref={containerRef} direction="row" alignItems="center">
        <MuiTooltip title="Zoom In" placement="top">
          <IconButton
            onClick={handleZoomIn}
            aria-label="zoom in"
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            <ZoomIn sx={{ fontSize: '30px', color: 'white' }} />
          </IconButton>
        </MuiTooltip>

        <MuiTooltip title="Zoom Out" placement="top">
          <IconButton
            onClick={handleZoomOut}
            aria-label="zoom out"
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            <ZoomOut sx={{ fontSize: '30px', color: 'white' }} />
          </IconButton>
        </MuiTooltip>

        <MuiTooltip title="Reset Zoom" placement="top">
          <IconButton
            onClick={handleResetZoom}
            aria-label="reset zoom"
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            <RemoveCircleOutline sx={{ fontSize: '25px', color: 'white' }} />
          </IconButton>
        </MuiTooltip>
      </Stack>
      <Line ref={chartRef} data={data} options={options} />
    </Stack>
  );
};

export default ZoomableChart;

// import React, { useRef, useMemo } from 'react';
// import 'hammerjs';

// import { Line } from 'react-chartjs-2';
// import { Chart } from 'chart.js'; // Importez Chart pour le typage
// import './chartConfig'; // Assurez-vous que c'est le bon chemin
// import { useResponsiveMode } from './utils/useResponsiveMode';
// import { getChartOptions, getChartData } from './chartConfig';
// import { IconButton, Stack } from '@mui/material';
// import MuiTooltip from '@mui/material/Tooltip';
// import { RemoveCircleOutline, ZoomIn, ZoomOut } from '@mui/icons-material';
// import { ZoomableChartProps } from './interfaces';

// const ZoomableChart: React.FC<ZoomableChartProps> = ({
//   labels,
//   datasets,
//   xAxisTitle,
//   yAxisTitle,
//   annotations = [],
// }) => {
//   // Utilisez le type Chart pour votre référence
//   const chartRef = useRef<Chart<'line', number[], string> | null>(null);
//   const isResponsiveMode = useResponsiveMode();

//   const options = useMemo(
//     () =>
//       getChartOptions(
//         xAxisTitle,
//         yAxisTitle,
//         datasets.length,
//         isResponsiveMode,
//         annotations,
//       ),
//     [xAxisTitle, yAxisTitle, datasets.length, isResponsiveMode, annotations],
//   );

//   const data = getChartData(labels, datasets);

//   const handleZoomIn = () => chartRef.current?.zoom(1.1);
//   const handleZoomOut = () => chartRef.current?.zoom(0.9);
//   const handleResetZoom = () => chartRef.current?.resetZoom();

//   return (
//     <Stack spacing={1}>
//       <Stack direction="row" alignItems="center">
//         <ZoomControl title="Zoom In" onClick={handleZoomIn} icon={<ZoomIn />} />
//         <ZoomControl
//           title="Zoom Out"
//           onClick={handleZoomOut}
//           icon={<ZoomOut />}
//         />
//         <ZoomControl
//           title="Reset Zoom"
//           onClick={handleResetZoom}
//           icon={<RemoveCircleOutline />}
//         />
//       </Stack>
//       <Line ref={chartRef} data={data} options={options} />
//     </Stack>
//   );
// };

// interface ZoomControlProps {
//   title: string;
//   onClick: () => void;
//   icon: React.ReactElement;
// }

// const ZoomControl: React.FC<ZoomControlProps> = ({ title, onClick, icon }) => (
//   <MuiTooltip title={title} placement="top">
//     <IconButton
//       onClick={onClick}
//       sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' } }}
//     >
//       {icon}
//     </IconButton>
//   </MuiTooltip>
// );

// export default ZoomableChart;
