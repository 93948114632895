import { Route, Routes, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import Error from './components/layouts/Error/Error';

import ClientArea from './pages/ClientArea/ClientArea';
import TraderArea from './pages/TraderArea/TraderArea';
import PartnerArea from './pages/PartnerArea/PartnerArea';
import Download from './pages/Download/Download';
import Profile from './pages/Profile/Profile';
import Login from './pages/Login/Login';
import PaymentStatusPage from './pages/BuyChallenge/PaymentStatusPage';
import BuyChallenge from './pages/BuyChallenge/BuyChallenge';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';

import Users from './pages/Admin/Users/Users';
import CashManagement from './pages/Admin/CashManagement/CashManagement';
import Reports from './pages/Admin/Reports/Reports';
import Promotion from './pages/Admin/Promotion/Promotion';
import Kyc from './pages/Admin/Kyc/Kyc';
import Challenges from './pages/Admin/Challenges/Challenges';
import Dashboard from './pages/Admin/Dashboard/Dashboard';
import Funded from './pages/Admin/Funded/Funded';
import Calendar from './pages/Calendar/Calendar';
import Discord from './pages/Discord/Discord';
import Certificate from './pages/Certificate/Certificate';
import Raffle from './pages/Raffle/Raffle';

export default function Router() {
  const navigate = useNavigate();
  const { t } = useTranslation(['unauthorized', 'comingSoon', 'notFound']);

  return (
    <Routes>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />

      <Route path="/login" element={<Login />} />
      <Route path="/buy-challenge" element={<BuyChallenge />} />
      <Route
        path="/buy-challenge/payment-status/:paymentId"
        element={<PaymentStatusPage />}
      />
      <Route path="/" element={<PrivateRoute />}>
        <Route index element={<ClientArea />} />
        <Route path="/trader-area" element={<TraderArea />} />
        <Route path="/partner-area" element={<PartnerArea />} />
        <Route path="/download" element={<Download />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/economic-calendar" element={<Calendar />} />
        <Route path="/certificate" element={<Certificate />} />
        <Route path="/raffle" element={<Raffle />} />
        <Route
          path="/calculator"
          element={
            <Error
              documentTitle="Coming Soon"
              title={t('title', { ns: 'comingSoon' })}
              subtitle={t('text', { ns: 'comingSoon' })}
              button={t('button', { ns: 'comingSoon' })}
              onClick={() => navigate('/')}
            />
          }
        />
        <Route path="/discord" element={<Discord />} />
        <Route path="admin" element={<PrivateRoute roles={['ROLE_ADMIN']} />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/cash-management" element={<CashManagement />} />
          <Route path="/admin/reports" element={<Reports />} />
          <Route path="/admin/promotion" element={<Promotion />} />
          <Route path="/admin/kyc" element={<Kyc />} />
          <Route path="/admin/challenges" element={<Challenges />} />
          <Route path="/admin/funded" element={<Funded />} />
        </Route>
      </Route>
      <Route
        path="/unauthorized"
        element={
          <Error
            documentTitle="Unauthorized"
            title={t('title')}
            subtitle={t('text')}
            button={t('button')}
            onClick={() => navigate('/')}
          />
        }
      />
      <Route
        path="*"
        element={
          <Error
            documentTitle="404 - Not Found"
            title={t('title', { ns: 'notFound' })}
            subtitle={t('text', { ns: 'notFound' })}
            button={t('button', { ns: 'notFound' })}
            onClick={() => navigate('/')}
          />
        }
      />
    </Routes>
  );
}
