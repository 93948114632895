import axios from 'axios';
import { logout } from '../services/auth/login';

const axiosInstance = axios.create({
  baseURL: 'https://live.raisemyfunds.co/api/',
});

const refreshTokenInstance = axios.create({
  baseURL: 'https://live.raisemyfunds.co/api/',
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem('accessToken') ||
      sessionStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken =
          localStorage.getItem('refreshToken') ||
          sessionStorage.getItem('refreshToken');

        // IIFE pour exécuter immédiatement la fonction asynchrone
        await (async () => {
          try {
            const response = await refreshTokenInstance.post(
              'users/token/refresh-token',
              {
                refreshToken: refreshToken,
              },
            );

            if (response.status === 200) {
              localStorage.setItem('accessToken', response.data.accessToken);
              localStorage.setItem('refreshToken', response.data.refreshToken);
              axios.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;

              return axiosInstance(originalRequest);
            }
          } catch (refreshTokenError) {
            console.error(
              "Erreur lors de la récupération de l'adresse IP: ",
              refreshTokenError,
            );
            logout();
          }
        })();
      } catch (refreshTokenError) {
        logout();
        return Promise.reject(refreshTokenError);
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
