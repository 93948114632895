import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../context/AuthContext/AuthContext';

import useDocumentTitle from '../../../hooks/useDocumentTitle';

import { logout } from '../../../services/auth/login';
import { fetchDashboardData } from '../../../services/admin/dashboard/dashboard';
import {
  CashEntries,
  ChallengesFailed,
  ChallengesPassed,
  ResponseDashboard,
  Sales,
} from '../../../services/admin/dashboard/interface';

import Card from '../../../components/Cards/Card/Card';
import IconCard from '../../../components/Cards/IconCard/IconCard';
import ProfitChart from '../../../components/ProfitChart/ProfitChart';
import Tab from '../../../components/Tab/Tab';
import Loader from '../../../components/Loader/Loader';

import classes from './_dashboard.module.scss';
import ZoomableChart from '../../../components/ZoomableChart/ZoomableChart';

const Dashboard = () => {
  const { tokens, isTokensLoading } = useAuth();
  useDocumentTitle('Admin Dashboard');

  const tabNames = [
    'Challenge Failed',
    'Cash Entry',
    'Sales',
    'Challenges Passed',
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ResponseDashboard>();
  const [challengeFailed, setChallengesFailed] = useState<ChallengesFailed[]>(
    [],
  );
  const [challengePassed, setChallengesPassed] = useState<ChallengesPassed[]>(
    [],
  );
  const [cashEntry, setCashEntries] = useState<CashEntries[]>([]);
  const [sale, setSales] = useState<Sales[]>([]);

  const [counter, setCounter] = useState(0); // Cet état représente le chiffre à animer

  // Supposons que `newNumber` est la nouvelle valeur que vous souhaitez afficher
  const animateCounter = (newNumber: number) => {
    const currentNumber = counter;
    const difference = newNumber - currentNumber;
    const stepTime = Math.abs(Math.floor(500 / difference)); // Durée de chaque étape de l'animation

    let steps = 0;
    const timer = setInterval(() => {
      steps++;
      setCounter(currentNumber + (difference / Math.abs(difference)) * steps);
      if (steps >= Math.abs(difference)) {
        clearInterval(timer);
      }
    }, stepTime);
  };

  useEffect(() => {
    if (data?.totalSales !== undefined) {
      animateCounter(Number(data.totalSales));
    }
  }, [data?.totalSales]);

  useEffect(() => {
    // Cette fonction est déclarée pour pouvoir être appelée répétitivement
    const fetchData = async () => {
      if (tokens && tokens.accessToken) {
        setIsLoading(true);
        try {
          const response = await fetchDashboardData(tokens.accessToken);
          setData(response);
          setChallengesFailed(response.challengesFailed);
          setChallengesPassed(response.challengesPassed);
          setCashEntries(response.cashEntries);
          setSales(response.sales);
          animateCounter(Number(data?.totalSales));
        } catch (error) {
          throw error;
        } finally {
          setIsLoading(false);
        }
      } else if (!tokens) {
        logout();
      }
    };

    if (!isTokensLoading) {
      fetchData();

      const intervalId = setInterval(fetchData, 10000);

      return () => clearInterval(intervalId); // Nettoyage de l'effet
    }
  }, [tokens, isTokensLoading]); // Dépendances de l'effet

  // if (isLoading) {
  //   return <Loader />;
  // }
  const chartLabelsCashEntry = cashEntry.map((item) => item.date);
  const chartCashEntry = cashEntry.map((item) => item.amount);

  const chartLabelsChallengesFailed = challengeFailed.map((item) => item.date);
  const chartChallengesFailed = challengeFailed.map((item) => item.count);

  const chartLabelsSales = sale.map((item) => item.date);
  const chartSales = sale.map((item) => item.count);

  const chartLabelsChallengesPassed = challengePassed.map((item) => item.date);
  const chartChallengesPassed = challengePassed.map((item) => item.count);

  const chartCashEntryDatasets = [
    {
      label: 'Cash Entry',
      data: chartCashEntry,
      fill: 'start',
      backgroundColor: 'rgba(231, 71, 80, 0.1)',
      borderColor: 'rgb(231, 71, 80)',
    },
  ];

  const chartChallengesFailedDatasets = [
    {
      label: 'Challenges Failed',
      data: chartChallengesFailed,
      fill: 'start',
      backgroundColor: 'rgba(231, 71, 80, 0.1)',
      borderColor: 'rgb(231, 71, 80)',
    },
  ];

  const chartSalesDatasets = [
    {
      label: 'Sales',
      data: chartSales,
      fill: 'start',
      backgroundColor: 'rgba(231, 71, 80, 0.1)',
      borderColor: 'rgb(231, 71, 80)',
    },
  ];

  const chartChallengesPassedDatasets = [
    {
      label: 'Challenges Passed',
      data: chartChallengesPassed,
      fill: 'start',
      backgroundColor: 'rgba(231, 71, 80, 0.1)',
      borderColor: 'rgb(231, 71, 80)',
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <div className={classes.cardsContainer}>
          <div className={classes.cards}>
            <IconCard
              title="Total users"
              amount={`${data?.totalUsers.toLocaleString('fr-FR')}` || '0'}
              icon=""
            />
            <IconCard
              title="Active clients"
              amount={`${data?.activeClients.toLocaleString('fr-FR')}` || '0'}
              icon=""
            />
          </div>
          <div className={classes.cards}>
            <IconCard
              title="Funded clients"
              amount={`${data?.fundedClients.toLocaleString('fr-FR')}` || '0'}
              icon=""
            />
            <IconCard
              title="Challenges failed"
              amount={`${data?.challengeFailed.toLocaleString('fr-FR')}` || '0'}
              icon=""
            />
          </div>
        </div>
        <Card className={classes.pendingsCard}>
          <div className={classes.pendingsContainer}>
            <div className={classes.section}>
              <h2>Payment error</h2>
              <p>{data?.pendingPaymentError.toLocaleString('fr-FR') || 0}</p>
              <Link to="/admin/cash-management">SHOW ALL</Link>
            </div>

            <div className={classes.section}>
              <h2>Withdraw request</h2>
              <p>{data?.pendingWithdrawRequest.toLocaleString('fr-FR') || 0}</p>
              <Link to="/admin/cash-management">SHOW ALL</Link>
            </div>
          </div>

          <div className={classes.pendingsContainer}>
            <div className={classes.section}>
              <h2>Affiliation payout request</h2>
              <p>
                {data?.pendingAffiliationPayoutRequest.toLocaleString(
                  'fr-FR',
                ) || 0}
              </p>
              <Link to="/admin/cash-management">SHOW ALL</Link>
            </div>

            <div className={classes.section}>
              <h2>KYC request</h2>
              <p>{data?.pendingKYCRequest.toLocaleString('fr-FR') || 0}</p>
              <Link to="/admin/kyc">SHOW ALL</Link>
            </div>
          </div>
        </Card>
      </div>
      <div className={classes.rightContainer}>
        <Card className={classes.pendingsCard}>
          <div className={classes.pendingsContainer}>
            <div className={classes.salesSection}>
              <h2>Today's Sale</h2>
              <p>{data?.todaySales.toLocaleString('fr-FR') || 0}€</p>
            </div>

            <div className={classes.salesSection}>
              <h2>Total sales</h2>
              <p>{data?.totalSales.toLocaleString('fr-FR') || 0}€</p>
            </div>
          </div>

          <div className={classes.pendingsContainer}>
            <div className={classes.salesSection}>
              <h2>Weekly sales</h2>
              <p>{data?.weeklySales.toLocaleString('fr-FR') || 0}€</p>
            </div>

            <div className={classes.salesSection}>
              <h2>Monthly sales</h2>
              <p>{data?.monthlySales.toLocaleString('fr-FR') || 0}€</p>
            </div>
          </div>
        </Card>

        <Card className={classes.tabContainer}>
          <Tab id="adminDashboardChart" tabs={tabNames}>
            <div className={classes.chartContainer}>
              <ZoomableChart
                labels={chartLabelsChallengesFailed}
                datasets={chartChallengesFailedDatasets}
                xAxisTitle="Dates"
                yAxisTitle="Challenges Failed"
              />
            </div>
            <div>
              <ZoomableChart
                labels={chartLabelsCashEntry}
                datasets={chartCashEntryDatasets}
                xAxisTitle="Dates"
                yAxisTitle="Cash Entry"
                annotations={[
                  {
                    id: 'cashEntry1',
                    content: `Max: ${Math.max(
                      ...cashEntry.map((item) => item.amount),
                    )}`,
                    borderColor: 'rgb(31,204,146)',
                    yMin: Math.max(...cashEntry.map((item) => item.amount)),
                    yMax: Math.max(...cashEntry.map((item) => item.amount)),
                  },
                ]}
              />
            </div>
            <div>
              <ZoomableChart
                labels={chartLabelsSales}
                datasets={chartSalesDatasets}
                xAxisTitle="Dates"
                yAxisTitle="Sales"
                annotations={[
                  {
                    id: 'sales1',
                    content: `Max: ${Math.max(
                      ...sale.map((item) => item.count),
                    )}`,
                    borderColor: 'rgb(31,204,146)',
                    yMin: Math.max(...sale.map((item) => item.count)),
                    yMax: Math.max(...sale.map((item) => item.count)),
                  },
                ]}
              />
            </div>
            <div>
              <ZoomableChart
                labels={chartLabelsChallengesPassed}
                datasets={chartChallengesPassedDatasets}
                xAxisTitle="Dates"
                yAxisTitle="Challenges Passed"
              />
            </div>
          </Tab>
        </Card>
      </div>
    </div>
  );
};
export default Dashboard;
