import { useTheme } from '../../../context/ThemeContext/ThemeContext';

import classes from './_card.module.scss';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card: React.FC<CardProps> = ({ children, className }) => {
  const { isDarkMode } = useTheme();

  return (
    <div
      className={`${classes.card} ${className || ''} ${
        isDarkMode ? classes.dark : classes.light
      }`}
    >
      {children}
    </div>
  );
};
export default Card;
