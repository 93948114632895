import axiosInstance from '../../utils/axios';
import { Profile, JWTPayload } from './interfaces';
import { toast } from 'react-toastify';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}

export const fetchProfile = async (
  accessToken: string,
  userUUID: string,
): Promise<Profile> => {
  try {
    const response = await axiosInstance.get<Profile>(
      `/discord/user/${userUUID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendDiscordTag = async (
  accessToken: string,
  userUUID: string,
  discordTag: string,
) => {
  try {
    const response = await axiosInstance.post(
      `/discord/user/${userUUID}/set-available-roles`,
      {
        discordTag: discordTag,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );

    if (response.status === 200) {
      toast.success('Liaison effectuée !', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error('Une erreur est survenue.', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};
