import axiosInstance from '../../../utils/axios';

import { ResponseDashboard } from './interface';

export const fetchDashboardData = async (
  accessToken: string,
): Promise<ResponseDashboard> => {
  try {
    const response = await axiosInstance.get<ResponseDashboard>(
      `/admin/dashboard`,
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur lors de la récupération des data du dashboard');
  }
};
