import { useTheme } from '../../../context/ThemeContext/ThemeContext';

import Icon from '../../../components/userInterface/Icon/Icon';

import classes from './_iconCard.module.scss';

interface IconCardProps {
  title: string;
  amount: string;
  icon: string;
  percent?: string;
  action?: JSX.Element;
  button?: React.ReactNode;
  className?: string;
}

const IconCard: React.FC<IconCardProps> = ({
  title,
  amount,
  icon,
  percent,
  action,
  button,
  className,
}) => {
  const { isDarkMode } = useTheme();

  return (
    <div
      className={`${classes.card} ${
        isDarkMode ? classes.dark : classes.light
      } ${className}`}
    >
      <div className={classes.content}>
        <h3>{title}</h3>
        <p> {amount} </p>
        {percent === 'NA' ? null : (
          <p
            className={`${classes.percent} ${
              percent?.startsWith('-') ? classes.red : classes.green
            }`}
          >
            {percent ? (
              <Icon
                color="custom"
                fill={percent.startsWith('-') ? '#e73d44' : '#19b580'}
                size={20}
                path={
                  percent.startsWith('-')
                    ? 'M9 13.589L17.6066 4.98242L19.0208 6.39664L10.4142 15.0032H18V17.0032H7V6.00324H9V13.589Z'
                    : 'M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z'
                }
              />
            ) : null}
            {percent}
          </p>
        )}
        <p className={classes.action}> {action} </p>
        {button}
      </div>
      <div className={classes.icon}>
        <Icon color="gray" size={70} path={icon} />
      </div>
    </div>
  );
};
export default IconCard;
