import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ar';

import { useTheme } from '../../context/ThemeContext/ThemeContext';
import { useAuth } from '../../context/AuthContext/AuthContext';

import { fetchEconomicCalendar } from '../../services/calendar/calendar';
import { CalendarResponse } from '../../services/calendar/interfaces';
import { logout } from '../../services/auth/login';

import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Rating,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import StarIcon from '@mui/icons-material/Star';
import NzdIcon from '../../assets/images/calendar/NZD.png';
import AudIcon from '../../assets/images/calendar/AUD.png';
import JpyIcon from '../../assets/images/calendar/JPY.png';
import CnyIcon from '../../assets/images/calendar/CNY.png';
import ChfIcon from '../../assets/images/calendar/CHF.svg';
import EurIcon from '../../assets/images/calendar/EUR.svg';
import GbpIcon from '../../assets/images/calendar/GBP.svg';
import CadIcon from '../../assets/images/calendar/CAD.png';
import UsdIcon from '../../assets/images/calendar/USD.svg';
import GasIcon from '../../assets/images/calendar/GAS.png';
import OilIcon from '../../assets/images/calendar/OIL.png';
import DefaultIcon from '../../assets/images/calendar/default.png';

import Countdown from './components/Countdown/Countdown';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const Calendar: React.FC = () => {
  const { isDarkMode } = useTheme();
  const { user, tokens, isTokensLoading } = useAuth();
  const { i18n } = useTranslation();
  const { t } = useTranslation(['calendar']);

  useDocumentTitle(`${t('TITLES.MAIN_TITLE')}`);

  const [data, setData] = useState<CalendarResponse[]>([]);
  const [timeZone, setTimeZone] = useState(moment.tz.guess());
  const [filters, setFilters] = useState({
    Low: true,
    Medium: true,
    High: true,
    Holiday: true,
  });
  const [selectedDates, setSelectedDates] = useState<string[]>(() => {
    const now = new Date();
    now.setHours(now.getHours() + 1);
    return [now.toISOString().split('T')[0]];
  });

  const timeZones = moment.tz.names();

  const handleChangeTimeZone = (event: SelectChangeEvent) => {
    setTimeZone(event.target.value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const getInstrumentIcon = (instrumentName: string) => {
    let imageUrl;

    switch (instrumentName) {
      case 'NZD':
        imageUrl = NzdIcon;
        break;
      case 'AUD':
        imageUrl = AudIcon;
        break;
      case 'JPY':
        imageUrl = JpyIcon;
        break;
      case 'CNY':
        imageUrl = CnyIcon;
        break;
      case 'CHF':
        imageUrl = ChfIcon;
        break;
      case 'EUR':
        imageUrl = EurIcon;
        break;
      case 'GBP':
        imageUrl = GbpIcon;
        break;
      case 'CAD':
        imageUrl = CadIcon;
        break;
      case 'USD':
        imageUrl = UsdIcon;
        break;
      case 'USGas':
        imageUrl = GasIcon;
        break;
      case 'oilWTI':
        imageUrl = OilIcon;
        break;
      default:
        imageUrl = DefaultIcon;
    }
    return <Avatar src={imageUrl} alt={instrumentName} />;
  };

  const loadData = useCallback(async () => {
    if (!isTokensLoading && tokens?.accessToken && user) {
      try {
        const data = await fetchEconomicCalendar(tokens.accessToken);
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (!tokens) {
      logout();
    }
  }, [tokens, isTokensLoading, user]);

  useEffect(() => {
    moment.locale(i18n.language);
    loadData();
  }, [loadData, timeZone, i18n.language]);

  function createData(
    title: string,
    instrument: string,
    date: string,
    impact: string,
    forecast: string,
    previous: string,
  ) {
    const dateObject = moment.tz(date, timeZone);

    const formattedDate = dateObject.format('ll');

    const formattedTime = dateObject.format('LT');

    return {
      title,
      instrument,
      date,
      impact,
      forecast,
      previous,
      formattedDate,
      formattedTime,
    };
  }

  const rows = data.map((item: any) =>
    createData(
      item.title,
      item.instrument,
      item.eventDateTime,
      item.impact,
      item.forecast,
      item.previous,
    ),
  );

  const filteredRows = rows.filter((row) => {
    const eventDate = moment.tz(row.date, timeZone);
    const isSelectedDate = selectedDates.some((selectedDate) => {
      const selectedMoment = moment.tz(selectedDate, timeZone);
      return eventDate.isSame(selectedMoment, 'day');
    });

    return isSelectedDate && filters[row.impact as keyof typeof filters];
  });

  const handleDayClick = (isoDate: string) => {
    setSelectedDates((prevDates) => {
      if (prevDates.includes(isoDate)) {
        return prevDates.filter((date) => date !== isoDate);
      } else {
        return [...prevDates, isoDate];
      }
    });
  };

  const generateWeekDates = () => {
    const week = [];
    const now = moment.tz(timeZone);

    for (let i = 1; i <= 5; i++) {
      const dayOfWeek = now.day();
      const weekDate = now.clone().weekday(i);

      week.push({
        dayName: weekDate.format('dddd'),
        date: weekDate.date(),
        monthName: weekDate.format('MMMM'),
        isToday: i === dayOfWeek,
        isoDate: weekDate.format('YYYY-MM-DD'),
      });
    }

    return week;
  };

  const weekDates = generateWeekDates();

  const dateBoxStyle = {
    flexGrow: 1,
    backgroundColor: isDarkMode ? '#262729' : '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '2vw',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isDarkMode ? '#484848' : '#c4c4c4',
    ':hover': {
      borderTop: '5px solid #E73D44',
      color: '#E73D44 !important',
      cursor: 'pointer',
    },
  };

  return (
    <>
      <Typography
        variant="h3"
        sx={{ marginBottom: '60px', fontWeight: 'bold' }}
      >
        {t('TITLES.MAIN_TITLE')}
      </Typography>

      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
      >
        <Stack>
          <Typography
            variant="h4"
            sx={{ color: '#A3A3A3', fontWeight: 'bold' }}
          >
            {t('TITLES.IMPACT')}:
          </Typography>

          <Stack
            direction={'row'}
            flexWrap={'wrap'}
            sx={{ marginBottom: '24px' }}
          >
            {Object.entries(filters).map(([key, value]) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={value}
                    onChange={handleFilterChange}
                    name={key}
                    sx={{
                      color:
                        key === 'Low'
                          ? '#FFC700'
                          : key === 'Medium'
                          ? '#FF9264'
                          : key === 'High'
                          ? '#FF3548'
                          : '#B164FF',
                      '&.Mui-checked': {
                        color:
                          key === 'Low'
                            ? '#FFC700'
                            : key === 'Medium'
                            ? '#FF9264'
                            : key === 'High'
                            ? '#FF3548'
                            : '#B164FF',
                      },
                    }}
                  />
                }
                label={t(`IMPACTS.${key.toUpperCase()}`)}
              />
            ))}
          </Stack>
        </Stack>

        <Stack>
          <FormControl
            sx={{
              width: '500px',
              '.MuiSvgIcon-root': {
                color: 'white',
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: '#A3A3A3',
                fontWeight: 'bold',
                marginBottom: '12px',
              }}
            >
              {t('TITLES.TIME_ZONE')}:
            </Typography>
            <Select
              sx={{
                maxWidth: '90vw',
                '&:hover': {
                  border: '1px solid #e73d44',
                },
                '& .MuiSelect-select': {
                  color: isDarkMode ? 'white' : '#262729',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: isDarkMode ? 'white' : '#262729',
                },
                '.MuiSelect-select': {
                  color: isDarkMode ? 'white' : '#262729',
                },
              }}
              labelId="demo-simple-select-label"
              id="fuseau-horaire-select"
              value={timeZone}
              onChange={handleChangeTimeZone}
              displayEmpty
              renderValue={
                timeZone !== ''
                  ? undefined
                  : () => <span>Choisissez un fuseau horaire</span>
              }
            >
              {timeZones.map((tz) => (
                <MenuItem key={tz} value={tz}>
                  {tz} (UTC{moment.tz(tz).format('Z')})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        flexShrink={5}
        sx={{
          width: '100%',
          marginTop: '40px',
          overflowX: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {weekDates.map(({ dayName, date, monthName, isToday, isoDate }) => (
          <Box
            key={isoDate}
            sx={{
              ...dateBoxStyle,
              borderTop: selectedDates.includes(isoDate)
                ? '5px solid #E73D44'
                : undefined,
              color: selectedDates.includes(isoDate) ? '#E73D44' : '#9A9A9A',
            }}
            onClick={() => handleDayClick(isoDate)}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                color: selectedDates.includes(isoDate) ? '#E73D44' : '#9A9A9A',
              }}
            >
              {dayName.charAt(0).toUpperCase() + dayName.slice(1)}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                color: selectedDates.includes(isoDate) ? '#E73D44' : '#9A9A9A',
              }}
            >
              {date}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                color: selectedDates.includes(isoDate) ? '#E73D44' : '#9A9A9A',
              }}
            >
              {monthName.charAt(0).toUpperCase() + monthName.slice(1)}
            </Typography>
          </Box>
        ))}
      </Stack>

      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            backgroundColor: isDarkMode ? '#262729' : '#fffff',
            color: '#E1E1E1',
            '& .MuiTableCell-root': {
              color: isDarkMode ? '#ffffff' : '#262729',
              borderBottom: `1px solid ${isDarkMode ? '#484848' : '#c4c4c4'}`,
              fontSize: '16px',
              fontWeight: 'bold',
              textWrap: 'nowrap',
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingTop: '42px ' }}>
                {t('TABLE.DESCRIPTION')}
              </TableCell>
              <TableCell align="center" sx={{ paddingTop: '42px ' }}>
                {t('TABLE.INSTRUMENT')}
              </TableCell>
              <TableCell align="center" sx={{ paddingTop: '42px ' }}>
                {t('TABLE.DATE')}
              </TableCell>
              <TableCell align="center" sx={{ paddingTop: '42px ' }}>
                {t('TABLE.FORECAST')}
              </TableCell>
              <TableCell align="center" sx={{ paddingTop: '42px ' }}>
                {t('TABLE.PREVIOUS')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:nth-child(2n)': {
                    backgroundColor: isDarkMode ? '#26292C' : '#fffff',
                  },
                }}
              >
                <TableCell component="th" scope="row" sx={{ color: 'white' }}>
                  <Stack direction={'row'} alignItems={'center'} gap={2}>
                    <Rating
                      name="simple-controlled"
                      value={
                        row.impact === 'Low'
                          ? 1
                          : row.impact === 'Medium'
                          ? 2
                          : row.impact === 'High'
                          ? 3
                          : 3
                      }
                      readOnly
                      max={3}
                      icon={
                        <StarIcon
                          fontSize="small"
                          sx={
                            row.impact === 'High'
                              ? { color: '#FF3548' }
                              : row.impact === 'Medium'
                              ? { color: '#FF9264' }
                              : row.impact === 'Low'
                              ? { color: '#FFC700' }
                              : row.impact === 'Holiday'
                              ? { color: 'transparent', stroke: '#B164FF' }
                              : { color: 'gray' }
                          }
                        />
                      }
                      emptyIcon={
                        <span
                          style={
                            row.impact === 'Holiday'
                              ? { display: 'block' }
                              : { visibility: 'hidden' }
                          }
                        >
                          {' '}
                          <StarIcon fontSize="inherit" />{' '}
                        </span>
                      }
                    />
                    {row.title}
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    direction={'row'}
                    gap={2}
                  >
                    {getInstrumentIcon(row.instrument)}
                    {row.instrument === 'oilWTI'
                      ? 'OIL'
                      : row.instrument === 'USGas'
                      ? 'GAS'
                      : row.instrument}
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  {row.formattedDate}
                  <br />
                  {row.formattedTime}
                  <Typography sx={{ color: '#797979', fontWeight: 'bold' }}>
                    <Countdown
                      targetDate={moment.tz(row.date, timeZone).toDate()}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {row.forecast || t('TABLE.NO_FORECAST')}
                </TableCell>
                <TableCell align="center">
                  {row.previous || t('TABLE.NO_PREVIOUS_VALUE')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Calendar;
