import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../context/AuthContext/AuthContext';
import { useTheme } from '../../context/ThemeContext/ThemeContext';

import useDocumentTitle from '../../hooks/useDocumentTitle';

import { fetchCertificate } from '../../services/certificate/certificate';
import { logout } from '../../services/auth/login';
import { CertificateResponse } from '../../services/certificate/interfaces';

import Tab from '../../components/Tab/Tab';
import {
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import LockIcon from '@mui/icons-material/Lock';

interface CertificateDisplay extends CertificateResponse {
  imageUrl: string;
}

const Certificate: React.FC = () => {
  const { tokens, isTokensLoading, user } = useAuth();
  const { isDarkMode } = useTheme();
  const { t } = useTranslation(['certificate']);

  const [certificates, setCertificates] = useState<CertificateDisplay[]>([]);
  const [payoutCertificates, setPayoutCertificates] = useState<
    CertificateDisplay[]
  >([]);
  const [fundedCertificates, setFundedCertificates] = useState<
    CertificateDisplay[]
  >([]);
  const [hasUnlockedCertificate, setHasUnlockedCertificate] =
    useState<boolean>(false);
  const [isImageCopied, setIsImageCopied] = useState<{
    [key: string]: boolean;
  }>({});
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);

  useDocumentTitle(t('CERTIFICATE_TITLE'));

  useEffect(() => {
    if (!isTokensLoading && tokens?.accessToken && user) {
      const fetchData = async () => {
        try {
          const certificatesData = await fetchCertificate(
            tokens.accessToken,
            isAnonymous,
          );
          const enhancedCertificates = certificatesData.map((cert) => ({
            ...cert,
            imageUrl: `data:image/jpeg;base64,${cert.image}`,
          }));
          setCertificates(enhancedCertificates);
          setPayoutCertificates(
            enhancedCertificates.filter(
              (cert) => cert.certificateType === 'PAYOUT',
            ),
          );
          setFundedCertificates(
            enhancedCertificates.filter(
              (cert) => cert.certificateType === 'FUNDED',
            ),
          );

          setHasUnlockedCertificate(
            certificatesData.some((cert) => cert.unlocked),
          );
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } else if (!tokens) {
      logout();
    }
  }, [tokens, isTokensLoading, user, isAnonymous]);

  const downloadImage = (imageUrl: string) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'certificate';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const copyImageToClipboard = async (imageUrl: string, id: number) => {
    try {
      const response = await fetch(`${imageUrl}`);
      const blob = await response.blob();

      const img = new Image();
      img.src = URL.createObjectURL(blob);

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
        }

        canvas.toBlob(async (pngBlob) => {
          if (pngBlob) {
            await navigator.clipboard.write([
              new ClipboardItem({ 'image/png': pngBlob }),
            ]);
            setIsImageCopied((prev) => ({ ...prev, [id]: true }));
            setTimeout(() => {
              setIsImageCopied((prev) => ({ ...prev, [id]: false }));
            }, 2000);
          }
        }, 'image/png');
      };
    } catch (error) {
      toast.error(t('AN_ERROR_HAS_OCCURED'), {
        progressStyle: { backgroundColor: 'red' },
      });
    }
  };

  const handleChangeAnonymous = (event: ChangeEvent<HTMLInputElement>) => {
    setIsAnonymous(event.target.checked);
  };

  const tabNames = [
    t('TOTAL_TAB_TITLE'),
    t('PAYOUT_TAB_TITLE'),
    t('FUNDED_TAB_TITLE'),
  ];

  return (
    <div>
      <style>
        {`
      @keyframes shake {
        0%, 100% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
      }
    `}
      </style>

      <Stack
        justifyContent={'space-between'}
        alignItems={'center'}
        direction={'row'}
        flexWrap={'wrap'}
      >
        <Typography variant="h2" fontWeight="bold" mb={5}>
          {t('CERTIFICATE_TITLE')}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={isAnonymous}
              onChange={handleChangeAnonymous}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('HIDE_LASTNAME_BUTTON')}
        />
      </Stack>

      {!hasUnlockedCertificate && (
        <Stack
          alignItems={'center'}
          gap={5}
          mb={5}
          sx={{
            backgroundColor: 'rgba(231, 61, 68, 0.3)',
            border: '2px solid #E73D44',
            borderRadius: '10px',
            padding: '20px',
          }}
        >
          <SchoolIcon sx={{ fontSize: '32px' }} />
          <Typography fontWeight={'bold'}>
            {t('YOU_DO_NOT_HAVE_ANY_DIPLOMA_AT_THE_MOMENT')}
          </Typography>
          <Button variant="contained" href="/buy-challenge">
            {t('START_A_NEW_CHALLENGE_BUTTON')}
          </Button>
        </Stack>
      )}

      <Tab id="certificate" tabs={tabNames}>
        <div>
          <Grid item xs={12}>
            <Grid container justifyContent={'center'} spacing={3}>
              {certificates.map((cert, index) => (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={index}>
                  <Stack
                    sx={{
                      backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
                      padding: '20px',
                      borderRadius: '10px',
                    }}
                  >
                    <Stack
                      direction={'row'}
                      gap={5}
                      mb={2}
                      alignItems={'center'}
                      sx={{
                        backgroundColor:
                          cert.certificateType === 'PAYOUT'
                            ? 'rgba(231, 61, 68, 0.3)'
                            : 'rgba(255, 224, 99, 0.3)',
                        color:
                          cert.certificateType === 'PAYOUT'
                            ? '#E73D44'
                            : '#e1b811',
                        width: 'fit-content',
                        padding: '8px 16px',
                        borderRadius: '5px',
                      }}
                    >
                      <Typography variant="body1" fontWeight={'bold'}>
                        {cert.certificateType.charAt(0).toUpperCase() +
                          cert.certificateType.slice(1).toLowerCase()}{' '}
                        {t('CERTIFICATE_TITLE')}
                      </Typography>
                      {cert.unlocked && (
                        <Tooltip
                          title={`${t('OBTAINED_DATE')} ${cert.obtainedDate}`}
                          placement="top"
                        >
                          <InfoIcon sx={{ fontSize: '20px' }} />
                        </Tooltip>
                      )}
                    </Stack>
                    <Stack
                      width={'100%'}
                      textAlign={'center'}
                      position={'relative'}
                    >
                      <img
                        style={{
                          display: 'block',
                          width: '100%',
                          height: 'auto',
                          ...(!cert.unlocked && {
                            filter: 'grayscale(90%) blur(5px)',
                          }),
                        }}
                        src={cert.imageUrl}
                        alt="Certificate"
                      />
                      {!cert.unlocked && (
                        <Stack
                          position={'absolute'}
                          top={'50%'}
                          left={'50%'}
                          sx={{ transform: 'translate(-50%, -50%)' }}
                        >
                          <LockIcon
                            sx={{
                              fontSize: '92px',
                              color: '#FFFFFF',
                              '&:hover': { animation: 'shake 0.2s linear' },
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                    {cert.unlocked && (
                      <Stack direction={'row'} mt={1} gap={2}>
                        <Button
                          onClick={() => downloadImage(cert.imageUrl)}
                          variant="text"
                          startIcon={<FileDownloadIcon />}
                        >
                          {t('DOWNLOAD_BUTTON')}
                        </Button>
                        <Button
                          onClick={() =>
                            copyImageToClipboard(cert.imageUrl, cert.id)
                          }
                          variant="text"
                          startIcon={<FileCopyIcon />}
                        >
                          {isImageCopied[cert.id]
                            ? t('COPIED_BUTTON')
                            : t('COPY_BUTTON')}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid item xs={12}>
            <Grid container justifyContent={'center'} spacing={3}>
              {payoutCertificates.map((cert, index) => (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={index}>
                  <Stack
                    sx={{
                      backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
                      padding: '20px',
                      borderRadius: '10px',
                    }}
                  >
                    <Stack
                      direction={'row'}
                      gap={2}
                      mb={2}
                      alignItems={'center'}
                      sx={{
                        backgroundColor:
                          cert.certificateType === 'PAYOUT'
                            ? 'rgba(231, 61, 68, 0.3)'
                            : 'rgba(255, 224, 99, 0.3)',
                        color:
                          cert.certificateType === 'PAYOUT'
                            ? '#E73D44'
                            : '#e1b811',
                        width: 'fit-content',
                        padding: '8px 16px',
                        borderRadius: '5px',
                      }}
                    >
                      <Typography variant="body1" fontWeight={'bold'}>
                        {cert.certificateType.charAt(0).toUpperCase() +
                          cert.certificateType.slice(1).toLowerCase()}{' '}
                        {t('CERTIFICATE_TITLE')}
                      </Typography>
                      {cert.unlocked && (
                        <Tooltip
                          title={`${t('OBTAINED_DATE')} ${cert.obtainedDate}`}
                          placement="top"
                        >
                          <InfoIcon sx={{ fontSize: '20px' }} />
                        </Tooltip>
                      )}
                    </Stack>
                    <Stack
                      width={'100%'}
                      textAlign={'center'}
                      position={'relative'}
                    >
                      <img
                        style={{
                          display: 'block',
                          width: '100%',
                          height: 'auto',
                          ...(!cert.unlocked && {
                            filter: 'grayscale(90%) blur(5px)',
                          }),
                        }}
                        src={cert.imageUrl}
                        alt="Certificate"
                      />
                      {!cert.unlocked && (
                        <Stack
                          position={'absolute'}
                          top={'50%'}
                          left={'50%'}
                          sx={{ transform: 'translate(-50%, -50%)' }}
                        >
                          <LockIcon
                            sx={{
                              fontSize: '92px',
                              color: '#FFFFFF',
                              '&:hover': { animation: 'shake 0.2s linear' },
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                    {cert.unlocked && (
                      <Stack direction={'row'} mt={1} gap={2}>
                        <Button
                          onClick={() => downloadImage(cert.imageUrl)}
                          variant="text"
                          startIcon={<FileDownloadIcon />}
                        >
                          {t('DOWNLOAD_BUTTON')}
                        </Button>
                        <Button
                          onClick={() =>
                            copyImageToClipboard(cert.imageUrl, cert.id)
                          }
                          variant="text"
                          startIcon={<FileCopyIcon />}
                        >
                          {isImageCopied[cert.id]
                            ? t('COPIED_BUTTON')
                            : t('COPY_BUTTON')}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid item xs={12}>
            <Grid container justifyContent={'center'} spacing={3}>
              {fundedCertificates.map((cert, index) => (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={index}>
                  <Stack
                    sx={{
                      backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
                      padding: '20px',
                      borderRadius: '10px',
                    }}
                  >
                    <Stack
                      direction={'row'}
                      gap={2}
                      mb={2}
                      alignItems={'center'}
                      sx={{
                        backgroundColor:
                          cert.certificateType === 'PAYOUT'
                            ? 'rgba(231, 61, 68, 0.3)'
                            : 'rgba(255, 224, 99, 0.3)',
                        color:
                          cert.certificateType === 'PAYOUT'
                            ? '#E73D44'
                            : '#e1b811',
                        width: 'fit-content',
                        padding: '8px 16px',
                        borderRadius: '5px',
                      }}
                    >
                      <Typography variant="body1" fontWeight={'bold'}>
                        {cert.certificateType.charAt(0).toUpperCase() +
                          cert.certificateType.slice(1).toLowerCase()}{' '}
                        {t('CERTIFICATE_TITLE')}
                      </Typography>
                      {cert.unlocked && (
                        <Tooltip
                          title={`${t('OBTAINED_DATE')} ${cert.obtainedDate}`}
                          placement="top"
                        >
                          <InfoIcon sx={{ fontSize: '20px' }} />
                        </Tooltip>
                      )}
                    </Stack>
                    <Stack
                      width={'100%'}
                      textAlign={'center'}
                      position={'relative'}
                    >
                      <img
                        style={{
                          display: 'block',
                          width: '100%',
                          height: 'auto',
                          ...(!cert.unlocked && {
                            filter: 'grayscale(90%) blur(5px)',
                          }),
                        }}
                        src={cert.imageUrl}
                        alt="Certificate"
                      />
                      {!cert.unlocked && (
                        <Stack
                          position={'absolute'}
                          top={'50%'}
                          left={'50%'}
                          sx={{ transform: 'translate(-50%, -50%)' }}
                        >
                          <LockIcon
                            sx={{
                              fontSize: '92px',
                              color: '#FFFFFF',
                              '&:hover': { animation: 'shake 0.2s linear' },
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                    {cert.unlocked && (
                      <Stack direction={'row'} mt={1} gap={2}>
                        <Button
                          onClick={() => downloadImage(cert.imageUrl)}
                          variant="text"
                          startIcon={<FileDownloadIcon />}
                        >
                          {t('DOWNLOAD_BUTTON')}
                        </Button>
                        <Button
                          onClick={() =>
                            copyImageToClipboard(cert.imageUrl, cert.id)
                          }
                          variant="text"
                          startIcon={<FileCopyIcon />}
                        >
                          {isImageCopied[cert.id]
                            ? t('COPIED_BUTTON')
                            : t('COPY_BUTTON')}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </Tab>
    </div>
  );
};

export default Certificate;
