import { toast } from 'react-toastify';
import axiosInstance from '../../utils/axios';
import { ResponseProfile, ResponseKYC, JWTPayload, ProfileInformations } from './interface';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}

export const fetchProfile = async (
  accessToken: string,
): Promise<ResponseProfile> => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.get<ResponseProfile>(
      `/users/profile/edit?email=${decoded?.sub}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const fetchKYCArea = async (
  accessToken: string,
): Promise<ResponseKYC> => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.get<ResponseKYC>(
      `/kyc/area/user/${decoded?.userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProfile = (
  accessToken: string,
  profile: ProfileInformations,
) => {
  const decoded = decodeJWT(accessToken);

  try {
    axiosInstance.put(`/users/${decoded?.userId}`, profile, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const sendKycDocuments = async (
  accessToken: string,
  file: File,
  documentType: 'IDENTITY_PROOF_RECTO' | 'IDENTITY_PROOF_VERSO' | 'ADDRESS_PROOF',
) => {
  const decoded = decodeJWT(accessToken);
  if (!decoded || !decoded.userId) {
    throw new Error('Invalid access token - userId not found');
  }

  const formData = new FormData();
  formData.append('userId', decoded.userId);
  formData.append('file', file);
  formData.append('documentType', documentType);

  try {
    const response = await axiosInstance.post('/kyc/upload', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status === 200) {
      toast.success('Votre demande a été effectuée.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
  } catch (error) {
    toast.error('Votre demande a échouée.', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};
