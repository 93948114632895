import React from 'react';

import { useTheme } from '../../../context/ThemeContext/ThemeContext';

import Icon from '../../../components/userInterface/Icon/Icon';

import classes from './_tag.module.scss';

interface FilterTagProps {
  label: string;
  onClick: () => void;
  onRemove: () => void;
}

const FilterTag: React.FC<FilterTagProps> = ({ label, onRemove, onClick }) => {
  const { isDarkMode } = useTheme();

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onClick();
  };

  const handleRemove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <span
      className={`${classes.tag} ${isDarkMode ? classes.dark : classes.light}`}
      onClick={handleClick}
    >
      {label}
      <div className={classes.button} onClick={handleRemove}>
        <Icon
          color="custom"
          fill="#FFFFFF"
          size={18}
          path="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"
        />
      </div>
    </span>
  );
};

export default FilterTag;
