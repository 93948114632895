import React, { useEffect, useRef, useState } from 'react';
import FilterTag from './Tag/Tag';

import { useTheme } from '../../context/ThemeContext/ThemeContext';

import Icon from '../userInterface/Icon/Icon';
import Input from '../userInterface/Input/Input';
import Button from '../userInterface/Button/Button';

import classes from './_filter.module.scss';

export interface FilterType {
  key: string;
  type: 'string' | 'date' | 'boolean';
}

interface FilterProps {
  onApplySearch: (filterType: string, filterValue: string) => void;
  onRemoveFilter: (filterType: string) => void;
  filters: { [key: string]: string };
  filterTypes: FilterType[];
}

const Filter: React.FC<FilterProps> = ({
  onApplySearch,
  onRemoveFilter,
  filters,
  filterTypes,
}) => {
  const { isDarkMode } = useTheme();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<FilterType | null>(null);
  const [filterValue, setFilterValue] = useState('');

  const refDropdown = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const refButton = useRef<HTMLButtonElement>(null);
  const refCheckbox = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    if (
      !refDropdown.current?.contains(e.target) &&
      !refInput.current?.contains(e.target) &&
      !refButton.current?.contains(e.target) &&
      !refCheckbox.current?.contains(e.target)
    ) {
      setCurrentFilter(null);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleApplySearch();
    }
  };

  const handleFilterSelect = (filterType: FilterType) => {
    setCurrentFilter(filterType);
    setFilterValue(
      filterType.type === 'boolean' ? 'false' : filters[filterType.key] || '',
    );
    setDropdownOpen(false);
  };

  const handleApplySearch = () => {
    if (currentFilter) {
      onApplySearch(currentFilter.key, filterValue);
      setCurrentFilter(null);
      setFilterValue('');
      setDropdownOpen(false);
    }
  };

  const handleCloseSearchModal = () => {
    setCurrentFilter(null);
    setFilterValue('');
    setDropdownOpen(false);
  };

  const handleTagClick = (filterKey: string) => {
    const filterType = filterTypes.find((ft) => ft.key === filterKey);
    if (filterType) {
      setCurrentFilter(filterType);
      setFilterValue(filters[filterKey]);
      setDropdownOpen(true);
    }
  };

  const toggleDropdown = () => {
    if (!dropdownOpen) {
      setCurrentFilter(null);
    }
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className={classes.filterContainer}>
      <div
        className={` ${classes.searchBar} ${
          isDarkMode ? classes.dark : classes.light
        }`}
        onClick={toggleDropdown}
      >
        <div className={classes.filterButton}>
          <Icon
            color="custom"
            fill="#AAAAAA"
            size={23}
            path="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"
          />
        </div>
        {Object.entries(filters).map(
          ([key, value]) =>
            value && (
              <FilterTag
                key={key}
                label={`Le ${key} contient "${value}"`}
                onClick={() => handleTagClick(key)}
                onRemove={() => onRemoveFilter(key)}
              />
            ),
        )}
        <div className={classes.searchInput} />
      </div>
      {dropdownOpen && currentFilter === null && (
        <div
          ref={refDropdown}
          className={`${classes.dropdown} ${
            isDarkMode ? classes.dark : classes.light
          }`}
        >
          {filterTypes.map((filterType) => (
            <button
              key={filterType.key}
              onClick={() => handleFilterSelect(filterType)}
            >
              {filterType.key}
            </button>
          ))}
        </div>
      )}
      {currentFilter && (
        <div
          className={`${classes.searchModal} ${
            isDarkMode ? classes.dark : classes.light
          }`}
        >
          <h3>
            {currentFilter.key}
            <Icon
              onClick={() => handleCloseSearchModal()}
              color="white"
              size={24}
              path="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
            />
          </h3>
          <label htmlFor="value">Contient</label>
          {currentFilter.type === 'boolean' ? (
            <div className={classes.checkboxContainer}>
              <input
                ref={refCheckbox}
                id="checkbox"
                type="checkbox"
                className={classes.customCheckbox}
                checked={filterValue === 'true'}
                onChange={(e) =>
                  setFilterValue(e.target.checked ? 'true' : 'false')
                }
              />
              <label htmlFor="checkbox" className={classes.checkboxLabel}>
                Actif uniquement
              </label>
            </div>
          ) : (
            <Input
              ref={refInput}
              autoFocus
              id="value"
              type={currentFilter.type === 'date' ? 'date' : 'text'}
              className={classes.filterInput}
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              placeholder={`Valeur pour ${currentFilter.key}`}
              onKeyDown={handleKeyDown}
            />
          )}
          <Button
            ref={refButton}
            variant="rounded-outline"
            onClick={handleApplySearch}
            className={classes.applyButton}
          >
            Appliquer
          </Button>
        </div>
      )}
    </div>
  );
};

export default Filter;
