import { ChangeEvent, useState } from 'react';

import Icon from '../Icon/Icon';

import classes from './_uploadButton.module.scss';

interface UploadButtonProps {
  id: string;
  onFilesUploaded?: (files: File[]) => void;
  children: React.ReactNode;
}

const UploadButton: React.FC<UploadButtonProps> = ({
  id,
  onFilesUploaded,
  children,
}) => {
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const filesFromInput = event.target.files;
    if (filesFromInput && filesFromInput.length > 0) {
      const newFiles: File[] = Array.from(filesFromInput);
      const newFileNames: string[] = newFiles.map((file) => file.name);

      setFiles(newFiles);
      setFileNames(newFileNames);

      if (onFilesUploaded) {
        onFilesUploaded(newFiles);
      }
    } else {
      setFiles([]);
      setFileNames([]);

      if (onFilesUploaded) {
        onFilesUploaded([]);
      }
    }
  };

  const handleRemoveFile = (fileNameToRemove: string) => {
    const updatedFiles = files.filter((file) => file.name !== fileNameToRemove);
    const updatedFileNames = fileNames.filter(
      (fileName) => fileName !== fileNameToRemove,
    );

    setFiles(updatedFiles);
    setFileNames(updatedFileNames);

    if (onFilesUploaded) {
      onFilesUploaded(updatedFiles);
    }
  };

  return (
    <div className={classes.container}>
      <input
        className={classes.input}
        onChange={handleFileChange}
        type="file"
        id={id}
        multiple
      />
      <label className={classes.button} htmlFor={id}>
        <Icon
          color="custom"
          size={16}
          path="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM14 9V15H10V9H5L12 2L19 9H14Z"
        />
        {children}
      </label>
      <ul className={classes.filenames}>
        {fileNames.map((fileName, index) => (
          <li
            className={classes.filename}
            onClick={() => handleRemoveFile(fileName)}
            key={index}
          >
            {fileName}
            <Icon
              color="custom"
              size={16}
              path="M7 6V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7ZM13.4142 13.9997L15.182 12.232L13.7678 10.8178L12 12.5855L10.2322 10.8178L8.81802 12.232L10.5858 13.9997L8.81802 15.7675L10.2322 17.1817L12 15.4139L13.7678 17.1817L15.182 15.7675L13.4142 13.9997ZM9 4V6H15V4H9Z"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
export default UploadButton;
