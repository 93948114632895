import { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../context/AuthContext/AuthContext';
import { useTheme } from '../../context/ThemeContext/ThemeContext';

import useDocumentTitle from '../../hooks/useDocumentTitle';

import {
  fetchAffiliateAreaData,
  fetchAffiliatePayoutRequest,
  fetchAffiliateViewMoreCards,
  fetchAffiliateCommissionsPayoutRequest,
  payoutRequest,
} from '../../services/affiliate/affiliate';
import { logout } from '../../services/auth/login';

import { defineWallet } from '../../services/client/client';
import {
  AffiliationCommissionsGetPayoutContent,
  ResponseViewMoreStatic,
} from '../../services/affiliate/interfaces';

import IconCard from '../../components/Cards/IconCard/IconCard';
import Card from '../../components/Cards/Card/Card';
import Button from '../../components/userInterface/Button/Button';
import Input from '../../components/userInterface/Input/Input';
import Table from '../../components/Table/Table';
import Tag from '../../components/userInterface/Tag/Tag';
import Icon from '../../components/userInterface/Icon/Icon';
import Tooltip from '../../components/userInterface/Tooltip/Tooltip';
import Loader from '../../components/Loader/Loader';
import Modal from '../../components/Modal/Modal';
import PayoutDropdown from '../../components/PayoutDropdown/PayoutDropdown';
import Pagination from '../../components/Pagination/Pagination';
import MuiTooltip from '@mui/material/Tooltip';
import Error from '../../components/Error/Error';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import MUIModal from '@mui/material/Modal';

import networkList from './networkList.json';

import classes from './_partnerArea.module.scss';

interface PaginationItem {
  index: number;
  currentPage: number;
}

const PartnerArea = () => {
  const { t } = useTranslation(['partnerArea', 'components', 'common']);
  const { isDarkMode } = useTheme();
  const { tokens, isTokensLoading } = useAuth();
  useDocumentTitle('Partner Area');

  const commissionsPerPage = 10;
  const size = 10;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [copied, setCopied] = useState(false);
  const [commissionDetails, setCommissionDetails] = useState<any>([]);
  const [data, setData] = useState<any>();
  const [affiliateGetPayoutData, setAffiliateGetPayoutData] = useState<any>([]);
  const prevTokenRef = useRef<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isGetPayoutModalOpen, setIsGetPayoutModalOpen] = useState(false);
  const [commissions, setCommissions] = useState<{}[]>([]);
  const [viewMoreCards, setViewMoreCards] = useState<ResponseViewMoreStatic>();
  const [commissionsOnPeriod, setCommissionsOnPeriod] = useState<any>();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState<string>('');
  const [selectedEndDate, setSelectedEndDate] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [paginatedData, setPaginatedData] = useState<{
    [key: number]: { [key: number]: any[] };
  }>({});
  const [totalPages, setTotalPages] = useState<any>({});
  const [cryptoWalletAddress, setCryptoWalletAddress] = useState<string>('');
  const [selectedNetwork, setSelectedNetwork] = useState<string | null>(null);
  const [commissionsPage, setCommissionsPage] = useState<number>(0);
  const [totalCommissionsPages, setTotalCommissionsPages] = useState<number>(0);

  const [openViewMoreModal, setOpenViewMoreModal] = useState(false);
  const handleOpenViewMoreModal = () => {
    setOpenViewMoreModal(true);
    handleFetchAffiliatePayoutRequest();
  };
  const handleCloseViewMoreModal = () => setOpenViewMoreModal(false);

  const [openGetPayoutModal, setOpenGetPayoutModal] = useState(false);
  const handleOpenGetPayoutModal = () => {
    setOpenGetPayoutModal(true);
    handleFetchAffiliatePayoutRequest();
  };
  const handleCloseGetPayoutModal = () => setOpenGetPayoutModal(false);

  const updateTotalPages = (index: number, totalPagesForIndex: number) => {
    setTotalPages((prev: any) => ({ ...prev, [index]: totalPagesForIndex }));
  };
  const [paginationState, setPaginationState] = useState<PaginationItem[]>([]);

  useEffect(() => {
    const initialPaginationState = affiliateGetPayoutData.map(
      (_: any, index: number) => ({
        index,
        currentPage: 0,
      }),
    );

    setPaginationState(initialPaginationState);
  }, [affiliateGetPayoutData]);

  const updatePaginationState = (index: number, newPage: number) => {
    const newState = paginationState.map((item) =>
      item.index === index ? { ...item, currentPage: newPage } : item,
    );

    setPaginationState(newState);
  };

  const updatePaginatedData = useCallback(
    (tableIndex: number, page: number, newData: any) => {
      setPaginatedData((prev) => ({
        ...prev,
        [tableIndex]: {
          ...(prev[tableIndex] || {}),
          [page]: newData,
        },
      }));
    },
    [setPaginatedData],
  );

  const loadCommissions = useCallback(async () => {
    if (tokens && tokens.accessToken !== prevTokenRef.current) {
      setIsLoading(true);
      try {
        const data = await fetchAffiliateAreaData(
          tokens.accessToken,
          commissionsPage,
          size,
        );
        setData(data);
        setCommissionDetails(data.commissionDetails.content);
        setTotalCommissionsPages(data.commissionDetails.totalPages);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, commissionsPage, size]);

  useEffect(() => {
    if (!isTokensLoading) {
      loadCommissions();
    }
  }, [loadCommissions, isTokensLoading]);

  const handleCopyClick = () => {
    if (inputRef.current) {
      navigator.clipboard.writeText(inputRef.current.value);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  const handleNetworkChange = (
    event: React.ChangeEvent<{}>,
    value: { id: number; name: string; src: string } | null,
  ) => {
    setSelectedNetwork(value ? value.name : null);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const openGetPayoutModal = () => {
  //   setIsGetPayoutModalOpen(true);
  // };
  // const closeGetPayoutModal = () => {
  //   setIsGetPayoutModalOpen(false);
  // };

  const fetchViewMore = async () => {
    if (tokens?.accessToken) {
      setIsLoading(true);
      try {
        const viewMoreCards = await fetchAffiliateViewMoreCards(
          tokens.accessToken,
        );
        setViewMoreCards(viewMoreCards);
      } finally {
        setIsLoading(false);
      }
    } else if (!tokens) {
      logout();
    }
  };

  const handleDefineWallet = () => {
    if (tokens?.accessToken) {
      if (cryptoWalletAddress === '' || selectedNetwork === null) {
        setError('All fields are required');
        return;
      }

      try {
        defineWallet(cryptoWalletAddress, selectedNetwork, tokens?.accessToken);
      } finally {
        setError('');
      }
    } else if (!tokens) {
      logout();
      setError('');
    }
  };

  const handleFetchAffiliatePayoutRequest = () => {
    if (tokens?.accessToken) {
      setIsLoading(true);

      try {
        (async () => {
          const response = await fetchAffiliatePayoutRequest(
            tokens?.accessToken,
          );
          setAffiliateGetPayoutData(response.payoutMonths);
        })();
      } finally {
        setIsLoading(false);
      }
    } else if (!tokens) {
      logout();
    }
  };

  const fetchAffiliationsCommissionsGetPayout = useCallback(
    async (tableIndex: number, page: number) => {
      if (!tokens || !tokens.accessToken) return;

      setIsLoading(true);
      try {
        const payoutMonth = affiliateGetPayoutData[tableIndex];
        if (!payoutMonth) return;

        const response = await fetchAffiliateCommissionsPayoutRequest(
          tokens.accessToken,
          payoutMonth.monthValue,
          payoutMonth.year,
          page,
          size,
        );

        updateTotalPages(tableIndex, response.totalPages);
        updatePaginatedData(tableIndex, page, response.content);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [tokens, affiliateGetPayoutData, size, updatePaginatedData],
  );

  useEffect(() => {
    if (!isTokensLoading && tokens && tokens.accessToken) {
      setIsLoading(true);
      paginationState.forEach(({ index, currentPage }) => {
        fetchAffiliationsCommissionsGetPayout(index, currentPage);
      });
      setIsLoading(false);
    }
  }, [
    paginationState,
    tokens,
    isTokensLoading,
    fetchAffiliationsCommissionsGetPayout,
  ]);

  useEffect(() => {
    fetchViewMore();
  }, []);

  useEffect(() => {
    if (
      !isTokensLoading &&
      tokens &&
      tokens.accessToken &&
      affiliateGetPayoutData.length > 0
    ) {
      setIsLoading(true);
      affiliateGetPayoutData.forEach((_: any, index: any) => {
        fetchAffiliationsCommissionsGetPayout(index, 0);
      });
      setIsLoading(false);
    }
  }, [
    tokens,
    isTokensLoading,
    affiliateGetPayoutData,
    fetchAffiliationsCommissionsGetPayout,
  ]);

  const commissionsData = [];
  const referralsData = [];

  const commissionsGetPayoutColumns = [
    t('tables.commissions.id'),
    t('tables.commissions.name'),
    t('tables.commissions.pricePaid'),
    t('tables.commissions.commissionsEarned'),
    t('tables.commissions.notes'),
    t('tables.commissions.transactionDate'),
  ];

  for (const commissionDetail of commissionDetails) {
    commissionsData.push({
      [t('tables.commissions.name')]: commissionDetail?.name,
      [t('tables.commissions.pricePaid')]: `${commissionDetail?.pricePaid}€`,
      [t(
        'tables.commissions.commissionsEarned',
      )]: `${commissionDetail?.commissionEarned}€`,
      [t('tables.commissions.notes')]: commissionDetail?.notes,
    });
  }
  for (const commissionDetail of commissionDetails) {
    referralsData.push({
      [t('tables.referrals.name')]: commissionDetail?.name,
      [t('tables.referrals.pricePaid')]: `${commissionDetail?.pricePaid}€`,
    });
  }
  const commissionsColumns = [
    t('tables.commissions.name'),
    t('tables.commissions.pricePaid'),
    t('tables.commissions.commissionsEarned'),
    t('tables.commissions.notes'),
  ];
  const referralsColumns = [
    t('tables.referrals.name'),
    t('tables.referrals.pricePaid'),
  ];

  const viewMoreReferralsData = commissions.map((detail: any) => ({
    [t('tables.commissions.name')]: detail.name,
    [t('tables.commissions.pricePaid')]: `${detail.pricePaid}€`,
    [t('tables.commissions.commissionsEarned')]: `${
      detail.commissionEarned ? detail.commissionEarned : 0
    }€`,
    [t('tables.commissions.notes')]: detail.notes,
  }));
  const viewMoreReferralsColumns = [
    t('tables.commissions.name'),
    t('tables.commissions.pricePaid'),
    t('tables.commissions.commissionsEarned'),
    t('tables.commissions.notes'),
  ];

  const paginatedReferralsData = viewMoreReferralsData.slice(
    currentPage * commissionsPerPage,
    (currentPage + 1) * commissionsPerPage,
  );

  const renderTableOrMessage = () => {
    if (isSearchLoading) {
      return <Loader />;
    }

    if (!commissions.length) {
      return (
        <h3 className={classes.noActivity}>{t('detailsModal.noActivity')}</h3>
      );
    }
    return (
      <Table data={paginatedReferralsData} columns={viewMoreReferralsColumns} />
    );
  };

  const handleChangeCryptoWalletAddress = (event: any) => {
    setCryptoWalletAddress(event.target.value);
  };

  if (isLoading) {
    return <Loader />;
  }

  const borderColor = isDarkMode ? '#d4d4d4' : '#4f4f51';
  const textColor = isDarkMode ? '#d4d4d4' : '#4f4f51';
  const iconColor = isDarkMode ? '#d4d4d4' : '#4f4f51';

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: isDarkMode ? '#262729' : '#ffffff',
    color: isDarkMode ? '#ffffff' : '#262729',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <MUIModal
        // title={t('detailsModal.title')}
        // isOpen={isModalOpen}
        // onClose={closeModal}
        open={openViewMoreModal}
        onClose={handleCloseViewMoreModal}
        aria-labelledby={t('detailsModal.title')}
      >
        <Box sx={style}>
          <Typography
            variant="h4"
            sx={{ marginBottom: '24px', fontWeight: 'bold' }}
          >
            {t('detailsModal.title')}
          </Typography>
          <div className={classes.cardsContainer}>
            <IconCard
              title={t('detailsModal.totalCommissions')}
              amount={`€${viewMoreCards?.totalCommissions}`}
              icon="M2.00488 9H21.0049C21.5572 9 22.0049 9.44772 22.0049 10V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V9ZM3.00488 3H18.0049V7H2.00488V4C2.00488 3.44772 2.4526 3 3.00488 3ZM15.0049 14V16H18.0049V14H15.0049Z"
              className={classes.modalCard}
            />
            <IconCard
              title={t('detailsModal.commissionThisYear')}
              amount={`€${viewMoreCards?.yearlyCommissions}`}
              icon="M3.00488 3.00293H21.0049C21.5572 3.00293 22.0049 3.45064 22.0049 4.00293V20.0029C22.0049 20.5552 21.5572 21.0029 21.0049 21.0029H3.00488C2.4526 21.0029 2.00488 20.5552 2.00488 20.0029V4.00293C2.00488 3.45064 2.4526 3.00293 3.00488 3.00293ZM10.0549 11.0029C10.2865 9.86181 11.2954 9.00293 12.5049 9.00293C13.1201 9.00293 13.6834 9.22515 14.1189 9.59369L15.8198 8.45969C14.9973 7.5642 13.8166 7.00293 12.5049 7.00293C10.1886 7.00293 8.28107 8.75295 8.03235 11.0029H7.00488V13.0029H8.03235C8.28107 15.2529 10.1886 17.0029 12.5049 17.0029C13.8166 17.0029 14.9973 16.4417 15.8198 15.5462L14.1188 14.4122C13.6833 14.7807 13.1201 15.0029 12.5049 15.0029C11.2954 15.0029 10.2865 14.144 10.0549 13.0029H15.0049V11.0029H10.0549Z"
              className={classes.modalCard}
            />
            <IconCard
              title={t('detailsModal.commissionThisMonth')}
              amount={`€${viewMoreCards?.monthlyCommissions}`}
              icon="M23.0049 12.0029V14.0029C23.0049 17.3166 18.08 20.0029 12.0049 20.0029C6.03824 20.0029 1.18114 17.4117 1.00957 14.1798L1.00488 14.0029V12.0029C1.00488 15.3166 5.92975 18.0029 12.0049 18.0029C18.08 18.0029 23.0049 15.3166 23.0049 12.0029ZM12.0049 4.00293C18.08 4.00293 23.0049 6.68922 23.0049 10.0029C23.0049 13.3166 18.08 16.0029 12.0049 16.0029C5.92975 16.0029 1.00488 13.3166 1.00488 10.0029C1.00488 6.68922 5.92975 4.00293 12.0049 4.00293Z"
              className={classes.modalCard}
            />
            <IconCard
              title={t('detailsModal.COMMISSION_THIS_WEEK')}
              amount={`€${viewMoreCards?.weeklyCommissions}`}
              icon="M22.0049 10V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V10H22.0049ZM22.0049 8H2.00488V4C2.00488 3.44772 2.4526 3 3.00488 3H21.0049C21.5572 3 22.0049 3.44772 22.0049 4V8ZM15.0049 16V18H19.0049V16H15.0049Z"
              className={classes.modalCard}
            />
          </div>

          <div className={classes.payoutDropdown}>
            {affiliateGetPayoutData.map((payoutMonth: any, index: any) => {
              const currentPage =
                paginationState.find((item) => item.index === index)
                  ?.currentPage || 0;
              const currentData = paginatedData[index]?.[currentPage] || [];

              return (
                <PayoutDropdown
                  key={index}
                  month={payoutMonth.month}
                  year={payoutMonth.year}
                  amount={payoutMonth.amount}
                  button={
                    payoutMonth.alreadyProcessed ? (
                      <MuiTooltip
                        title={`Processed the ${payoutMonth.processDate}`}
                        followCursor
                      >
                        <div className={classes.greenButton}>Processed</div>
                      </MuiTooltip>
                    ) : payoutMonth.alreadyRequested ? (
                      <MuiTooltip
                        title={`Will be processed ${payoutMonth.processDate}`}
                        followCursor
                      >
                        <div className={classes.redButton}>In Review</div>
                      </MuiTooltip>
                    ) : (
                      <MuiTooltip
                        title={`Will in review the ${payoutMonth.processDate}`}
                        followCursor
                      >
                        <div className={classes.primaryButton}>
                          Not Available
                        </div>
                      </MuiTooltip>
                    )
                  }
                >
                  <Table
                    data={currentData.map((detail) => ({
                      [t('tables.commissions.id')]: detail.id,
                      [t('tables.commissions.name')]: detail.name,
                      [t('tables.commissions.pricePaid')]: detail.pricePaid,
                      [t('tables.commissions.commissionsEarned')]:
                        detail.commissionEarned,
                      [t('tables.commissions.notes')]: detail.notes,
                      [t('tables.commissions.transactionDate')]:
                        detail.transactionDate,
                    }))}
                    columns={commissionsGetPayoutColumns}
                  />
                  <Pagination
                    total={totalPages[index] || 0}
                    perPage={size}
                    currentPage={currentPage}
                    onPageChange={(newPage) =>
                      updatePaginationState(index, newPage)
                    }
                  />
                </PayoutDropdown>
              );
            })}
          </div>
        </Box>
      </MUIModal>

      <MUIModal
        // title="Affiliation Payout Management"
        // isOpen={isGetPayoutModalOpen}
        // onClose={closeGetPayoutModal}
        open={openGetPayoutModal}
        onClose={handleCloseGetPayoutModal}
        aria-labelledby="Affiliation Payout Management"
      >
        <Box sx={style}>
          <Typography
            variant="h4"
            sx={{ marginBottom: '24px', fontWeight: 'bold' }}
          >
            Affiliation Payout Management
          </Typography>

          <div className={classes.payoutDropdown}>
            {affiliateGetPayoutData.map((payoutMonth: any, index: any) => {
              const currentPage =
                paginationState.find((item) => item.index === index)
                  ?.currentPage || 0;
              const currentData = paginatedData[index]?.[currentPage] || [];

              return (
                <PayoutDropdown
                  key={index}
                  month={payoutMonth.month}
                  year={payoutMonth.year}
                  amount={payoutMonth.amount}
                  button={
                    payoutMonth.alreadyProcessed ? (
                      <MuiTooltip
                        title={`Processed the ${payoutMonth.processDate}`}
                        followCursor
                      >
                        <div className={classes.greenButton}>Processed</div>
                      </MuiTooltip>
                    ) : payoutMonth.alreadyRequested ? (
                      <MuiTooltip
                        title={`Will be processed ${payoutMonth.processDate}`}
                        followCursor
                      >
                        <div className={classes.redButton}>In Review</div>
                      </MuiTooltip>
                    ) : (
                      <MuiTooltip
                        title={`Will in review the ${payoutMonth.processDate}`}
                        followCursor
                      >
                        <div className={classes.primaryButton}>
                          Not Available
                        </div>
                      </MuiTooltip>
                    )
                  }
                >
                  <Table
                    data={currentData.map((detail) => ({
                      [t('tables.commissions.id')]: detail.id,
                      [t('tables.commissions.name')]: detail.name,
                      [t('tables.commissions.pricePaid')]: detail.pricePaid,
                      [t('tables.commissions.commissionsEarned')]:
                        detail.commissionEarned,
                      [t('tables.commissions.notes')]: detail.notes,
                      [t('tables.commissions.transactionDate')]:
                        detail.transactionDate,
                    }))}
                    columns={commissionsGetPayoutColumns}
                  />
                  <Pagination
                    total={totalPages[index] || 0}
                    perPage={size}
                    currentPage={currentPage}
                    onPageChange={(newPage) =>
                      updatePaginationState(index, newPage)
                    }
                  />
                </PayoutDropdown>
              );
            })}
          </div>
        </Box>
      </MUIModal>

      <div className={classes.cardsContainer}>
        <IconCard
          title={t('headerCards.commissions')}
          amount={`${data?.totalCommissions}€`}
          action={
            <Button
              variant="primary"
              onClick={() => {
                handleOpenViewMoreModal();
              }}
            >
              {t('headerCards.viewMore')}
            </Button>
          }
          icon="M21.0049 5.9404C19.2657 7.91087 16.8895 9.30578 14.1986 9.80288C13.7739 9.02817 12.9507 8.50293 12.0049 8.50293C11.0591 8.50293 10.2359 9.02817 9.8112 9.80288C7.12025 9.30578 4.74405 7.91087 3.00488 5.9404V3.00293C3.00488 2.45064 3.4526 2.00293 4.00488 2.00293H20.0049C20.5572 2.00293 21.0049 2.45064 21.0049 3.00293V5.9404ZM21.0049 8.72716V21.0029C21.0049 21.5552 20.5572 22.0029 20.0049 22.0029H4.00488C3.4526 22.0029 3.00488 21.5552 3.00488 21.0029V8.72716C4.86501 10.2899 7.13757 11.3775 9.63575 11.8033C9.96947 12.7915 10.9041 13.5029 12.0049 13.5029C13.1057 13.5029 14.0403 12.7915 14.374 11.8033C16.8722 11.3775 19.1448 10.2899 21.0049 8.72716Z"
          className={classes.card}
        />
        <IconCard
          title={t('headerCards.referrals')}
          amount={data?.totalReferrals || 0}
          icon="M2 22C2 17.5817 5.58172 14 10 14C14.4183 14 18 17.5817 18 22H2ZM10 13C6.685 13 4 10.315 4 7C4 3.685 6.685 1 10 1C13.315 1 16 3.685 16 7C16 10.315 13.315 13 10 13ZM17.3628 15.2332C20.4482 16.0217 22.7679 18.7235 22.9836 22H20C20 19.3902 19.0002 17.0139 17.3628 15.2332ZM15.3401 12.9569C16.9728 11.4922 18 9.36607 18 7C18 5.58266 17.6314 4.25141 16.9849 3.09687C19.2753 3.55397 21 5.57465 21 8C21 10.7625 18.7625 13 16 13C15.7763 13 15.556 12.9853 15.3401 12.9569Z"
          className={classes.card}
        />
        <IconCard
          title={t('headerCards.purchasedChallenges')}
          amount={data?.totalPurchasedChallenges || 0}
          icon="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18ZM12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14Z"
          className={classes.card}
        />
        <IconCard
          title={t('headerCards.averageReferralSpend')}
          amount={data?.averageReferralSpend || 0}
          icon="M11.0049 2L18.3032 4.28071C18.7206 4.41117 19.0049 4.79781 19.0049 5.23519V7H21.0049C21.5572 7 22.0049 7.44772 22.0049 8V10H9.00488V8C9.00488 7.44772 9.4526 7 10.0049 7H17.0049V5.97L11.0049 4.094L5.00488 5.97V13.3744C5.00488 14.6193 5.58406 15.7884 6.56329 16.5428L6.75154 16.6793L11.0049 19.579L14.7869 17H10.0049C9.4526 17 9.00488 16.5523 9.00488 16V12H22.0049V16C22.0049 16.5523 21.5572 17 21.0049 17L17.7848 17.0011C17.3982 17.5108 16.9276 17.9618 16.3849 18.3318L11.0049 22L5.62486 18.3318C3.98563 17.2141 3.00488 15.3584 3.00488 13.3744V5.23519C3.00488 4.79781 3.28913 4.41117 3.70661 4.28071L11.0049 2Z"
          className={classes.card}
        />
      </div>

      <Stack
        direction={{ md: 'column', xl: 'row' }}
        spacing={{ md: 4, xl: 5 }}
        sx={{ margin: '32px 0px' }}
      >
        <Card className={classes.affiliateCard}>
          <Stack>
            <Tooltip
              content={`${
                data?.affiliationAttributes.type === 'REFERRAL'
                  ? t('affiliation.referral')
                  : data?.affiliationAttributes.type === 'FIXED_PERCENTAGE'
                  ? t('affiliation.fixedPercentage')
                  : data?.affiliationAttributes.attribute === 'RANK_SILVER'
                  ? t('affiliation.silver')
                  : data?.affiliationAttributes.attribute === 'RANK_GOLD'
                  ? t('affiliation.gold')
                  : data?.affiliationAttributes.attribute === 'RANK_PLATINUM'
                  ? t('affiliation.platinum')
                  : ''
              }`}
              className={classes.tag}
            >
              <Tag
                label={`${
                  data?.affiliationAttributes.type === 'REFERRAL'
                    ? t('affiliation.cardTitleReferral')
                    : data?.affiliationAttributes.type === 'FIXED_PERCENTAGE'
                    ? data?.affiliationAttributes.attribute
                    : data?.affiliationAttributes.attribute === 'RANK_SILVER'
                    ? t('affiliation.silverRank')
                    : data?.affiliationAttributes.attribute === 'RANK_GOLD'
                    ? t('affiliation.goldRank')
                    : data?.affiliationAttributes.attribute === 'RANK_PLATINUM'
                    ? t('affiliation.platinumRank')
                    : ''
                }`}
                color={`${
                  data?.affiliationAttributes.type === 'REFERRAL'
                    ? '#0075ff'
                    : data?.affiliationAttributes.type === 'FIXED_PERCENTAGE'
                    ? '#0075ff'
                    : data?.affiliationAttributes.attribute === 'RANK_SILVER'
                    ? '#C0C0C0'
                    : data?.affiliationAttributes.attribute === 'RANK_GOLD'
                    ? '#f0c300'
                    : data?.affiliationAttributes.attribute === 'RANK_PLATINUM'
                    ? '#ed0000'
                    : ''
                }`}
                icon={
                  <Icon
                    fill={`${
                      data?.affiliationAttributes.type === 'REFERRAL'
                        ? '#0075ff'
                        : data?.affiliationAttributes.type ===
                          'FIXED_PERCENTAGE'
                        ? '#0075ff'
                        : data?.affiliationAttributes.attribute ===
                          'RANK_SILVER'
                        ? '#C0C0C0'
                        : data?.affiliationAttributes.attribute === 'RANK_GOLD'
                        ? '#f0c300'
                        : data?.affiliationAttributes.attribute ===
                          'RANK_PLATINUM'
                        ? '#ed0000'
                        : ''
                    }`}
                    color=""
                    size={18}
                    path={
                      'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 11V17H13V11H11ZM11 7V9H13V7H11Z'
                    }
                  />
                }
              />
            </Tooltip>
            <h2>
              {data?.affiliationAttributes.type === 'REFERRAL'
                ? t('affiliation.cardTitleReferral')
                : t('affiliation.cardTitle')}
            </h2>
          </Stack>
          <div className={classes.container}>
            <div className={classes.linkContent}>
              <div>
                <h3>{t('affiliation.link')}</h3>
                <Input
                  className={classes.affiliateLink}
                  id="affiliateLink"
                  value={`https://live.raisemyfunds.co/buy-challenge?affiliate=${
                    data?.affiliateLink || data?.affiliateID
                  }`}
                  readOnly
                  ref={inputRef}
                />
              </div>
              <Button variant="primary" onClick={() => handleCopyClick()}>
                {copied
                  ? t('copied', { ns: 'common' })
                  : t('affiliation.copyLinkButton')}
              </Button>
            </div>
          </div>
        </Card>

        {data?.affiliationAttributes.type !== 'REFERRAL' ? (
          <Card className={classes.affiliateCard}>
            {error && <Error error={error} />}
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h5" fontWeight={'bold'}>
                Crypto Wallet
              </Typography>
              <MuiTooltip
                placement="top"
                title={t('CRYPTO_WALLET.RECEIVE_PAYMENT_VIA_WALLET')}
              >
                <InfoIcon fontSize="small" sx={{ color: '#d4d4d4' }} />
              </MuiTooltip>
            </Stack>
            <Stack
              direction={{ lg: 'column', xl: 'row' }}
              alignContent={'center'}
              gap={'20px'}
              spacing={3}
            >
              <TextField
                required
                id="crypto-wallet-address"
                label="Crypto Wallet Address"
                variant="outlined"
                value={cryptoWalletAddress}
                onChange={handleChangeCryptoWalletAddress}
                sx={{
                  flexGrow: 0,
                  flexBasis: '50%',
                  // width: '100%',
                  '& .MuiOutlinedInput-root': {
                    color: textColor,
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? '#4f4f51' : '#d4d4d4',
                      borderWidth: '2px',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: textColor,
                  },
                  '& .MuiSvgIcon-root': {
                    color: iconColor,
                  },
                }}
              />
              <Autocomplete
                id="select-network"
                options={networkList}
                getOptionLabel={(option) => option.name}
                onChange={handleNetworkChange}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      '& > img': { mr: 2, flexShrink: 0 },
                    }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={option.src}
                      src={option.src}
                      alt=""
                    />
                    {option.name}
                  </Box>
                )}
                sx={{
                  flexGrow: 0,
                  flexBasis: '50%',
                }}
                renderInput={(params) => (
                  <TextField
                    className={classes.networkLabel}
                    {...params}
                    label={'Select a network'}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    sx={{
                      '& .MuiInputBase-input': {
                        color: textColor,
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: isDarkMode ? '#4f4f51' : '#d4d4d4',
                          borderWidth: '2px',
                        },
                        '&:hover fieldset': {
                          borderColor: isDarkMode ? '#4f4f51' : '#d4d4d4',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#e73d44',
                        },
                      },
                      '& .MuiSvgIcon-root': {
                        color: iconColor,
                      },
                      '& .MuiInputLabel-root': {
                        color: textColor,
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Button onClick={handleDefineWallet} variant="primary">
              Update
            </Button>
          </Card>
        ) : null}
      </Stack>

      <div className={classes.tablesContainer}>
        <Card className={classes.commissions}>
          <h2>{t('tables.commissions.cardTitle')}</h2>
          <Stack spacing={5}>
            <Table data={commissionsData} columns={commissionsColumns} />
            <Pagination
              total={totalCommissionsPages}
              perPage={size}
              currentPage={commissionsPage}
              onPageChange={(page) => setCommissionsPage(page)}
            />
          </Stack>
        </Card>
      </div>
    </>
  );
};
export default PartnerArea;
