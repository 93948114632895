import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../context/ThemeContext/ThemeContext';
import { useAuth } from '../../context/AuthContext/AuthContext';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { toast } from 'react-toastify';
import {
  fetchProfile,
  sendKycDocuments,
  fetchKYCArea,
  updateProfile,
} from '../../services/profile/profileService';
import { logout } from '../../services/auth/login';

import Button from '../../components/userInterface/Button/Button';
import Card from '../../components/Cards/Card/Card';
import Input from '../../components/userInterface/Input/Input';
import Select from '../../components/userInterface/Select/Select';
import Tab from '../../components/Tab/Tab';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';

import classes from './_profile.module.scss';
import Icon from '../../components/userInterface/Icon/Icon';
import { ResponseKYC } from '../../services/profile/interface';
import { isCountryAuthorized } from '../../utils/isCountryAuthorized';

const Profile = () => {
  type DocumentType =
    | 'IDENTITY_PROOF_RECTO'
    | 'IDENTITY_PROOF_VERSO'
    | 'ADDRESS_PROOF';
  const { isDarkMode } = useTheme();
  const { tokens, isTokensLoading } = useAuth();
  useDocumentTitle('Profile');
  const { t } = useTranslation(['profile', 'common']);

  const [data, setData] = useState<any>();
  const prevTokenRef = useRef<string | null>(null);
  const titleOptions = ['Monsieur', 'Madame', 'Non précisé'];
  const [countryOptions, setCountryOptions] = useState<string[]>([]);

  const [error, setError] = useState('');
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [proofOfAddressFileName, setProofOfAddressFileName] = useState<string>(
    'Upload proof of address',
  );
  const [nationalCardRectoFileName, setNationalCardRectoFileName] =
    useState<string>('Upload national card (Recto)');

  const [nationalCardVersoFileName, setNationalCardVersoFileName] =
    useState<string>('Upload national card (Verso)');
  const proofOfAddressInputRef = useRef<HTMLInputElement>(null);
  const nationalCardRectoInputRef = useRef<HTMLInputElement>(null);
  const nationalCardVersoInputRef = useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    title: null,
    birthdate: null,
    phoneNumber: null,
    country: null,
    city: null,
    street: null,
    postalCode: null,
  });

  const isProfileComplete =
    data &&
    data.firstName &&
    data.lastName &&
    data.country &&
    data.city &&
    data.street &&
    data.postalCode;

  const [kycArea, setKycArea] = useState<ResponseKYC>({
    kycFileList: [],
    kyccompleted: false,
  });
  const tabNames = [
    t('tabsName.editProfile'),
    kycArea.kyccompleted
      ? t('tabsName.kycProcessValidated')
      : t('tabsName.kycProcessNotValidated'),
  ];

  useEffect(() => {
    fetch('https://live.raisemyfunds.co/api/localisation/allowed-countries')
      .then((response: any) => response.json())
      .then((data) => {
        setCountryOptions(data);
      });
  }, []);

  useEffect(() => {
    if (!isTokensLoading && tokens?.accessToken) {
      setIsLoading(true);
      const fetchData = async () => {
        try {
          const profileData = await fetchProfile(tokens.accessToken);
          setData(profileData);
          const kycData = await fetchKYCArea(tokens.accessToken); // Fetch KYC data
          setKycArea(kycData); // Update state with fetched KYC data
        } catch (error) {
          console.error('Error fetching data:', error);
          toast.error('Error fetching data');
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else if (!tokens) {
      logout();
    }
  }, [tokens, isTokensLoading]);

  const handleSelectChange = (selectedValue: string, id: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [id]: selectedValue || null,
    }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value || null,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (tokens?.accessToken) {
      setIsLoading(true);

      try {
        await updateProfile(tokens.accessToken, formData);
        toast.success(t('dataUpdatedSuccess', { ns: 'common' }));
        const profileData = await fetchProfile(tokens.accessToken);
        setData(profileData);
      } finally {
        setIsLoading(false);
      }
    } else if (!tokens) {
      logout();
    }
  };

  const handleSelect = () => {
    setSelectedOption(selectedOption);
  };

  const handleFileChange =
    (
      inputRef: React.RefObject<HTMLInputElement>,
      setFileName: React.Dispatch<React.SetStateAction<string>>,
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files ? event.target.files[0] : null;
      const maxFileSize = 5 * 1024 * 1024;

      if (file) {
        if (file.size > maxFileSize) {
          toast.error('File is too large. Maximum size is 5MB.');

          if (inputRef.current) {
            inputRef.current.value = '';
          }
        } else {
          // Si la taille du fichier est acceptable
          setFileName(file.name);
        }
      }
    };

  const handleFileDelete = (
    setFileName: React.Dispatch<React.SetStateAction<string>>,
    inputRef: React.RefObject<HTMLInputElement>,
  ) => {
    setFileName(
      inputRef === proofOfAddressInputRef
        ? 'Upload proof of address'
        : inputRef === nationalCardRectoInputRef
        ? 'Upload national card (Recto)'
        : 'Upload national card (Verso)',
    );
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const getKYCDocumentStatus = (documentType: DocumentType) => {
    const document = kycArea.kycFileList.find(
      (doc) => doc.documentType === documentType,
    );
    let uploadPermitted = false;
    if (document) {
      switch (document.documentStatus) {
        case 'APPROVED':
          return {
            status: t('kycProcess.validated') + ' ✅',
            uploadPermitted,
            originalFileName: document.originalFileName,
          };
        case 'REJECTED':
          uploadPermitted = true;
          return {
            status: t('kycProcess.rejected') + ' ❌',
            uploadPermitted,
            originalFileName: document.originalFileName,
          };
        case 'PENDING':
          return {
            status: t('kycProcess.waiting') + ' ⌛',
            uploadPermitted,
            originalFileName: document.originalFileName,
          };
        default:
          uploadPermitted = true;
          return { status: t('kycProcess.notShared') + ' 📥', uploadPermitted };
      }
    } else {
      uploadPermitted = true; // Permettre l'upload si aucun document n'est partagé
      return { status: t('kycProcess.notShared') + ' 📥', uploadPermitted }; // Si le document n'existe pas
    }
  };
  const identityProofRectoStatus = getKYCDocumentStatus('IDENTITY_PROOF_RECTO');
  const identityProofVersoStatus = getKYCDocumentStatus('IDENTITY_PROOF_VERSO');
  const addressProofStatus = getKYCDocumentStatus('ADDRESS_PROOF');

  const openFileBrowser = (inputRef: React.RefObject<HTMLInputElement>) => {
    inputRef.current?.click();
  };

  const handleUploadKycDocuments = async () => {
    const nationalCardRecto = nationalCardRectoInputRef.current?.files?.[0];
    const nationalCardVerso = nationalCardVersoInputRef.current?.files?.[0];
    const proofOfAddress = proofOfAddressInputRef.current?.files?.[0];

    if (!isProfileComplete) {
      setError(t('kycProcess.profileIncompleteError'));
      return;
    }

    if (tokens?.accessToken) {
      if (nationalCardRecto || proofOfAddress || nationalCardVerso) {
        setIsLoading(true);

        try {
          if (nationalCardRecto) {
            await sendKycDocuments(
              tokens?.accessToken,
              nationalCardRecto,
              'IDENTITY_PROOF_RECTO',
            );
          }

          if (nationalCardVerso) {
            await sendKycDocuments(
              tokens?.accessToken,
              nationalCardVerso,
              'IDENTITY_PROOF_VERSO',
            );
          }

          if (proofOfAddress) {
            await sendKycDocuments(
              tokens?.accessToken,
              proofOfAddress,
              'ADDRESS_PROOF',
            );
          }

          const kycData = await fetchKYCArea(tokens.accessToken); // Fetch KYC data
          setKycArea(kycData); // Update state with fetched KYC data
        } catch (error) {
          setError('');
          setIsLoading(false);
        } finally {
          setError('');
          setIsLoading(false);
        }
      } else {
        setError(t('kycProcess.pleaseDownloadDocument'));
      }
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Tab id="profile" tabs={tabNames}>
      <div className={classes.container}>
        <form onSubmit={handleSubmit}>
          <section>
            <h2>{t('identityCard.identity')}</h2>
            <Card className={classes.card}>
              <div className={classes.formGroup}>
                <div className={classes.form}>
                  <label htmlFor="firstName">
                    {t('identityCard.firstname')}
                  </label>
                  <Input
                    id="firstName"
                    placeholder={t('identityCard.firstname')}
                    defaultValue={data?.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.form}>
                  <label htmlFor="lastName">{t('identityCard.lastname')}</label>
                  <Input
                    id="lastName"
                    placeholder={t('identityCard.lastname')}
                    defaultValue={data?.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.formGroup}>
                <div className={classes.form}>
                  <label htmlFor="title">{t('identityCard.title')}</label>
                  <Select
                    id="title"
                    options={titleOptions}
                    defaultValue={data?.title || handleSelect}
                    onSelect={(value) => handleSelectChange(value, 'title')}
                  />
                </div>
                <div className={classes.form}>
                  <label htmlFor="birthdate">
                    {t('identityCard.birthDate')}
                  </label>
                  <Input
                    id="birthdate"
                    defaultValue={data?.birthdate}
                    type="date"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Card>
          </section>
          <section>
            <h2>{t('moreInformationsCard.moreInformations')}</h2>
            <Card className={classes.card}>
              <div className={classes.formGroup}>
                <div className={classes.form}>
                  <label htmlFor="phoneNumber">
                    {t('moreInformationsCard.phone')}
                  </label>
                  <Input
                    id="phoneNumber"
                    type="tel"
                    placeholder={t('moreInformationsCard.phonePlaceholder')}
                    defaultValue={data?.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.form}>
                  <label htmlFor="email">
                    {t('moreInformationsCard.email')}
                  </label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Email address"
                    defaultValue={data?.email}
                    disabled
                  />
                </div>
              </div>
              <div className={classes.formGroup}>
                <div className={classes.form}>
                  <label htmlFor="country">
                    {t('moreInformationsCard.country')}
                  </label>
                  <Select
                    id="country"
                    options={countryOptions}
                    defaultValue={data?.country || handleSelect}
                    onSelect={(value) => handleSelectChange(value, 'country')}
                  />
                </div>
                <div className={classes.form}>
                  <label htmlFor="city">{t('moreInformationsCard.city')}</label>
                  <Input
                    id="city"
                    placeholder={t('moreInformationsCard.city')}
                    defaultValue={data?.city}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.formGroup}>
                <div className={classes.form}>
                  <label htmlFor="street">
                    {t('moreInformationsCard.street')}
                  </label>
                  <Input
                    id="street"
                    placeholder={t('moreInformationsCard.street')}
                    defaultValue={data?.street}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.form}>
                  <label htmlFor="postalCode">
                    {t('moreInformationsCard.postalCode')}
                  </label>
                  <Input
                    id="postalCode"
                    placeholder={t('moreInformationsCard.postalCode')}
                    defaultValue={data?.postalCode}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Card>
          </section>
          <Button type="submit" variant="primary">
            {t('save', { ns: 'common' })}
          </Button>
        </form>
      </div>
      <div className={classes.container}>
        <section>
          <h2>{t('kycProcess.selectYourCountry')}</h2>
          <Card className={classes.card}>
            <div className={classes.formGroup}>
              <div className={classes.form}>
                <label htmlFor="kyc-title">
                  {t('moreInformationsCard.country')}
                </label>
                <Select
                  id="kyc-title"
                  options={countryOptions}
                  defaultValue={data?.country || handleSelect}
                  onSelect={handleSelect}
                />
              </div>
            </div>
          </Card>
        </section>
        <section>
          <Card className={classes.card}>
            {error && <Error error={error} />}
            <h3>{t('kycProcess.requestedInformations')}</h3>
            <table
              className={`${classes.table} ${
                isDarkMode ? classes.dark : classes.light
              }`}
            >
              <thead className={classes.table__header}>
                <tr>
                  <th colSpan={2}>{t('kycProcess.fileDocument')}</th>
                  <th>{t('kycProcess.shared')}</th>
                  <th>{t('kycProcess.validation')}</th>
                </tr>
              </thead>
              <tbody className={classes.table__body}>
                <tr>
                  <td colSpan={2}>{t('kycProcess.nationalIdCardRecto')}</td>
                  <td>
                    <label className={classes.kycUploadLabel}>
                      {nationalCardRectoFileName !==
                        'Upload national card (Recto)' &&
                      identityProofRectoStatus.uploadPermitted ? (
                        <div
                          className={classes.deleteText}
                          onClick={() =>
                            handleFileDelete(
                              setNationalCardRectoFileName,
                              nationalCardRectoInputRef,
                            )
                          }
                        >
                          <Icon
                            color="custom"
                            fill="#e73d44"
                            size={16}
                            path="M7 6V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7ZM13.4142 13.9997L15.182 12.232L13.7678 10.8178L12 12.5855L10.2322 10.8178L8.81802 12.232L10.5858 13.9997L8.81802 15.7675L10.2322 17.1817L12 15.4139L13.7678 17.1817L15.182 15.7675L13.4142 13.9997ZM9 4V6H15V4H9Z"
                          />
                          {nationalCardRectoFileName}
                        </div>
                      ) : identityProofRectoStatus.uploadPermitted ? (
                        <div
                          className={classes.uploadText}
                          onClick={() =>
                            openFileBrowser(nationalCardRectoInputRef)
                          }
                        >
                          <Icon
                            color="custom"
                            fill="#999999"
                            size={16}
                            path="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM14 9V15H10V9H5L12 2L19 9H14Z"
                          />
                          {t('kycProcess.uploadNationalCardRecto')}
                        </div>
                      ) : (
                        <div className={classes.sharedText}>
                          {identityProofRectoStatus.originalFileName}
                        </div>
                      )}
                    </label>
                    {identityProofRectoStatus.uploadPermitted && (
                      <input
                        className={classes.kycUploadInput}
                        type="file"
                        ref={nationalCardRectoInputRef}
                        onChange={(e) =>
                          handleFileChange(
                            nationalCardRectoInputRef,
                            setNationalCardRectoFileName,
                          )(e)
                        }
                        accept="image/png, image/jpeg, application/pdf"
                      />
                    )}
                  </td>
                  <td>{identityProofRectoStatus.status}</td>
                </tr>

                <tr>
                  <td colSpan={2}>{t('kycProcess.nationalIdCardVerso')}</td>
                  <td>
                    <label className={classes.kycUploadLabel}>
                      {nationalCardVersoFileName !==
                        'Upload national card (Verso)' &&
                      identityProofVersoStatus.uploadPermitted ? (
                        <div
                          className={classes.deleteText}
                          onClick={() =>
                            handleFileDelete(
                              setNationalCardVersoFileName,
                              nationalCardVersoInputRef,
                            )
                          }
                        >
                          <Icon
                            color="custom"
                            fill="#e73d44"
                            size={16}
                            path="M7 6V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7ZM13.4142 13.9997L15.182 12.232L13.7678 10.8178L12 12.5855L10.2322 10.8178L8.81802 12.232L10.5858 13.9997L8.81802 15.7675L10.2322 17.1817L12 15.4139L13.7678 17.1817L15.182 15.7675L13.4142 13.9997ZM9 4V6H15V4H9Z"
                          />
                          {nationalCardVersoFileName}
                        </div>
                      ) : identityProofVersoStatus.uploadPermitted ? (
                        <div
                          className={classes.uploadText}
                          onClick={() =>
                            openFileBrowser(nationalCardVersoInputRef)
                          }
                        >
                          <Icon
                            color="custom"
                            fill="#999999"
                            size={16}
                            path="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM14 9V15H10V9H5L12 2L19 9H14Z"
                          />
                          {t('kycProcess.uploadNationalCardVerso')}
                        </div>
                      ) : (
                        <div className={classes.sharedText}>
                          {identityProofVersoStatus.originalFileName}
                        </div>
                      )}
                    </label>
                    {identityProofVersoStatus.uploadPermitted && (
                      <input
                        className={classes.kycUploadInput}
                        type="file"
                        ref={nationalCardVersoInputRef}
                        onChange={(e) =>
                          handleFileChange(
                            nationalCardVersoInputRef,
                            setNationalCardVersoFileName,
                          )(e)
                        }
                        accept="image/png, image/jpeg, application/pdf"
                      />
                    )}
                  </td>
                  <td>{identityProofVersoStatus.status}</td>
                </tr>

                <tr>
                  <td colSpan={2}>{t('kycProcess.proofOfAddress')}</td>
                  <td>
                    <label className={classes.kycUploadLabel}>
                      {proofOfAddressFileName !== 'Upload proof of address' &&
                      addressProofStatus.uploadPermitted ? (
                        <div
                          className={classes.deleteText}
                          onClick={() =>
                            handleFileDelete(
                              setProofOfAddressFileName,
                              proofOfAddressInputRef,
                            )
                          }
                        >
                          <Icon
                            color="custom"
                            fill="#e73d44"
                            size={16}
                            path="M7 6V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7ZM13.4142 13.9997L15.182 12.232L13.7678 10.8178L12 12.5855L10.2322 10.8178L8.81802 12.232L10.5858 13.9997L8.81802 15.7675L10.2322 17.1817L12 15.4139L13.7678 17.1817L15.182 15.7675L13.4142 13.9997ZM9 4V6H15V4H9Z"
                          />
                          {proofOfAddressFileName}
                        </div>
                      ) : addressProofStatus.uploadPermitted ? (
                        <div
                          className={classes.uploadText}
                          onClick={() =>
                            openFileBrowser(proofOfAddressInputRef)
                          }
                        >
                          <Icon
                            color="custom"
                            fill="#999999"
                            size={16}
                            path="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM14 9V15H10V9H5L12 2L19 9H14Z"
                          />
                          {t('kycProcess.uploadProofOfAdress')}
                        </div>
                      ) : (
                        <div className={classes.sharedText}>
                          {addressProofStatus.originalFileName}
                        </div>
                      )}
                    </label>
                    {addressProofStatus.uploadPermitted && (
                      <input
                        className={classes.kycUploadInput}
                        type="file"
                        ref={proofOfAddressInputRef}
                        onChange={(e) =>
                          handleFileChange(
                            proofOfAddressInputRef,
                            setProofOfAddressFileName,
                          )(e)
                        }
                        accept="image/png, image/jpeg, application/pdf"
                      />
                    )}
                  </td>
                  <td>{addressProofStatus.status}</td>
                </tr>
              </tbody>
            </table>
          </Card>
        </section>
        <Button variant="primary" onClick={() => handleUploadKycDocuments()}>
          {t('sendDocuments', { ns: 'common' })}
        </Button>
      </div>
    </Tab>
  );
};
export default Profile;
