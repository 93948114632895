import React, { useState } from 'react';

import { useTheme } from '../../context/ThemeContext/ThemeContext';

import Icon from '../userInterface/Icon/Icon';

import classes from './_payoutDropdown.module.scss';

interface PayoutDropdownProps {
  month: string;
  amount: string;
  year: string;
  button: any;
  children: React.ReactNode;
}

const PayoutDropdown: React.FC<PayoutDropdownProps> = ({
  month,
  amount,
  year,
  button,
  children,
}) => {
  const { isDarkMode } = useTheme();

  const [isShowMore, setIsShowMore] = useState(false);

  const handleShowMore = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <div className={classes.wrapper}>
      <div
        className={`${classes.dropdown} 
          ${isShowMore ? classes.active : ''} 
          ${isDarkMode ? classes.dark : classes.light}`}
      >
        <div>
          <div className={classes.header}>
            <h3>{`${month} ${year}`}</h3>
            <div className={classes.headerContent}>
              <span>{amount} €</span>
              {button}
              <div className={classes.icons} onClick={() => handleShowMore()}>
                <Icon
                  color="white"
                  size={24}
                  path={
                    isShowMore
                      ? 'M11.9997 10.8284L7.04996 15.7782L5.63574 14.364L11.9997 8L18.3637 14.364L16.9495 15.7782L11.9997 10.8284Z'
                      : 'M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z'
                  }
                />
                <span>Show More</span>
              </div>
            </div>
          </div>
          <hr />
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PayoutDropdown;
