import React, { useEffect } from 'react';

declare global {
  interface Window {
    fbq: any;
  }
}

const PixelBuyChallenge: React.FC = () => {
  useEffect(() => {
    const insertMetaPixelScript = () => {
      if (typeof window.fbq === 'undefined') {
        (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = true;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = true;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          'script',
          'https://connect.facebook.net/en_US/fbevents.js',
        );
      }

      if (window.fbq) {
        window.fbq('init', '1149218386329636');
        window.fbq('track', 'PageView');
      }
    };

    // Insérer le script Meta Pixel au montage du composant
    insertMetaPixelScript();
  }, []);

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=1149218386329636&ev=PageView&noscript=1"
        alt="Meta Pixel"
      />
    </noscript>
  );
};

export default PixelBuyChallenge;
