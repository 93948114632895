import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';
import { JWTPayload } from '../client/interfaces';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}

export const submitSupportTicket = async (
  accessToken: string,
  subject: string,
  content: string,
  attachments: File | null,
) => {
  const decoded = decodeJWT(accessToken);
  if (!decoded || !decoded.userId) {
    throw new Error('Invalid access token - userId not found');
  }

  const formData = new FormData();
  formData.append('subject', subject);
  formData.append('content', content);
  if (attachments) {
    formData.append('attachments', attachments);
  }
  formData.append('userUuid', decoded.userId);

  try {
    const response = await axiosInstance.post('/support/ticket', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      toast.success('Votre demande a été envoyée.', {
        progressStyle: { backgroundColor: 'green' },
      });
    }
    return response.data;
  } catch (error) {
    toast.error('Une erreur est survenue.', {
      progressStyle: { backgroundColor: 'red' },
    });
  }
};
