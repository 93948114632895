import { useEffect, useRef, useState } from 'react';

import { submitSupportTicket } from '../../../services/support/support';

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import GroupIcon from '@mui/icons-material/Group';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SchoolIcon from '@mui/icons-material/School';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalculateIcon from '@mui/icons-material/Calculate';
import CloseIcon from '@mui/icons-material/Close';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LogoutIcon from '@mui/icons-material/Logout';
import TranslateIcon from '@mui/icons-material/Translate';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RadarIcon from '@mui/icons-material/Radar';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PercentIcon from '@mui/icons-material/Percent';
import CelebrationIcon from '@mui/icons-material/Celebration';
import MuiModal from '@mui/material/Modal';

import FullLogo from '../../../assets/images/fullLogo.png';
import SquareLogo from '../../../assets/images/squareLogo.png';
import { useTheme as useRMFTheme } from '../../../context/ThemeContext/ThemeContext';

import { logout } from '../../../services/auth/login';

import items from './items.json';
import adminItems from './adminItems.json';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Chip,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useAuth } from '../../../context/AuthContext/AuthContext';
import { fetchProfile } from '../../../services/profileSidebar/profileSidebar';
import { Profile } from '../../../services/profileSidebar/interface';
import LanguageSelector from '../../../components/LanguageSelector/LanguageSelector';
import Modal from '../../../components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/userInterface/Input/Input';
import TextArea from '../../../components/userInterface/TextArea/TextArea';
import UploadButton from '../../../components/userInterface/UploadButton/UploadButton';
import Error from '../../../components/Error/Error';

const drawerWidth = 305;

function getIconComponent(iconName: string) {
  switch (iconName) {
    case 'PeopleAltIcon':
      return <PeopleAltIcon />;
    case 'ShowChartIcon':
      return <ShowChartIcon />;
    case 'GroupIcon':
      return <GroupIcon />;
    case 'CloudDownloadIcon':
      return <CloudDownloadIcon />;
    case 'AssignmentIndIcon':
      return <AssignmentIndIcon />;
    case 'AccountCircleAccount':
      return <AccountCircleIcon />;
    case 'SchoolIcon':
      return <SchoolIcon />;
    case 'DateRangeIcon':
      return <DateRangeIcon />;
    case 'CalculateIcon':
      return <CalculateIcon />;
    case 'DashboardIcon':
      return <DashboardIcon />;
    case 'RadarIcon':
      return <RadarIcon />;
    case 'ViewInArIcon':
      return <ViewInArIcon />;
    case 'MonetizationOnIcon':
      return <MonetizationOnIcon />;
    case 'AssessmentIcon':
      return <AssessmentIcon />;
    case 'PercentIcon':
      return <PercentIcon />;
    case 'FactCheckIcon':
      return <FactCheckIcon />;
    case 'CelebrationIcon':
      return <CelebrationIcon />;
    default:
      return null;
  }
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexShrink: 0,
  padding: '20px',
  position: 'sticky',
  top: '0',
  zIndex: 20,
  overflowY: 'hidden',
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
    transition: 'none',
  },
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const { user, tokens, isTokensLoading } = useAuth();
  const { isDarkMode, toggleTheme } = useRMFTheme();

  const [openSupportModal, setOpenSupportModal] = useState(false);
  const [profilAnchorEl, setProfilAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [languageAnchorEl, setLanguageProfilAnchorEl] =
    useState<null | HTMLElement>(null);
  const [profile, setProfile] = useState<Profile>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isProfilMenuOpen, setIsProfilMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpenSupportModal(true);
  const handleClose = () => setOpenSupportModal(false);

  const isProfilOpen = Boolean(profilAnchorEl);
  const isLanguageOpen = Boolean(profilAnchorEl);
  // let isLanguageOpen = false;

  const { t } = useTranslation(['sidebar', 'common']);
  const { i18n } = useTranslation();

  const languageNames = {
    en: 'English',
    fr: 'Français',
    es: 'Español',
    ar: 'العربية',
  };

  const languageFullName =
    languageNames[i18n.language as keyof typeof languageNames] || '...';

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubjectChange = (e: any) => {
    setSubject(e.target.value);
  };

  const handleContentChange = (e: any) => {
    setContent(e.target.value);
  };

  const handleFileChange = (files: File[]) => {
    const file = files.length > 0 ? files[0] : null;
    setFile(file);
  };

  const validateForm = () => {
    return subject !== '' && content !== '';
  };

  const handleSubmitSupportTicket = () => {
    if (validateForm()) {
      if (tokens?.accessToken) {
        setIsLoading(true);

        try {
          submitSupportTicket(tokens.accessToken, subject, content, file);
        } catch (error) {
          setIsLoading(false);
          closeModal();
          setError('');
        } finally {
          setIsLoading(false);
          closeModal();
          setError('');
        }
      }
    } else {
      setError(t('supportModal.error'));
    }
  };

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isTokensLoading && tokens?.accessToken && user) {
      const fetchData = async () => {
        try {
          const profileData = await fetchProfile(
            tokens.accessToken,
            user.userId,
          );
          setProfile(profileData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } else if (!tokens) {
      logout();
    }
  }, [tokens, isTokensLoading]);

  const handleOpenProfil = () => {
    setIsProfilMenuOpen(true);
  };
  const handleCloseProfil = () => {
    setIsProfilMenuOpen(false);
  };

  const handleCloseProfilMenu = () => {
    const menuElement = document.getElementById('profil-menu');

    if (menuElement) {
      const handleTransitionEnd = () => {
        navigate('/profile');
        menuElement.removeEventListener('transitionend', handleTransitionEnd);
      };
      menuElement.addEventListener('transitionend', handleTransitionEnd);
      setIsProfilMenuOpen(false);
    } else {
      console.error('Menu element not found');
    }
  };

  const handleOpenLanguage = () => {
    setIsLanguageMenuOpen(true);
  };
  const handleCloseLanguage = () => {
    setIsLanguageMenuOpen(false);
  };

  const profilButtonRef = useRef(null);

  const style = {
    position: 'absolute' as 'absolute',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: isDarkMode ? '#262729' : '#ffffff',
    color: isDarkMode ? '#ffffff' : '#262729',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <MuiModal
        // title={t('supportModal.title')}
        // isOpen={isModalOpen}
        // onClose={closeModal}
        open={openSupportModal}
        onClose={handleClose}
        aria-labelledby="support-modal"
        aria-describedby="support-modal"
      >
        <Box sx={style}>
          {!error ? null : <Error error={error} />}
          <div>
            <label htmlFor="ticket-subject">{t('supportModal.subject')}</label>
            <Input
              id="ticket-subject"
              type="text"
              value={subject}
              onChange={handleSubjectChange}
            />
          </div>
          <div>
            <label htmlFor="content">{t('supportModal.content')}</label>
            <TextArea
              id="content"
              rows={5}
              value={content}
              onChange={handleContentChange}
            />
          </div>
          <div>
            <label htmlFor="support">{t('supportModal.attachments')}</label>
            <UploadButton id="support" onFilesUploaded={handleFileChange}>
              {t('uploadDocuments', { ns: 'common' })}
            </UploadButton>
          </div>
          <Button
            variant="contained"
            onClick={handleSubmitSupportTicket}
            className="modalButton"
          >
            {isLoading
              ? t('loading', { ns: 'common' })
              : t('submit', { ns: 'common' })}
          </Button>
        </Box>
      </MuiModal>

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{
            backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
            boxShadow: '4px 0 5px 1px rgba(0, 0, 0, 0.2)',
            paddingRight: '32px',
            paddingLeft: '8px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: isMobile ? 0 : 5,
                  ...(open && { display: 'none' }),
                  '&:hover': {
                    backgroundColor: isDarkMode
                      ? 'rgba(255, 255, 255, 0.2)'
                      : 'rgba(149, 143, 143, 0.2)',
                  },
                  color: isDarkMode ? '#FFFFFF' : '#333333',
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box component={Link} to={'/'}>
                <Box
                  component="img"
                  sx={{
                    height: '40px',
                    width: 'auto',
                    ...(open && { display: 'none' }),
                  }}
                  alt="RaiseMyFunds logo"
                  src={isMobile ? SquareLogo : FullLogo}
                />
              </Box>
            </Toolbar>
            <Stack direction="row" spacing={3} alignItems="center">
              <Stack direction="row" spacing={2}>
                <Tooltip title={t('settings.support')}>
                  <IconButton
                    aria-label="contact support"
                    edge="start"
                    onClick={handleOpen}
                    sx={{
                      '&:hover': {
                        backgroundColor: isDarkMode
                          ? 'rgba(255, 255, 255, 0.2)'
                          : 'rgba(149, 143, 143, 0.2)',
                      },
                      color: isDarkMode ? '#FFFFFF' : '#333333',
                    }}
                  >
                    <SupportAgentIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <IconButton
                id="profil-button"
                aria-controls={isProfilOpen ? 'profil-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isProfilOpen ? 'true' : undefined}
                ref={profilButtonRef}
                onClick={handleOpenProfil}
              >
                <Avatar
                  sx={{
                    backgroundColor: '#e73d44',
                  }}
                >
                  <PersonIcon />
                </Avatar>
              </IconButton>
              <Menu
                id="language-menu"
                anchorEl={profilButtonRef.current}
                open={isLanguageMenuOpen}
                onClose={handleCloseLanguage}
                MenuListProps={{
                  'aria-labelledby': 'language-list-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
                    color: isDarkMode ? '#FFFFFF' : '#333333',
                    width: '250px',
                  },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    borderBottom: '1px solid #949494',
                    padding: '8px 16px',
                    marginBottom: '16px',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleCloseLanguage();
                      handleOpenProfil();
                    }}
                    aria-label="go back to profile"
                    edge="start"
                    sx={{
                      '&:hover': {
                        backgroundColor: isDarkMode
                          ? 'rgba(255, 255, 255, 0.2)'
                          : 'rgba(149, 143, 143, 0.2)',
                      },
                      color: isDarkMode ? '#FFFFFF' : '#333333',
                    }}
                  >
                    <KeyboardBackspaceIcon fontSize="medium" />
                  </IconButton>
                  <Typography variant="subtitle1">
                    {t('titles.CHOOSE_YOUR_LANGUAGE')}
                  </Typography>
                </Stack>
                <LanguageSelector />
              </Menu>
              <Menu
                id="profil-menu"
                anchorEl={profilButtonRef.current}
                open={isProfilMenuOpen}
                onClose={handleCloseProfil}
                MenuListProps={{
                  'aria-labelledby': 'profil-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
                    color: isDarkMode ? '#FFFFFF' : '#333333',
                    width: '250px',
                  },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    borderBottom: '1px solid #949494',
                    padding: '8px 16px',
                    marginBottom: '16px',
                  }}
                >
                  <Tooltip
                    title={profile?.email}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <Typography variant="subtitle1">{`${profile?.firstname} ${profile?.lastname}`}</Typography>
                  </Tooltip>
                  <IconButton
                    onClick={handleCloseProfil}
                    aria-label="close profile"
                    edge="start"
                    sx={{
                      '&:hover': {
                        backgroundColor: isDarkMode
                          ? 'rgba(255, 255, 255, 0.2)'
                          : 'rgba(149, 143, 143, 0.2)',
                      },
                      color: isDarkMode ? '#FFFFFF' : '#333333',
                    }}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </Stack>
                <MenuItem
                  onClick={() => {
                    handleCloseProfilMenu();
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: isDarkMode
                        ? 'rgba(255, 255, 255, 0.2)'
                        : 'rgba(149, 143, 143, 0.2)',
                    },
                  }}
                >
                  <ListItemIcon>
                    <AssignmentIndIcon
                      fontSize="small"
                      sx={{
                        color: isDarkMode ? '#FFFFFF' : '#333333',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>{t('links.profile')}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseProfilMenu();
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: isDarkMode
                        ? 'rgba(255, 255, 255, 0.2)'
                        : 'rgba(149, 143, 143, 0.2)',
                    },
                  }}
                >
                  <ListItemIcon>
                    <FactCheckIcon
                      fontSize="small"
                      sx={{
                        color: isDarkMode ? '#FFFFFF' : '#333333',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>KYC</ListItemText>
                  <Chip
                    icon={
                      profile?.isKYCValidated ? (
                        <CheckCircleIcon sx={{ color: 'white !important' }} />
                      ) : (
                        <CancelIcon sx={{ color: 'white !important' }} />
                      )
                    }
                    label={
                      profile?.isKYCValidated
                        ? t('settings.KYC_VALIDATED')
                        : t('settings.KYC_NOT_VALIDATED')
                    }
                    // color={profile?.isKYCValidated ? 'success' : 'primary'}
                    sx={{
                      color: 'white',
                      backgroundColor: profile?.isKYCValidated
                        ? '#19B580'
                        : '#e73d44',
                    }}
                    size="small"
                  />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    toggleTheme();
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: isDarkMode
                        ? 'rgba(255, 255, 255, 0.2)'
                        : 'rgba(149, 143, 143, 0.2)',
                    },
                  }}
                >
                  <ListItemIcon>
                    {isDarkMode ? (
                      <DarkModeIcon
                        fontSize="small"
                        sx={{
                          color: isDarkMode ? '#FFFFFF' : '#333333',
                        }}
                      />
                    ) : (
                      <LightModeIcon
                        fontSize="small"
                        sx={{
                          color: isDarkMode ? '#FFFFFF' : '#333333',
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    {isDarkMode
                      ? t('settings.darkTheme')
                      : t('settings.lightTheme')}
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  id="language-list-button"
                  aria-controls={isLanguageOpen ? 'language-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={isLanguageOpen ? 'true' : undefined}
                  onClick={() => {
                    handleCloseProfil();
                    handleOpenLanguage();
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: isDarkMode
                        ? 'rgba(255, 255, 255, 0.2)'
                        : 'rgba(149, 143, 143, 0.2)',
                    },
                  }}
                >
                  <ListItemIcon>
                    <TranslateIcon
                      fontSize="small"
                      sx={{
                        color: isDarkMode ? '#FFFFFF' : '#333333',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {t('settings.language')}: {languageFullName}
                  </ListItemText>
                  <ChevronRightIcon
                    fontSize="medium"
                    sx={{
                      color: isDarkMode ? '#FFFFFF' : '#333333',
                    }}
                  />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseProfil();
                    logout();
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: isDarkMode
                        ? 'rgba(255, 255, 255, 0.2)'
                        : 'rgba(149, 143, 143, 0.2)',
                    },
                  }}
                >
                  <ListItemIcon>
                    <LogoutIcon
                      fontSize="small"
                      sx={{
                        color: isDarkMode ? '#FFFFFF' : '#333333',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>{t('settings.logOut')}</ListItemText>
                </MenuItem>
              </Menu>
            </Stack>
          </Stack>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
              color: 'white',
              ...(isMobile && !open && { display: 'none' }),
              height: '100vh',
              overflowY: 'hidden',
              boxShadow: '1px 0 5px 0px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <DrawerHeader
            sx={{
              marginBottom: open ? '20px' : '-16px',
              backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
            }}
          >
            <Stack>
              <Stack alignItems={isMobile ? 'start' : 'end'}>
                <IconButton
                  color="inherit"
                  aria-label="close drawer"
                  onClick={handleDrawerClose}
                  edge="start"
                  sx={{
                    '&:hover': {
                      backgroundColor: isDarkMode
                        ? 'rgba(255, 255, 255, 0.2)'
                        : 'rgba(149, 143, 143, 0.2)',
                    },
                    color: isDarkMode ? '#FFFFFF' : '#333333',
                  }}
                >
                  {isMobile ? (
                    <CloseIcon />
                  ) : (
                    <Tooltip title={t('buttons.REDUCE')}>
                      <ChevronLeftIcon />
                    </Tooltip>
                  )}
                </IconButton>
              </Stack>
              <Stack justifyContent="center" spacing={2}>
                <Box
                  component={Link}
                  to={'/'}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 'auto',
                      width: '70%',
                      ...(!open && { display: 'none' }),
                    }}
                    alt="RaiseMyFunds logo"
                    src={FullLogo}
                  />
                </Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate('/buy-challenge');
                    isMobile && handleDrawerClose();
                  }}
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    '&:hover': { backgroundColor: '#ea585e' },
                    ...(!open && { display: 'none' }),
                    boxShadow: 'none',
                  }}
                >
                  {t('buttons.newChallenge')}
                </Button>
              </Stack>
            </Stack>
          </DrawerHeader>
          <Typography
            variant="subtitle1"
            sx={{
              marginLeft: '16px',
              ...(!open && { display: 'none' }),
              color: isDarkMode ? '#FFFFFF' : '#333333',
            }}
          >
            {t('titles.mainMenu')}
          </Typography>
          <Divider
            sx={{
              height: '2px',
              backgroundColor: 'rgba(143, 143, 143, 0.3)',
              ...(!open && { display: 'none' }),
            }}
          />
          <Stack
            justifyContent="space-between"
            direction="column"
            sx={{
              overflowY: 'scroll',
              height: '100%',
              position: 'relative',
              overflowX: 'hidden',
            }}
          >
            <List sx={{ margin: '10px 0px' }}>
              {user?.role === 'ROLE_ADMIN'
                ? adminItems.map((item) => (
                    <Tooltip
                      title={t(item.name)}
                      placement="right"
                      key={item.name}
                      disableHoverListener={open ? true : false}
                    >
                      <ListItem
                        sx={{
                          color: isDarkMode ? '#FFFFFF' : '#333333',
                          padding: '0px 16px',
                          marginBottom: '10px',
                          display: 'block',
                          '& .MuiListItemButton-root': {
                            borderRadius: '5px',
                            ...(location.pathname === item.link && {
                              color: '#FFFFFF',
                            }),
                            backgroundColor:
                              location.pathname === item.link
                                ? '#e73d44'
                                : 'transparent',
                          },
                          '& .MuiListItemButton-root:hover': {
                            backgroundColor:
                              location.pathname === item.link
                                ? '#e73d44'
                                : 'rgba(231,61,68,.2)',
                          },
                        }}
                      >
                        <ListItemButton
                          component={Link}
                          to={item.link}
                          onClick={() => isMobile && handleDrawerClose()}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            padding: '8px 12px',
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color:
                                location.pathname === item.link
                                  ? '#FFFFFF'
                                  : isDarkMode
                                  ? '#FFFFFF'
                                  : '#333333',
                              justifyContent: open ? 'initial' : 'center',
                              px: open ? 0 : 2.5,
                            }}
                          >
                            {getIconComponent(item.icon)}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(item.name)}
                            sx={{ display: open ? 'block' : 'none' }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                  ))
                : items.map((item) => (
                    <Tooltip
                      title={t(item.name)}
                      placement="right"
                      key={item.name}
                      disableHoverListener={open ? true : false}
                    >
                      <ListItem
                        sx={{
                          color: isDarkMode ? '#FFFFFF' : '#333333',
                          padding: '0px 16px',
                          marginBottom: '10px',
                          display: 'block',
                          '& .MuiListItemButton-root': {
                            borderRadius: '5px',
                            ...(location.pathname === item.link && {
                              color: '#FFFFFF',
                            }),
                            backgroundColor:
                              location.pathname === item.link
                                ? '#e73d44'
                                : 'transparent',
                          },
                          '& .MuiListItemButton-root:hover': {
                            backgroundColor:
                              location.pathname === item.link
                                ? '#e73d44'
                                : 'rgba(231,61,68,.2)',
                          },
                        }}
                      >
                        <ListItemButton
                          component={Link}
                          to={item.link}
                          onClick={() => isMobile && handleDrawerClose()}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            padding: '8px 12px',
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color:
                                location.pathname === item.link
                                  ? '#FFFFFF'
                                  : isDarkMode
                                  ? '#FFFFFF'
                                  : '#333333',
                              justifyContent: open ? 'initial' : 'center',
                              px: open ? 0 : 2.5,
                            }}
                          >
                            {getIconComponent(item.icon)}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(item.name)}
                            sx={{ display: open ? 'block' : 'none' }}
                          />
                          {item.new && (
                            <Chip
                              label={'EVENT'}
                              sx={{
                                backgroundColor:
                                  location.pathname === item.link
                                    ? 'white'
                                    : '#e73d44',
                                color:
                                  location.pathname === item.link
                                    ? '#333333'
                                    : '#FFFFFF',
                              }}
                            />
                          )}
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                  ))}
              <Tooltip
                title={'Discord'}
                placement="right"
                disableHoverListener={open ? true : false}
              >
                <ListItem
                  sx={{
                    color: isDarkMode ? '#FFFFFF' : '#333333',
                    padding: '0px 16px',
                    marginBottom: '10px',
                    display: 'block',
                    '& .MuiListItemButton-root': {
                      borderRadius: '5px',
                      ...(location.pathname === '/discord' && {
                        color: '#FFFFFF',
                      }),
                      backgroundColor:
                        location.pathname === '/discord'
                          ? '#e73d44'
                          : 'transparent',
                    },
                    '& .MuiListItemButton-root:hover': {
                      backgroundColor:
                        location.pathname === '/discord'
                          ? '#e73d44'
                          : 'rgba(231,61,68,.2)',
                    },
                  }}
                >
                  <ListItemButton
                    component={Link}
                    to={'/discord'}
                    onClick={() => isMobile && handleDrawerClose()}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      padding: '8px 12px',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          location.pathname === '/discord'
                            ? '#FFFFFF'
                            : isDarkMode
                            ? '#FFFFFF'
                            : '#333333',
                        justifyContent: open ? 'initial' : 'center',
                        px: open ? 0 : 2.5,
                      }}
                    >
                      <SvgIcon>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill={
                            location.pathname === '/discord'
                              ? '#FFFFFF'
                              : isDarkMode
                              ? '#FFFFFF'
                              : '#333333'
                          }
                          viewBox="0 0 24 24"
                        >
                          <path d="M19.3034 5.33716C17.9344 4.71103 16.4805 4.2547 14.9629 4C14.7719 4.32899 14.5596 4.77471 14.411 5.12492C12.7969 4.89144 11.1944 4.89144 9.60255 5.12492C9.45397 4.77471 9.2311 4.32899 9.05068 4C7.52251 4.2547 6.06861 4.71103 4.70915 5.33716C1.96053 9.39111 1.21766 13.3495 1.5891 17.2549C3.41443 18.5815 5.17612 19.388 6.90701 19.9187C7.33151 19.3456 7.71356 18.73 8.04255 18.0827C7.41641 17.8492 6.82211 17.5627 6.24904 17.2231C6.39762 17.117 6.5462 17.0003 6.68416 16.8835C10.1438 18.4648 13.8911 18.4648 17.3082 16.8835C17.4568 17.0003 17.5948 17.117 17.7434 17.2231C17.1703 17.5627 16.576 17.8492 15.9499 18.0827C16.2789 18.73 16.6609 19.3456 17.0854 19.9187C18.8152 19.388 20.5875 18.5815 22.4033 17.2549C22.8596 12.7341 21.6806 8.80747 19.3034 5.33716ZM8.5201 14.8459C7.48007 14.8459 6.63107 13.9014 6.63107 12.7447C6.63107 11.5879 7.45884 10.6434 8.5201 10.6434C9.57071 10.6434 10.4303 11.5879 10.4091 12.7447C10.4091 13.9014 9.57071 14.8459 8.5201 14.8459ZM15.4936 14.8459C14.4535 14.8459 13.6034 13.9014 13.6034 12.7447C13.6034 11.5879 14.4323 10.6434 15.4936 10.6434C16.5442 10.6434 17.4038 11.5879 17.3825 12.7447C17.3825 13.9014 16.5548 14.8459 15.4936 14.8459Z" />
                        </svg>
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      primary={'Discord'}
                      sx={{ display: open ? 'block' : 'none' }}
                    />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </List>

            <Typography
              variant="caption"
              align="center"
              pb={1}
              sx={{
                position: 'absolute',
                left: '50%',
                width: '100%',
                transform: 'translate(-50%, 0)',
                bottom: '0px',
                color: '#909090',
                backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
                whiteSpace: 'normal',
                overflowWrap: 'break-word',
                padding: '0 16px',
                ...(!open && { display: 'none' }),
              }}
            >
              {user?.userId}
            </Typography>
          </Stack>
        </Drawer>
      </Box>
    </>
  );
};

export default Sidebar;
